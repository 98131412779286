import { DocumentData } from "@google-cloud/firestore";
import { BaseRecord } from "./base";
import { ActivationRequest } from "./activation_request";

export enum ActivationStatus {
    deleted = -2,
    created,
    activated,
    updating,
    deleting
}

const badges = new Map<ActivationStatus, String>()

badges[ActivationStatus.created] = 'badge-info';
badges[ActivationStatus.activated] = 'badge-success';
badges[ActivationStatus.updating] = 'badge-info';
badges[ActivationStatus.deleting] = 'badge-danger';

const ActivationBadges = badges;

export abstract class Activable extends BaseRecord {
    id: string
    createdBy: String
    status: ActivationStatus
    partnerId: string
    activationRequest?: ActivationRequest

    assign(data: DocumentData) {
        for (var key of Object.keys(data)) {
            this[key] = data[key]
        }
        return
    }

    protected preventedField(): string[] {
        return super.preventedField().concat("createdBy", "status", "createdAt", "partnerId", "activationRequest")
    }

    static badgeClass(obj: Activable): String {
        return ActivationBadges[obj.status];
    }

    static status(obj: Activable): string {
        switch (obj.status) {
            case ActivationStatus.deleted:
                return "deleted"
            case ActivationStatus.activated:
                return "active";
            case ActivationStatus.deleting:
                return "deleting";
            case ActivationStatus.created:
                return "created";
            case ActivationStatus.updating:
                return "updating";
            default: return "N/A";
        }
    }

}