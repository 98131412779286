import { BaseRecord } from "./base";
import Table from './table';
import { LocaleString } from "Lib/local-string";
import * as MD5 from 'md5'

export class I18n extends BaseRecord {
    coll_ref: string = Table.I18n

    locale: LocaleString
    refId: string = "strings_1"
    txt: string
    value: string

    setRecordId(): string | undefined {
        return MD5([this.locale, this.refId, this.txt].join(":"))
    }
}
