import { CorporateAccount } from "./corporate";
import { BaseRecord } from "Models/base";

export class CurrencyAccount extends CorporateAccount {

    iban: string
    bic: string

    constructor(parent?: BaseRecord) {
        super()
        this.parent = parent
    }
}


export class CurrencyAccountDirect extends CurrencyAccount {
    coll_ref: string = "accounts"

}
