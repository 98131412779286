module.exports = {
	"sidebar.app": "App",
	"sidebar.roles": "Ruoli",
	"sidebar.horizontal": "Orizzontale",
	"sidebar.horizontalMenu": "Menu orizzontale",
	"sidebar.general": "Generale",
	"sidebar.component": "Component",
	"sidebar.features": "Funzioni",
	"sidebar.applications": "Applicazioni",
	"sidebar.dashboard": "Cruscotto",
	"sidebar.dashboard1": "Cruscotto 1",
	"sidebar.dashboard2": "Cruscotto 2",
	"sidebar.dashboard3": "Cruscotto 3",
	"sidebar.modules": "Moduli",
	"sidebar.agency": "Agenzia",
	"sidebar.pages": "Pagine",
	"sidebar.gallery": "Galleria",
	"sidebar.pricing": "Prezzi",
	"sidebar.terms & Conditions": "Termini e condizioni",
	"sidebar.feedback": "Feedback",
	"sidebar.report": "Segnala",
	"sidebar.faq (s)": "Faq (s)",
	"sidebar.advancedComponent": "Componente avanzato",
	"sidebar.blank": "Vuoto",
	"sidebar.session": "Sessione",
	"sidebar.login": "Accesso",
	"sidebar.register": "Registrati",
	"sidebar.lockScreen": "Blocca schermo",
	"sidebar.forgotPassword": "Password dimenticata",
	"sidebar.404": "404",
	"sidebar.500": "500",
	"sidebar.uiComponents": "Componenti UI",
	"sidebar.alerts": "Avvisi",
	"sidebar.appBar": "App Bar",
	"sidebar.avatars": "Avatar",
	"sidebar.buttons": "Pulsanti",
	"sidebar.bottomNavigations": "Navigazione inferiore",
	"sidebar.badges": "Distintivo",
	"sidebar.cards": "Carte",
	"sidebar.cardsMasonry": "Carte in muratura",
	"sidebar.chip": "Patata fritta",
	"sidebar.dialog": "Dialogo",
	"sidebar.dividers": "divisori",
	"sidebar.drawers": "Cassetti",
	"sidebar.popover": "Popover",
	"sidebar.expansionPanel": "Pannello di espansione",
	"sidebar.gridList": "lista della griglia",
	"sidebar.list": "Elenco",
	"sidebar.menu": "Menu",
	"sidebar.popoverAndToolTip": "popover e suggerimento",
	"sidebar.progress": "Progresso",
	"sidebar.snackbar": "snack bar",
	"sidebar.selectionControls": "Controlli di selezione",
	"sidebar.advanceUiComponents": "Advance UI Components",
	"sidebar.dateAndTimePicker": "Selezione data e ora",
	"sidebar.tabs": "Schede",
	"sidebar.stepper": "Stepper",
	"sidebar.notification": "Notifica",
	"sidebar.sweetAlert": "Sweet Alert",
	"sidebar.autoComplete": "Completamento automatico",
	"sidebar.aboutUs": "Chi siamo",
	"sidebar.widgets": "widget di",
	"sidebar.forms": "Forme",
	"sidebar.formElements": "Elementi del modulo",
	"sidebar.textField": "Campo di testo",
	"sidebar.selectList": "Seleziona lista",
	"sidebar.charts": "Grafici",
	"sidebar.reCharts": "Re Grafici",
	"sidebar.reactChartjs2": "React Chartjs 2",
	"sidebar.icons": "Icone",
	"sidebar.themifyIcons": "Themify Icone",
	"sidebar.simpleLineIcons": "Icone linea semplice",
	"sidebar.materialIcons": "Icone materiali",
	"sidebar.fontAwesome": "font Eccezionale",
	"sidebar.tables": "Tabelle",
	"sidebar.basic": "Base",
	"sidebar.dataTable": "Tabella dati",
	"sidebar.responsive": "di risposta",
	"sidebar.reactTable": "Tabella di reazione",
	"sidebar.maps": "Mappe",
	"sidebar.googleMaps": "Google Mappe",
	"sidebar.leafletMaps": "Mappe dei volantini",
	"sidebar.inbox": "Posta in arrivo",
	"sidebar.users": "Utenti",
	"sidebar.userProfile1": "Profilo utente 1",
	"sidebar.userProfile2": "Profilo utente 2",
	"sidebar.userManagement": "Gestione utenti",
	"sidebar.userProfile": "Profilo utente",
	"sidebar.userList": "Elenco utenti",
	"sidebar.calendar": "Calendar",
	"sidebar.cultures": "Culture",
	"sidebar.dnd": "Dnd",
	"sidebar.selectable": "Selezionabile",
	"sidebar.customRendering": "Rendering personalizzato",
	"sidebar.chat": "Chiacchierare",
	"sidebar.toDo": "Fare",
	"sidebar.editor": "editore",
	"sidebar.wysiwygEditor": "editore WYSIWYG",
	"sidebar.quillEditor": "Quill editore",
	"sidebar.reactAce": "Reagire Ace",
	"sidebar.dragAndDrop": "Trascina e rilascia",
	"sidebar.reactDragula": "Reagire Dragula",
	"sidebar.reactDnd": "Reagire Dnd",
	"sidebar.blogManagement": "Gestione blog",
	"sidebar.ecommerce": "E-commercio",
	"sidebar.shopList": "Elenco negozi",
	"sidebar.shopGrid": "Negozio Griglia",
	"sidebar.invoice": "Fattura",
	"sidebar.multilevel": "Multilevel",
	"sidebar.sublevel": "Sottolivello",
	"widgets.totalEarns": "Totale guadagna",
	"widgets.emailsStatistics": "Statistiche e-mail",
	"widgets.totalRevenue": "Totale Reddito",
	"widgets.onlineVistors": "Online Vistors",
	"widgets.trafficSources": "Sorgenti di traffico",
	"widgets.RecentOrders": "Ordini recenti",
	"widgets.topSellings": "Superiore sellings",
	"widgets.productReports": "Rapporti sui prodotti",
	"widgets.productStats": "Statistiche prodotto",
	"widgets.ComposeEmail": "Scrivi e-mail",
	"widgets.employeePayroll": "Dipendente Libro paga",
	"widgets.visitors": "Visitatori",
	"widgets.orders": "Ordini",
	"widgets.ratings": "Giudizi",
	"widgets.orderStatus": "Stato dell'ordine",
	"widgets.totalSales": "Vendite totali",
	"widgets.netProfit": "Profitto netto",
	"widgets.overallTrafficStatus": "Stato generale del traffico",
	"widgets.tax": "Tasse",
	"widgets.expenses": "Spese",
	"widgets.currentTime": "Ora corrente",
	"widgets.currentDate": "Dati corrente",
	"widgets.todayOrders": "Oggi Ordini",
	"widgets.toDoList": "A Fare elenchi",
	"widgets.discoverPeople": "Scopri persone",
	"widgets.commments": "Commenti",
	"widgets.newCustomers": "Nuovi clienti",
	"widgets.recentNotifications": "Notifiche recenti",
	"widgets.appNotifications": "Notifiche app",
	"widgets.newEmails": "Nuove email",
	"widgets.siteVisitors": "Visitatori del sito",
	"widgets.socialCompanines": "Social Companines",
	"widgets.recentActivities": "Attività recenti",
	"widgets.recentOrders": "Ordini recenti",
	"widgets.gallery": "Galleria",
	"widgets.pricing": "Prezzi",
	"widgets.enterpriseEdition": "Edizione impresa",
	"widgets.personalEdition": "Personale Edizione",
	"widgets.teamEdition": "Squadra Edizione",
	"widgets.standard": "Standard",
	"widgets.advanced": "Avanzato",
	"widgets.master": "Maestro",
	"widgets.Mega": "Mega",
	"widgets.logIn": "Accedi",
	"widgets.signUp": "Registrati",
	"widgets.lockScreen": "Blocca schermo",
	"widgets.alertsWithLink": "Avvisi con collegamento",
	"widgets.additionalContent": "Contenuto aggiuntivo",
	"widgets.alertDismiss": "Alert Dismiss",
	"widgets.uncontrolledDisableAlerts": "Disattivare gli avvisi non controllati",
	"widgets.contexualAlerts": "avvisi contestuali",
	"widgets.alertsWithIcons": "Avvisi con icone",
	"widgets.simpleAppBars": "Semplice App Barre",
	"widgets.appBarsWithButtons": "App Bar con pulsanti",
	"widgets.imageAvatars": "Avatar di immagini",
	"widgets.lettersAvatars": "Lettere Avatar",
	"widgets.iconsAvatars": "Icone Avatar",
	"widgets.flatButtons": "Flat pulsanti",
	"widgets.raisedButton": "Pulsante alzato",
	"widgets.buttonWithIconAndLabel": "Pulsante con icona ed etichetta",
	"widgets.floatingActionButtons": "Pulsanti di azione mobili",
	"widgets.iconButton": "Pulsante icona",
	"widgets.socialMediaButton": "Pulsante Sociale Media",
	"widgets.reactButton": "Pulsante Reagisci",
	"widgets.buttonOutline": "Schema pulsante",
	"widgets.buttonSize": "Dimensione del pulsante",
	"widgets.buttonState": "Stato pulsante",
	"widgets.buttonNavigationWithNoLabel": "pulsante Navigazione senza etichetta",
	"widgets.buttonNavigation": "Pulsante di navigazione",
	"widgets.iconNavigation": "Icon Navigation",
	"widgets.badgeWithHeadings": "Distintivo con titoli",
	"widgets.contexualVariations": "Variazioni Contexual",
	"widgets.badgeLinks": "Collegamenti distintivo",
	"widgets.materialBadge": "Distintivo materiale",
	"widgets.simpleCards": "Semplice Carte",
	"widgets.backgroundVarient": "Varietà sfondo",
	"widgets.cardOutline": "Scheda Struttura",
	"widgets.overlayCard": "copertura Carta",
	"widgets.cardGroup": "Carta Gruppo",
	"widgets.cardTitle": "Titolo scheda",
	"widgets.speacialTitleTreatment": "Trattamento del titolo specifico",
	"widgets.chipWithClickEvent": "Patata fritta con evento clic",
	"widgets.chipArray": "Patata fritta schieramento",
	"widgets.dialogs": "Dialoghi",
	"widgets.listDividers": "Elenco divisori",
	"widgets.insetDividers": "Inset divisori",
	"widgets.temporaryDrawers": "Cassetti temporanei",
	"widgets.permanentDrawers": "Cassetti permanenti",
	"widgets.simpleExpansionPanel": "Semplice Espansione Pannello",
	"widgets.controlledAccordion": "Controlled Fisarmonica",
	"widgets.secondaryHeadingAndColumns": "Intestazione e colonne secondarie",
	"widgets.imageOnlyGridLists": "Solo elenchi di griglia di immagini",
	"widgets.advancedGridLists": "Avanzate Griglia elenchi",
	"widgets.singleLineGridLists": "Elenchi di griglia a linea singola",
	"widgets.simpleLists": "Liste semplici",
	"widgets.folderLists": "Elenchi cartelle",
	"widgets.listItemWithImage": "Elemento elenco con immagine",
	"widgets.switchLists": "Interruttore elenchi",
	"widgets.insetLists": "Elenco inserire",
	"widgets.nestedLists": "Nidificato elenchi",
	"widgets.checkboxListControl": "Controllo elenco caselle di controllo",
	"widgets.pinedSubHeader": "Intestazione secondaria intasata",
	"widgets.InteractiveLists": "Elenchi interattivi",
	"widgets.simpleMenus": "Menu semplici",
	"widgets.selectedMenu": "Menu selezionato",
	"widgets.maxHeightMenu": "Menu altezza massima",
	"widgets.changeTransition": "Cambia transizione",
	"widgets.paper": "Carta",
	"widgets.anchorPlayGround": "Ancora Giocare Terra",
	"widgets.tooltip": "Troppo lTip",
	"widgets.positionedToolTips": "Snackbar posizionato",
	"widgets.circularProgressBottomStart": "Inizio progresso circolare iniziale",
	"widgets.interactiveIntegration": "Integrazione interattiva",
	"widgets.determinate": "Determinato",
	"widgets.linearProgressLineBar": "Barra di avanzamento lineare",
	"widgets.indeterminate": "Indeterminato",
	"widgets.buffer": "Buffer",
	"widgets.query": "domanda",
	"widgets.transitionControlDirection": "Direzione del controllo di transizione",
	"widgets.simpleSnackbar": "Semplice Snack bar",
	"widgets.positionedSnackbar": "Snack bar posizionato",
	"widgets.contexualColoredSnackbars": "Contexual Colorato snackbars",
	"widgets.simpleCheckbox": "Semplice casella di controllo",
	"widgets.interminateSelection": "Interminate Selezione",
	"widgets.disabledCheckbox": "casella di controllo disattivato",
	"widgets.customColorCheckbox": "Casella di controllo colori personalizzati",
	"widgets.VerticalStyleCheckbox": "casella di controllo stile verticale",
	"widgets.horizontalStyleCheckbox": "casella di controllo stile orizzontale",
	"widgets.radioButtons": "Pulsanti Radio",
	"widgets.disabledRadio": "Radio disabilitata",
	"widgets.withError": "Con Errore",
	"widgets.switches": "swiches",
	"widgets.dateAndTimePicker": "Selezione dati e ora",
	"widgets.defaultPicker": "Selettore predefinito",
	"widgets.timePicker": "Tempo Picker",
	"widgets.weekPicker": "Selezionatore settimanale",
	"widgets.defaultDatePicker": "Selezione data predefinita",
	"widgets.customPicker": "Selettore personalizzato",
	"widgets.tabs": "Schede",
	"widgets.fixedTabs": "linguetta Fisso",
	"widgets.basicTab": "Scheda di base",
	"widgets.wrappedLabels": "Etichette avvolte",
	"widgets.centeredLabels": "Etichette centrate",
	"widgets.forcedScrolledButtons": "Pulsanti a scorrimento forzato",
	"widgets.iconsTabs": "Schede icone",
	"widgets.withDisableTabs": "Disabilita tabulazioni",
	"widgets.iconWithLabel": "Icona con etichetta",
	"widgets.stepper": "stepper",
	"widgets.horizontalLinear": "Orizzontale Lineare",
	"widgets.horizontalNonLinear": "Orizzontale Non Lineare",
	"widgets.horizontalLinerAlternativeLabel": "Etichetta alternativa orizzontale fodera",
	"widgets.horizontalNonLinerAlternativeLabel": "Etichetta alternativa non lineare orizzontale",
	"widgets.verticalStepper": "Vertical Stepper",
	"widgets.descriptionAlert": "Descrizione avviso",
	"widgets.customIconAlert": "costume Icona Mettere in guardia",
	"widgets.withHtmlAlert": "Con html Mettere in guardia",
	"widgets.promptAlert": "Richiesta Mettere in guardia",
	"widgets.passwordPromptAlert": "Avviso richiesta parola d'ordine",
	"widgets.customStyleAlert": "costume Stile Mettere in guardia",
	"widgets.autoComplete": "Completamento automatico",
	"widgets.reactSelect": "Reagisci Seleziona",
	"widgets.downshiftAutoComplete": "Downshift Auto Complete",
	"widgets.reactAutoSuggests": "Reagisce suggerimenti automatici",
	"widgets.aboutUs": "Chi siamo",
	"widgets.ourVission": "Nostro Vission",
	"widgets.ourMissions": "Le nostre missioni",
	"widgets.ourMotivation": "La nostra motivazione",
	"widgets.defualtReactForm": "defualt Reagire Modulo",
	"widgets.url": "url",
	"widgets.textArea": "La zona di testo",
	"widgets.file": "File",
	"widgets.formGrid": "Modulo Griglia",
	"widgets.inlineForm": "In linea Modulo",
	"widgets.inputSizing": "Ridimensionamento dell'ingresso",
	"widgets.inputGridSizing": "Ingresso Griglia dimensionamento",
	"widgets.hiddenLabels": "Etichette nascoste",
	"widgets.formValidation": "Modulo Validazione",
	"widgets.number": "Numero",
	"widgets.date": "Dati",
	"widgets.time": "Tempo",
	"widgets.color": "Colore",
	"widgets.search": "Cerca",
	"widgets.selectMultiple": "Seleziona più",
	"widgets.inputWithSuccess": "Input con successo",
	"widgets.inputWithDanger": "Ingresso Con Pericolo",
	"widgets.simpleTextField": "Campo di testo semplice",
	"widgets.componet": "Componenti",
	"widgets.layouts": "layout",
	"widgets.inputAdorements": "Ingresso Adorements",
	"widgets.formattedInputs": "Ingresso formattati",
	"widgets.simpleSelect": "Selezione semplice",
	"widgets.nativeSelect": "Selezione nativa",
	"widgets.MutltiSelectList": "Mutlti Selezionare Elenco",
	"widgets.lineChart": "Grafico a linee",
	"widgets.barChart": "Bar Grafico",
	"widgets.stackedBarChart": "Grafico a barre in pila",
	"widgets.lineBarAreaChart": "Grafico area barra delle linee",
	"widgets.areaChart": "La zona Grafico",
	"widgets.stackedAreaChart": "Grafico ad la zona in Pila",
	"widgets.verticalChart": "Grafico verticale",
	"widgets.radarChart": "Grafico radar",
	"widgets.doughnut": "Ciambella",
	"widgets.polarChart": "Grafico polare",
	"widgets.pieChart": "Grafico a torta",
	"widgets.bubbleChart": "Grafico a bolle",
	"widgets.horizontalBar": "horizonatl Bar",
	"widgets.basicTable": "Tabella di base",
	"widgets.contexualColoredTable": "Tavolo colorato contex",
	"widgets.dataTable": "Tabella dati",
	"widgets.employeeList": "Elenco dei dipendenti",
	"widgets.responsiveTable": "Tabella reattiva",
	"widgets.responsiveFlipTable": "Responsive Flip Table",
	"widgets.reactGridControlledStateMode": "React Grid Controlled State Mode",
	"widgets.googleMaps": "Google Maps",
	"widgets.productsReports": "Rapporti sui prodotti",
	"widgets.taskList": "Elenco attività",
	"widgets.basicCalender": "Calendario di base",
	"widgets.culturesCalender": "Calendario delle culture",
	"widgets.dragAndDropCalender": "Trascina e rilascia calendario",
	"widgets.selectableCalender": "Calendario selezionabile",
	"widgets.customRendering": "Rendering personalizzato",
	"widgets.customCalender": "Calendario personalizzato",
	"widgets.searchMailList": "Cerca mail list",
	"components.buyNow": "Acquista ora",
	"compenets.choose": "Scegli",
	"compenets.username": "Nome utente",
	"compenets.passwords": "Parola d'ordine",
	"widgets.forgetPassword": "Parola d'ordine dimenticata",
	"compenets.signIn": "Accedi",
	"compenets.dontHaveAccountSignUp": "Non ho accesso all'account",
	"compenets.enterUserName": "Inserisci nome utente",
	"compenets.enterEmailAddress": "Inserisci indirizzo email",
	"compenets.confirmPasswords": "Conferma password",
	"components.alreadyHavingAccountSignIn": "Hai già accesso all'account",
	"components.enterYourPassword": "Inserisci la tua password",
	"components.unlock": "Unlock",
	"components.enterPasswords": "Inserisci password",
	"components.resetPassword": "Resest Password",
	"components.pageNotfound": "Pagina non trovata",
	"components.goToHomePage": "Vai alla pagina iniziale",
	"components.sorryServerGoesWrong": "Spiacente Server va male",
	"components.persistentDrawer": "Cassetto persistente",
	"components.back": "Indietro",
	"components.next": "Avanti",
	"components.completeStep": "Completa il passaggio",
	"components.withHtml": "Con Html",
	"components.prompt": "Richiesta",
	"components.withDescription": "Con Descrizione",
	"components.success": "Successo",
	"components.passwordPrompt": "Richiesta parola d'ordine",
	"components.warning": "avvertimento",
	"components.customIcon": "Icona personalizzata",
	"components.customStyle": "Stile personalizzato",
	"components.basic": "Basic",
	"components.submit": "Invia",
	"components.compose": "Componi",
	"components.sendMessage": "Invia messaggio",
	"components.addNewTasks": "Aggiungi nuove attività",
	"components.addToCart": "Aggiungi al carrello",
	"components.payNow": "Paga adesso",
	"components.print": "Stampa",
	"components.cart": "Carrello",
	"components.viewCart": "Visualizza il carrello",
	"components.checkout": "Dai un'occhiata-su",
	"widgets.QuickLinks": "Collegamenti rapidi",
	"widgets.upgrade": "aggiornamento",
	"widgets.app": "App",
	"widgets.addNew": "Aggiungi nuovo",
	"widgets.orderDate": "Ordine Data",
	"widgets.status": "Stato",
	"widgets.trackingNumber": "Numero di tracciamento",
	"widgets.action": "Azione",
	"widgets.designation": "Designazione",
	"widgets.subject": "soggetto",
	"widgets.send": "Invia",
	"widgets.saveAsDrafts": "Salva venire bozze",
	"widgets.onlineSources": "Sorgenti in linea",
	"widgets.lastMonth": "Ultimo mese",
	"widgets.widgets": "widget",
	"widgets.listing": "Elenco",
	"widgets.paid": "Pagato",
	"widgets.refunded": "Rimborsato",
	"widgets.done": "Fatto",
	"widgets.pending": "In sospeso",
	"widgets.cancelled": "Cancelled",
	"widgets.approve": "Approva",
	"widgets.following": "A seguire",
	"widgets.follow": "Seguire",
	"widgets.graphs & Charts": "Grafici e grafici",
	"widgets.open": "Apri",
	"widgets.bounced": "Bounced",
	"widgets.spam": "Spam",
	"widgets.unset": "unset",
	"widgets.bandwidthUse": "Uso della larghezza di banda",
	"widgets.dataUse": "Utilizzo dati",
	"widgets.unsubscribe": "Annulla iscrizione",
	"widgets.profile": "Profilo",
	"widgets.messages": "Messaggi",
	"widgets.support": "Supporto",
	"widgets.faq (s)": "Faq (s)",
	"widgets.upgradePlains": "aggiornamento Plains",
	"widgets.logOut": "Esci",
	"widgets.mail": "posta",
	"widgets.adminTheme": "Tema di amministrazione",
	"widgets.wordpressTheme": "Tema Wordpress",
	"widgets.addToCart": "Aggiungi al carrello",
	"widgets.plan": "Piano",
	"widgets.basic": "Base",
	"widgets.pro": "Pro",
	"widgets.startToBasic": "Inizia per base",
	"widgets.upgradeToPro": "Aggiorna a Pro",
	"widgets.upgradeToAdvance": "Aggiornamento per l'avanzamento",
	"widgets.comparePlans": "Confronta i piani",
	"widgets.free": "Gratuito",
	"widgets.frequentlyAskedQuestions": "Domande frequenti",
	"widgets.searchIdeas": "Cerca idee",
	"widgets.startDate": "Dati di inizio",
	"widgets.endDate": "Fine Data",
	"widgets.category": "Categoria",
	"widgets.apply": "Applica",
	"widgets.downloadPdfReport": "Scarica rapporto Pdf",
	"widgets.yesterday": "Ieri",
	"widgets.totalOrders": "Totale ordini",
	"widgets.totalVisitors": "Visitatori totali",
	"widgets.typeYourQuestions": "Digita le tue domande",
	"widgets.username": "Nome utente",
	"widgets.password": "Parola d'ordine",
	"widgets.signIn": "Accedi",
	"widgets.enterYourPassword": "Inserisci la tua password",
	"widgets.alreadyHavingAccountLogin": "Hai già accesso all'account",
	"widgets.composeMail": "Scrivi messaggio",
	"widgets.issue": "Problema",
	"widgets.recentChat": "Chiacchierare recente",
	"widgets.previousChat": "Chiacchierare precedente",
	"widgets.all": "Tutti",
	"widgets.filters": "Filtri",
	"widgets.deleted": "eliminata",
	"widgets.starred": "Ha recitato",
	"widgets.frontend": "Fine frontale",
	"widgets.backend": "backend",
	"widgets.api": "Api",
	"widgets.simpleAppBar": "Semplice App Bar",
	"widgets.recents": "Recenti",
	"widgets.cardLink": "Collegamento scheda",
	"widgets.anotherLink": "Un altro collegamento",
	"widgets.cardSubtittle": "sottotitolo della carta",
	"widgets.confirmationDialogs": "Dialoghi di conferma",
	"widgets.deletableChip": "deletable Patata fritta",
	"widgets.customDeleteIconChip": "costume Elimina Icona Patata fritta",
	"widgets.openAlertDialog": "Aperto Mettere in guardia Dialogo",
	"widgets.openResponsiveDialog": "Aperto di risposta Dialogo",
	"widgets.openSimpleDialog": "Aperto Semplice Dialogo",
	"widgets.openFormDialog": "Aperto Modulo Dialogo",
	"widgets.follower": "Seguace",
	"widgets.important": "Importante",
	"widgets.private": "Privato",
	"widgets.openLeft": "Aperto Sinistra",
	"widgets.openRight": "Aperto Destra",
	"widgets.openTop": "Aperto Superiore",
	"widgets.openBottom": "Aperto Parte inferiore",
	"widgets.selectTripDestination": "Selezionare Viaggio Destinazione",
	"widgets.pinnedSubheaderList": "Pinned Subheader Elenco",
	"widgets.singleLineItem": "singolo Linea Articolo",
	"widgets.acceptTerms": "Accettare condizioni",
	"widgets.optionA": "Opzione A",
	"widgets.optionB": "Opzione B",
	"widgets.optionC": "Opzione C",
	"widgets.optionM": "Opzione M",
	"widgets.optionN": "Opzione N",
	"widgets.optionO": "Opzione O",
	"widgets.customColor": "costume Colore",
	"widgets.centeredTabs": "centrato Tabs",
	"widgets.multipleTabs": "multiplo Tabs",
	"widgets.preventScrolledButtons": "Impedire scorrere pulsanti",
	"widgets.browse": "Navigare",
	"widgets.formValidate": "Modulo Convalidare",
	"widgets.code": "Codice",
	"widgets.company": "Azienda",
	"widgets.price": "Prezzo",
	"widgets.change": "Modificare",
	"widgets.high": "alto",
	"widgets.low": "Basso",
	"widgets.volume": "Volume",
	"widgets.personalDetails": "Dettagli personali",
	"widgets.occupazione": "Occupazione",
	"widgets.companyName": "Nome dell'azienda",
	"widgets.phoneNo": "Numero di telefono",
	"widgets.city": "Città",
	"widgets.zipCode": "Codice postale",
	"widgets.updateProfile": "Aggiorna profilo",
	"widgets.reject": "Rifiuta",
	"widgets.exportToExcel": "Esporta in Excel",
	"widgets.addNewUser": "Aggiungi nuovo utente",
	"widgets.workWeek": "Settimana lavorativa",
	"widgets.agenda": "Agenda",
	"widgets.conference": "Conferenza",
	"widgets.multilevel": "Multilevel",
	"widgets.dailySales": "Vendite giornaliere",
	"widgets.today": "Oggi",
	"widgets.campaignPerformance": "Rendimento della campagna",
	"widgets.supportRequest": "Richiesta di supporto",
	"widgets.usersList": "Elenco degli utenti",
	"widgets.lastWeek": "La settimana scorsa",
	"themeOptions.sidebarOverlay": "Overlay della sidebar",
	"themeOptions.sidebarBackgroundImages": "Immagini di sfondo della sidebar",
	"themeOptions.appSettings": "Impostazioni app",
	"themeOptions.sidebarImage": "Immagine della sidebar",
	"themeOptions.miniSidebar": "Mini Sidebar",
	"themeOptions.boxLayout": "Layout casella",
	"themeOptions.rtlLayout": "Rtl Layout",
	"themeOptions.darkMode": "Modalità oscura",
	"themeOptions.sidebarLight": "Leggero",
	"themeOptions.sidebarDark": "Buio",
	"button.cancel": "Annulla",
	"button.add": "Aggiungi",
	"button.update": "Aggiorna",
	"button.reply": "Rispondi",
	"button.delete": "Elimina",
	"button.yes": "Sì",
	"button.viewAll": "Guarda tutto",
	"button.like": "Piace",
	"button.assignNow": "Assegna ora",
	"button.seeInsights": "Vedi approfondimenti",
	"sidebar.dateTimePicker": "Selezione data e ora",
	"components.summary": "Sommario",
	"hint.whatAreYouLookingFor": "Che cosa sta cercando",
	"components.yesterday": "Ieri",
	"components.last7Days": "Ultimi 7 giorni",
	"components.last1Month": "Ultimo 1 mese",
	"components.last6Month": "Ultimo 6 mesi",
	"components.spaceUsed": "Spazio utilizzato",
	"components.followers": "Seguace",
	"components.trending": "trending",
	"components.paid": "Pagato",
	"components.refunded": "rimborsato",
	"components.done": "Fatto",
	"components.pending": "in attesa di",
	"components.cancelled": "Annullato",
	"components.approve": "Approvare",
	"components.week": "Settimana",
	"components.month": "Mese",
	"components.year": "Anno",
	"components.today": "Oggi",
	"components.popularity": "Popolarità",
	"components.email": "E-mail",
	"components.drafts": "bozze",
	"components.sent": "Inviato",
	"components.trash": "Spazzatura",
	"components.all": "Tutti",
	"components.do": "Fare",
	"components.title": "Titolo",
	"components.projectName": "Nome del progetto",
	"components.companyName": "Nome della ditta",
	"components.openAlert": "Apri avviso",
	"components.slideInAlertDialog": "slide Nella finestra di avviso",
	"components.openFullScreenDialog": "Apri finestre di dialogo a schermo intero",
	"components.basicChip": "Chip di base",
	"components.clickableChip": "Chip cliccabile",
	"components.left": "Sinistra",
	"components.right": "Destra",
	"components.expansionPanel1": "Pannello di espansione 1",
	"components.expansionPanel2": "Pannello di espansione 2",
	"components.generalSetting": "Impostazioni generali",
	"components.advancedSettings": "Impostazioni avanzate",
	"components.firstName": "Nome di battesimo",
	"components.lastName": "Cognome",
	"components.occupation": "Occupazione",
	"components.phoneNo": "Telefono n",
	"components.address": "Indirizzo",
	"components.city": "Città",
	"components.state": "Stato",
	"components.zipCode": "Cap",
	"components.social Connection": "Connessione sociale",
	"widgets.buyMore": "Compra di più",
	"widgets.trafficChannel": "Canale del traffico",
	"widgets.stockExchange": "Borsa valori",
	"widgets.tweets": "tweets",
	"widgets.ourLocations": "Le nostre sedi",
	"widgets.sales": "I saldi",
	"widgets.to": "A",
	"widgets.shipTo": "Spedire a",
	"widgets.description": "Descrizione",
	"widgets.unitPrice": "Prezzo unitario",
	"widgets.total": "Totale",
	"widgets.note": "Nota",
	"widgets.chipWithAvatar": "chip Con l'avatar",
	"widgets.chipWithTextAvatar": "Chip con Avatar di testo",
	"widgets.chipWithIconAvatar": "Chip con Icon Avatar",
	"widgets.customClickableChip": "Chip cliccabile personalizzato",
	"widgets.outlineChip": "Chip di contorno",
	"widgets.disableChip": "Disattiva chip",
	"widgets.alertDialog": "finestra di avviso",
	"widgets.animatedSlideDialogs": "Dialoghi di diapositive animate",
	"widgets.fullScreenDialogs": "Dialoghi a schermo intero",
	"widgets.formDialogs": "Dialoghi modulo",
	"widgets.simpleDialogs": "Dialoghi semplici",
	"widgets.responsiveFullScreen": "Schermo intero reattivo",
	"widgets.primary": "Primario",
	"widgets.social": "Sociale",
	"widgets.user": "Utente",
	"widgets.admin": "Admin",
	"widgets.permanentdrawer": "Cassetto permanente",
	"widgets.persistentdrawer": "Cassetto persistente",
	"widgets.swiches": "swiches",
	"widgets.horizontalLinearAlternativeLabel": "Etichetta alternativa lineare orizzontale",
	"widgets.horizontalNonLinearAlternativeLabel": "Etichetta alternativa non lineare orizzontale",
	"widgets.notifications": "notifiche",
	"widgets.basicAlert": "Avviso di base",
	"widgets.successAlert": "Avviso di successo",
	"widgets.warningAlert": "Avviso di allerta",
	"widgets.reactAutoSuggest": "Reagisci suggerimento automatico",
	"widgets.components": "componenti",
	"widgets.inputAdornments": "ingresso Adorements",
	"widgets.multiSelectList": "Lista multipla",
	"widgets.contextualColoredTable": "Tavolo colorato contex",
	"widgets.updateYourEmailAddress": "Aggiorna il tuo indirizzo email",
	"widgets.selectADefaultAddress": "Seleziona un indirizzo predefinito",
	"widgets.activity": "Attività",
	"widgets.basicCalendar": "Calendario di base",
	"widgets.culturesCalendar": "Calendario delle culture",
	"widgets.dragAndDropCalendar": "Trascina e lascia calendario",
	"widgets.quillEditor": "Editor di quill",
	"widgets.reactDND": "Reagire DND",
	"widgets.dragula": "Dragula",
	"button.acceptTerms": "Accetta i termini",
	"button.reject": "Rifiutare",
	"button.addNew": "Aggiungere nuova",
	"button.goToCampaign": "Vai alla campagna",
	"button.viewProfile": "Vedi profilo",
	"button.sendMessage": "Invia messaggio",
	"button.saveNow": "Salva ora",
	"button.pen": "Penna",
	"button.search": "Ricerca",
	"button.downloadPdfReport": "Scarica il rapporto Pdf",
	"button.primary": "Primario",
	"button.secondary": "Secondario",
	"button.danger": "Pericolo",
	"button.info": "Informazioni",
	"button.success": "Successo",
	"button.warning": "avvertimento",
	"button.link": "collegamento",
	"button.smallButton": "Pulsante piccolo",
	"button.largeButton": "Pulsante grande",
	"button.blockLevelButton": "Pulsante a livello di blocco",
	"button.primaryButton": "Pulsante primario",
	"button.button": "Pulsante",
	"button.save": "Salvare",
	"button.openMenu": "Apri Menu",
	"button.openWithFadeTransition": "Apri con dissolvenza",
	"button.openPopover": "Apri Popover",
	"button.accept": "Accettare",
	"button.click": "Clic",
	"button.complete": "Completare",
	"button.back": "Indietro",
	"button.next": "Il prossimo",
	"button.completeStep": "Completa il passo",
	"button.error": "Errore",
	"button.writeNewMessage": "Scrivi un nuovo messaggio",
	"button.saveChanges": "Salva I Cambiamenti",
	"button.addNewUser": "Aggiungi nuovo utente",
	"button.more": "Di Più",
	"hint.searchMailList": "Cerca nella lista delle mail",
	"widgets.AcceptorrRejectWithin": "Accettare o rifiutare all'interno",
	"widgets.quoteOfTheDay": "Citazione del giorno",
	"widgets.updated10Minago": "Aggiornato 10 minuti fa",
	"widgets.personalSchedule": "Programma personale",
	"widgets.activeUsers": "Utenti attivi",
	"widgets.totalRequest": "Richiesta totale",
	"widgets.new": "Nuovo",
	"widgets.ShareWithFriends": "Condividere con gli amici!",
	"widgets.helpToShareText": "Aiutaci a diffondere il mondo condividendo il nostro sito Web con i tuoi amici e follower sui social media!",
	"widgets.thisWeek": "Questa settimana",
	"widgets.howWouldYouRateUs": "Come valuteresti?",
	"widgets.booking": "Prenotazione",
	"widgets.confirmed": "Confermato",
	"widgets.monthly": "mensile",
	"widgets.weekly": "settimanale",
	"widgets.target": "bersaglio",
	"widgets.totalActiveUsers": "Totale utenti attivi",
	"sidebar.user": "Utente",
	"sidebar.miscellaneous": "miscellaneo",
	"sidebar.promo": "Promo",
	"themeOptions.themeColor": "Tema Colore",
	"module.inbox": "Posta in arrivo",
	"module.drafts": "bozze",
	"module.sent": "Inviato",
	"module.trash": "Spazzatura",
	"module.spam": "Spam",
	"module.frontend": "Fine frontale",
	"module.backend": "Backend",
	"module.api": "Api",
	"module.issue": "Problema",
	"components.emailPrefrences": "preferenze email",
	"components.myProfile": "Il mio profilo",
	"sidebar.gettingStarted": "Iniziare",
	"widgets.deadline": "Scadenza",
	"widgets.team": "Squadra",
	"widgets.projectManagement": "Gestione di progetto",
	"widgets.latestPost": "L'ultimo post",
	"widgets.projectTaskManagement": "Gestione delle attività del progetto",
	"widgets.selectProject": "Seleziona Progetto",
	"widgets.activityBoard": "Commissione di attività",
	"widgets.checklist": "lista di controllo",
	"sidebar.shop": "Negozio",
	"sidebar.cart": "Carrello",
	"sidebar.checkout": "Check-out",
	"components.companiesAndRoles": "Aziende e ruoli",
	"components.product": "Prodotto",
	"components.quantity": "Quantità",
	"components.totalPrice": "Prezzo totale",
	"components.removeProduct": "Rimuovi prodotto",
	"components.mobileNumber": "Numero di cellulare",
	"components.address2Optional": "Indirizzo 2 (facoltativo)",
	"components.country": "Nazione",
	"components.zip": "Cerniera lampo",
	"components.saveContinue": "Salva e continua",
	"components.placeOrder": "Invia ordine",
	"components.payment": "Pagamento",
	"components.billingAddress": "Indirizzo Di Fatturazione",
	"components.ShippingAddressText": "L'indirizzo di spedizione è lo stesso dell'indirizzo di fatturazione.",
	"components.CartEmptyText": "Il tuo carrello è vuoto!",
	"components.NoItemFound": "Nessun oggetto trovato",
	"components.goToShop": "Vai a fare la spesa",
	"components.cardNumber": "Numero di carta",
	"components.expiryDate": "Data di scadenza",
	"components.cvv": "CVV",
	"components.nameOnCard": "Nome sulla carta",
	"components.confirmPayment": "Conferma pagamento",
	"sidebar.saas": "SAAS",
	"sidebar.multiLevel": "MultiLevel",
	"sidebar.level1": "Livello 1",
	"sidebar.level2": "Livello 2",
	"sidebar.boxed": "boxed",
	"sidebar.news": "notizia",
	"sidebar.extensions": "estensioni",
	"sidebar.imageCropper": "Cropper di immagini",
	"sidebar.videoPlayer": "Lettore video",
	"sidebar.dropzone": "Zona di rilascio",
	"widgets.baseConfig": "Base Config",
	"widgets.customControlBar": "Barra di controllo personalizzata",
	"widgets.withDownloadButton": "Con il pulsante Download",
	"widgets.httpLiveStreaming": "HTTP Live Streaming",
	"widgets.keyboardShortcuts": "Tasti rapidi",
	"button.useDefaultImage": "Usa immagine predefinita",
	"button.cropImage": "Ritaglia l'immagine",
	"widgets.preview": "Anteprima",
	"widgets.croppedImage": "Immagine ritagliata",
	"sidebar.faq(s)": "Faq(s)",
	"sidebar.terms&Conditions": "Termini & Condizioni",
}
