/**
 * Firebase Login
 * Reactify comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from 'firebase';

// Initialize Firebase 
const config = {
    apiKey: "AIzaSyA_nKpLs_z8U54M83cFUEnke1lN5rcJmVw",
    authDomain: "kash-base.firebaseapp.com",
    databaseURL: "https://kash-base.firebaseio.com",
    projectId: "kash-base",
    storageBucket: "kash-base.appspot.com",
    messagingSenderId: "240049414836"
};

// SystemID Config
const sidConfig = {
    apiKey: "AIzaSyDZAP2JhVZGWWV5ZnHUX3CC5cJ9oSRT2M4",
    authDomain: "systemid-bsc.firebaseapp.com",
    databaseURL: "https://systemid-bsc.firebaseio.com",
    projectId: "systemid-bsc",
    storageBucket: "systemid-bsc.appspot.com",
    messagingSenderId: "242440271719"
};

firebase.initializeApp(config);

var sidApp = firebase.initializeApp(sidConfig, 'sidApp');

const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();
// Retrieve Firebase Messaging object.
const messaging = firebase.messaging();
messaging.usePublicVapidKey("BCeDojIxU7WTihB3mKLCDwe_Bi5q7gReFdQueaji3rph1YhQ53zqWWZ4gg0vcV2lGVxeXjH1-oBaf71AeJYqqqI");
  
const sidMessaging = sidApp.messaging();
sidMessaging.usePublicVapidKey("BPMjeUZT_NppiawL8rPvZUaSPLgXEBA5kV07iCjiWl3_MMbAb9HGV4qr4C2AR7_HlHSJ1wrAc6aspPZgalNMVFo");

const sidStorage = sidApp.storage();
export {
    auth,
    database,
    sidMessaging,
    messaging,
    storage,
    sidStorage
};
