import { Activable } from "./activable";
import { Balance } from "Interfaces";
import { Organization } from "./organization";
import Table from './table';

export class Store extends Activable {
    coll_ref: string = Table.Store
    name: string = ""
    userId: string = ""
    userName: string = ""
    balance: Balance
    mcId: string

    constructor(partner?: Organization) {
        super()
        this.parent = partner
    }

    static dbFields = ["name", "userId", "userName", "balance", "mcId"]
}