import { LIST_SC_ADD, LIST_SC_FETCH, LIST_SC_DEL, LIST_SC_UPD, LOGOUT_MEMBER } from "Actions/types";
import { SharingContract } from "Models/account/sharing_contract";
import * as _ from 'lodash'

export interface ContractsState {
    contracts: SharingContract[] | null
    fetching: boolean
    partnerId: string | null
}

// initial state
const INIT_STATE: ContractsState = {
    contracts: null,
    fetching: false,
    partnerId: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGOUT_MEMBER:
            return { ...state, contracts: null, fetching: false, partnerId: null }
        case LIST_SC_ADD:
            var contracts = state.contracts || []
            contracts.push(action.payload)
            return { ...state, contracts: contracts, fetching: false, partnerId: action.partnerId }
        case LIST_SC_FETCH:
            return action.payload == undefined ? { ...state, fetching: false } : { ...state, contracts: action.payload, fetching: false }
        case LIST_SC_DEL:
            var { contracts } = state
            _.remove(contracts, (e: SharingContract) => e.id === action.payload.id)
            return { ...state, contracts: contracts }
        case LIST_SC_UPD:
            var { contracts } = state
            var idx = _.indexOf(contracts.map(x => x.id), action.payload.id)
            if (idx >= 0) {
                contracts[idx] = action.payload
            }
            return { ...state, contracts: contracts }
        default: return { ...state };
    }
}