import { Activable } from "./activable";
import Table from './table';

export class SidServer extends Activable {
    coll_ref: string = Table.Server
    code: string
    description: string
    callbackUrl: string
    accountId: string

}