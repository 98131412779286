import { MobileCurrency } from "Models";
import * as _ from 'lodash'
import {
    IB_LIST_LOADING,
    IB_LIST_ACCOUNTS,
    IB_LIST_MCS,
} from "Actions/types";
import { RawAccount } from "Models/account/base";

export interface IssuingBankState {
    accounts: RawAccount[]
    mcs: MobileCurrency[]
    loading: boolean
}

// initial state
const INIT_STATE: IssuingBankState = {
    accounts: [],
    mcs: [],
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case IB_LIST_LOADING:
            return { ...state, loading: action.payload }
        case IB_LIST_ACCOUNTS:
            return { ...state, accounts: action.payload, loading: false }
        case IB_LIST_MCS:
            return { ...state, mcs: action.payload, loading: false };
        default: return { ...state };
    }
}