import { DEMO_ID } from "Constants";
import { FullMembership } from "Models";
import { CurrencyGroup, RawAccountGroup, CategoryGroup, AccountGroup } from "src/groups/accounts1";
import { AccountType, RawAccount } from "Models/account/base";
import { DocumentData } from "@google-cloud/firestore";
import { Activable } from "Models/activable";
import * as _ from 'lodash';

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function enumKeys(en: any): string[] {
    let keys = Object.keys(en)
    let len = keys.length / 2
    return keys.slice(-len)
}

export function getAccountType(code: string): AccountType {
    let idx = enumKeys(AccountType).indexOf(code)
    if (idx < 0) {
        let rg = Math.floor(parseInt(code) / (10 ** 11))
        let idx = rg % 10
        return AccountType[AccountType[idx]]
    }
    return idx
}

interface IdObject {
    id: string
}

export function NoDemo<TValue extends IdObject>(value: TValue): value is TValue {
    return value.id != DEMO_ID
}

export function currencyValue(value: number): string {
    return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XAF' }).format(value)
}

export function isTester(memberships: FullMembership[] | null): boolean {
    if (memberships == undefined) return false
    return memberships.find(x => x.partnerId == DEMO_ID) != undefined
}

export function rawAccountsFiltering(categs: AccountType[], group: CurrencyGroup[] | undefined | null): RawAccountGroup {
    if (!group) return {}
    function groupToRaw(categ: AccountType, mcId: string): (accGroup: AccountGroup) => RawAccount {
        return (accGroup: AccountGroup) => {
            let acc = new RawAccount()
            acc.id = acc.code = accGroup.code
            acc.name = accGroup.name
            acc.accountType = categ
            acc.status = accGroup.status
            acc.mcId = mcId
            return acc
        }
    }

    let filterCateg = (x: CategoryGroup) => {
        return categs.includes(x.categ)
    }


    let reducer = (raw: RawAccountGroup, group: CurrencyGroup) => {
        let init: RawAccount[] = []
        let mcId = group.key
        let nestedArrays = group.categories
            .filter(filterCateg)
            .map(x => x.accounts.map(groupToRaw(x.categ, mcId)))
        raw[mcId] = init.concat(...nestedArrays)
        return raw
    }
    return group.reduce(reducer, {})
}

export function getRawAccountsByCategs(categs: AccountType[], accountsGroup: CurrencyGroup[]): RawAccount[] {
    const group = rawAccountsFiltering(categs, accountsGroup)
    return _.flatten(Object.values(group))
}


export function groupRawAccounts(group: CurrencyGroup[] | undefined | null): RawAccountGroup {

    let categs: AccountType[] = [
        AccountType.CORPORATE, AccountType.DPO,
        AccountType.DCE, AccountType.POS,
        AccountType.CIRC
    ]
    return rawAccountsFiltering(categs, group)
}

export function getRawAccounts(mcId: string, accountsGroup: CurrencyGroup[]): RawAccount[] {
    let categs: AccountType[] = [AccountType.CORPORATE, AccountType.SHARING_CONTRACT]
    let group = rawAccountsFiltering(categs, accountsGroup)
    return group[mcId]
}

export function getAllRawAccounts(accountsGroup: CurrencyGroup[]): RawAccount[] {
    let categs: AccountType[] = [AccountType.CORPORATE, AccountType.SHARING_CONTRACT]
    let group = rawAccountsFiltering(categs, accountsGroup)
    return _.flatten(Object.values(group))
}


export function makeDiff(rec: Activable, data: DocumentData): DocumentData {
    let diff: DocumentData = {}
    Object.keys(data).forEach(key => {
        if (!_.isEqual(rec[key], data[key])) diff[key] = data[key]
    })
    return diff
}

export function creditCardFormat(number: string): string {
    const code = number.replace(/\s/g, '');
    const group = _.chunk(_.split(code, ''), 4)
    const chunks = group.map(gr => gr.join(''))
    return chunks.join(' ')
}

export function applyMixins(derivedCtor: any, baseCtors: any[]) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            derivedCtor.prototype[name] = baseCtor.prototype[name];
        });
    });
}

export function renameFile(file: File): string {
    let name = `${Date.now()}_${file.name}`
    return name
}