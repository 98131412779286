import { KashRequest } from "Models";
import { KASH_REQUEST_CREATED, 
    KASH_REQUEST_COMPLETED, 
    KASH_REQUEST_RESET, 
    KASH_REQUEST_PROCESSING} from "Actions/types";

export interface KashRequestState {
    processing: Boolean
    uuid: string | null
    request: KashRequest | null
    error: string | null
}

// initial state
const INIT_STATE: KashRequestState = {
    uuid: null,
    processing: false,
    request: null,
    error: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case KASH_REQUEST_PROCESSING:
            return { ...state, processing: true, uuid: action.payload}
        case KASH_REQUEST_CREATED:
            return { ...state, request: action.payload, processing: false };
        case KASH_REQUEST_COMPLETED:
            return { ...state, request: action.payload, uuid: null };
        case KASH_REQUEST_RESET:
            return INIT_STATE;
        default: return { ...state };
    }
}