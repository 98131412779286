import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios';
import systemId from '../systemId';
import jwt from 'jsonwebtoken';
import * as fs from './firestore_client';
import {
    KashRequest,
    Organization, Approval,
    OrganizationType, Role,
    Membership, NewMemberData,
    FullMembership,
    Operation,
    MobileCurrency,
    PayLink,
    Prospect,
} from 'Models';
import { BaseRecord, ModelCreator } from 'Models/base';
import { notEmpty } from '../lib/tools';
import { BASE_CURRENCY, DEMO_CURRENCY, MicroService } from 'Constants';
import { User } from 'Models/user';
import { NotFoundError } from './error';
import { RawAccount, AccountType, BaseAccount } from 'Models/account/base';
import Table from 'Models/table'
import { ActivationStatus, Activable } from 'Models/activable';
import { Phone } from 'Models/phone';
import { Store } from 'Models/store';
import * as _ from 'lodash'
import { ActionContract } from 'Models/account/action_contract';
import { Signatory } from 'Models/signatory';
import { ActivationMap } from 'Models/activation_map';
import { QueryVar, OrderVar } from "Lib/query";
import { MarketService } from 'Models/market_service';
import { IMarketServiceData } from 'Interfaces';

const FIREBASE_TOKEN_HEADER = "firebase-custom"

enum Api {
    systemId,
    sidEngine,
    kash,
    firebase
}

//const DEV_END_POINT = "http://localhost:8080"
const DEV_END_POINT = "https://kash-backend.ngrok.io"
//const SID_ENDPOINT = "http://localhost:9090"
const SID_ENDPOINT = "https://systemid-bsc.appspot.com"
//const DEV_END_POINT = "https://backend-dot-kash-base.appspot.com/"
const KASH_API_ENDPOINT = "https://backend-dot-kash-base.appspot.com/"

function jwtToken(payload: Object): String {
    var jwtToken = jwt.sign(payload, systemId.private_key, {
        algorithm: 'RS256',
        issuer: systemId.client_id,
        expiresIn: 600,
        keyid: systemId.private_key_id
    });
    return jwtToken;
}
type ParamsObject = { [key: string]: string | ParamsObject }

function queryParams(queries?: QueryVar[], _config?: AxiosRequestConfig): AxiosRequestConfig {
    const config: AxiosRequestConfig = _config || {}
    const params: ParamsObject = {}
    if (queries) {
        if (queries) queries.forEach(query => {
            const field = query[0].toString()
            const value = query[2]
            if (!params[field]) params[field] = {} as ParamsObject
            switch (query[1]) {
                case ">":
                case ">=":
                    params[field]["min"] = value
                    break;
                case "<":
                case "<=":
                    params[field]["max"] = value
                    break;
                default:
                    params[field] = value
            }
        })
    }
    config.params = params
    return config
}

function baseUrl(backend: Api): string {
    if (backend == Api.systemId) {
        return systemId.api_end_point
    }
    if (backend == Api.sidEngine) {
        return SID_ENDPOINT
    }
    if (process.env.NODE_ENV === 'development') return DEV_END_POINT;
    return KASH_API_ENDPOINT;
}


export function apiError(error: any): Promise<never> {
    if (error.response == undefined) return Promise.reject(error)
    let { data } = error.response
    return Promise.reject(new Error(data))
}

const api = (backEnd: Api) => (obj: String | Object) => {
    var authToken: String;
    if (typeof obj == 'string') {
        authToken = obj;
    } else {
        authToken = jwtToken(obj);
    }
    //console.log("authtoken = ", authToken)
    let options: AxiosRequestConfig = {
        baseURL: baseUrl(backEnd),
        timeout: 30000,
        headers: { 'Authorization': `Bearer ${authToken}` }
    };
    if (backEnd == Api.firebase) options.headers['From'] = FIREBASE_TOKEN_HEADER
    return axios.create(options)
};

export default class SidApiInstance {

    api: AxiosInstance
    constructor(api: AxiosInstance) {
        this.api = api;
    }

    get(url: string, config?: AxiosRequestConfig) {
        return this.api(url, config)
    }

    postToken(uuid: string, regid: string) {
        var path = `/api/token`;
        return api(Api.systemId)({ uuid: uuid }).post(path, {
            type: 'token',
            uuid: uuid,
            regid: regid
        })
            .catch(apiError)
    }

    postData<Q extends BaseRecord>(path: string, record: Q, fbToken: string): Promise<Q> {
        return api(Api.firebase)(fbToken).post(path, record.documentData())
            .then(result => {
                record.id = result.data.id
                return Promise.resolve(record)
            })
            .catch(apiError)
    }

    getRecord<Q extends BaseRecord>(record: Q, fbToken: string): Promise<Q> {
        return api(Api.firebase)(fbToken).get(record.getDocumentPath())
            .then(result => {
                record.id = result.data.id
                return Promise.resolve(record)
            })
            .catch(apiError)
    }

    postRecord<Q extends BaseRecord>(record: Q, fbToken: string): Promise<Q> {
        return api(Api.firebase)(fbToken).post(record.getCollectionPath(), record.documentData())
            .then(result => {
                record.id = result.data.id
                return Promise.resolve(record)
            })
            .catch(apiError)
    }

    getRecordInfoById<Q extends BaseRecord>(id: string, creator: ModelCreator<Q>, fbToken: string, parent?: BaseRecord): Promise<Q> {
        const record = new creator()
        record.id = id
        record.parent = parent
        return this.getRecordInfo(record, fbToken)
    }


    getRecordInfo<Q extends BaseRecord>(record: Q, fbToken: string, queries?: QueryVar[]): Promise<Q> {
        const path = [record.getCollectionPath(), record.id].join("/")
        const params = queryParams(queries)
        return api(Api.firebase)(fbToken).get(path, params)
            .then(result => {
                record.assign(result.data)
                return Promise.resolve(record)
            })
            .catch(apiError)
    }

    putRecord<Q extends BaseRecord>(record: Q, fbToken: string): Promise<Q> {
        const url = record.getDocumentPath()
        return api(Api.firebase)(fbToken).put(url, record.documentData())
            .then(result => {
                record.id = result.data.id
                return Promise.resolve(record)
            })
            .catch(apiError)
    }

    deleteRecord<Q extends BaseRecord>(rec: Q, token: string): Promise<any> {
        return api(Api.firebase)(token).delete(rec.getDocumentPath())
            .catch(apiError)
    }

    addRecordFromApi = <Q extends BaseRecord>(fbToken: string) => (record: Q) => {
        return this.postRecord(record, fbToken)
    }

    updateRecordFromApi = <Q extends BaseRecord>(fbToken: string) => (record: Q) => {
        return this.putRecord(record, fbToken)
            .then(_ => Promise.resolve())
    }

    deleteRecordFromApi = <Q extends BaseRecord>(fbToken: string) => (record: Q) => {
        return this.deleteRecord(record, fbToken)
    }

    getRecordsFromApi = <Q extends BaseRecord>(fbToken: string) => (creator: ModelCreator<Q>, parent?: BaseRecord, queries?: QueryVar[], order?: OrderVar, limit?: number, after?: any) => {
        return this.getRecords(fbToken, creator, parent, queries, order, limit, after)
    }


    getRecordsFromMicroService = <Q extends BaseRecord>(fbToken: string) => (service: MicroService, path: string, queries?: QueryVar[], order?: OrderVar, limit?: number, after?: any) => {
        return this.getMSRecords(fbToken, service, path, queries, order, limit, after)
    }

    getMSRecords<Q extends BaseRecord>(fbToken: string, service: MicroService, path: string, queries?: QueryVar[], order?: OrderVar, limit?: number, after?: any): Promise<Q[]> {
        const fullPath = `srv/${service.valueOf()}/${path}`
        return api(Api.firebase)(fbToken).get(fullPath, queryParams(queries))
            .then(resp => {
                const list = resp.data as Q[]
                return Promise.resolve(list)
            })
            .catch(apiError)
    }

    getRecords<Q extends BaseRecord>(fbToken: string, creator: ModelCreator<Q>, parent?: BaseRecord, queries?: QueryVar[], order?: OrderVar, limit?: number, after?: any): Promise<Q[]> {
        const obj = new creator(parent)
        return api(Api.firebase)(fbToken).get(obj.getCollectionPath(), queryParams(queries))
            .then(BaseRecord.fromArrayAxiosResponse(creator, parent))
            .catch(apiError)
    }

    fetchRecordInfoData<Q extends BaseRecord>(rec: Q, fbToken: string): Promise<AxiosResponse> {
        return api(Api.firebase)(fbToken).get(rec.getDocumentPath())
            .catch(apiError)
    }

    getRecordInfoData(path: string, fbToken: string, queries?: QueryVar[]): Promise<AxiosResponse> {
        return api(Api.firebase)(fbToken).get(path, queryParams(queries))
            .catch(apiError)
    }

    createMarketService(ms: MarketService, fbToken: string): Promise<MarketService> {
        return api(Api.firebase)(fbToken).post(`/service_templates`, ms.documentData())
            .then(result => {
                const obj = new MarketService()
                obj.assign(result.data)
                return Promise.resolve(obj)
            })
            .catch(apiError)
    }

    publishMarketService(data: IMarketServiceData, mcId: string, fbToken: string): Promise<MarketService> {
        return api(Api.firebase)(fbToken).post(`/market-services/${mcId}`, data)
            .then(result => {
                const obj = new MarketService()
                obj.assign(result.data)
                return Promise.resolve(obj)
            })
            .catch(apiError)
    }

    unPublishMarketService(ms: MarketService, mcId: string, fbToken: string): Promise<any> {
        return api(Api.firebase)(fbToken).delete(`/market-services/${mcId}/${ms.id}`)
            .then(result => {
                const obj = new MarketService()
                obj.assign(result.data)
                return Promise.resolve(obj)
            })
            .catch(apiError)
    }

    getUserInfo(token: string) {
        return api(Api.sidEngine)(token).get('/user');
    }

    getActivationMaps(fbToken: string): Promise<ActivationMap[]> {
        return api(Api.firebase)(fbToken).get(`/activation_maps`)
            .then(resp => Promise.resolve(resp.data as ActivationMap[]))
            .catch(apiError)
    }

    getActivationMapInfo(id: string, token: string) {
        return api(Api.firebase)(token).get(`/activation_map/${id}`);
    }

    deleteActivationMap(id: string, fbToken: string): AxiosPromise<any> {
        return api(Api.firebase)(fbToken).delete(`/activation_map/${id}`)
            .catch(apiError)
    }

    createActivationMap(rec: ActivationMap, fbToken: string): Promise<ActivationMap> {
        return api(Api.firebase)(fbToken).post(`/activation_maps`, rec.documentData())
            .then(result => {
                console.log("result.data => ", result.data)
                const sig = new ActivationMap()
                sig.assign(result.data)
                return Promise.resolve(sig)
            })
            .catch(apiError)
    }

    getSignatoryInfo(id: string, accountId: string, token: string) {
        return api(Api.firebase)(token).get(`/account/${accountId}/signatory/${id}`);
    }

    deleteSignatory(id: string, accountId: string, fbToken: string): AxiosPromise<any> {
        return api(Api.firebase)(fbToken).delete(`/account/${accountId}/signatory/${id}`)
            .catch(apiError)
    }

    createSignatory(rec: Signatory, accountId: string, fbToken: string): Promise<Signatory> {
        return api(Api.firebase)(fbToken).post(`/account/${accountId}/signatories`, rec.documentData())
            .then(result => {
                console.log("result.data => ", result.data)
                const sig = new Signatory()
                sig.assign(result.data)
                return Promise.resolve(sig)
            })
            .catch(apiError)
    }
    getMemberships(token: string) {
        return api(Api.kash)(token).get('/members');
    }

    getLink(id: string) {
        return api(Api.kash)("").get(`/link/${id}`)
    }

    getInvitation(id: string) {
        return api(Api.kash)("").get(`/invitation/${id}`)
    }

    updateLink(id: string, link: PayLink) {
        return api(Api.kash)("").put(`/link/${id}`, link)
            .catch(err => {
                if (err.response) return Promise.reject(new Error(err.response.data))
                return Promise.reject(err)
            })
    }

    updateProspect(prospect: Prospect) {
        return api(Api.kash)("").put(`/prospect/${prospect.id}`, prospect.extractData())
            .catch(err => {
                if (err.response) {
                    console.error(err.response.data)
                    return Promise.resolve()
                }
                return Promise.reject(err)
            })
    }

    postPartner(token: string, partnerData: any) {
        return api(Api.kash)(token).post('/partners', partnerData);
    }

    postMember(data: NewMemberData, token: string) {
        return api(Api.firebase)(token).post(`/members`, data)
    }

    deleteMember(id: string, token: string) {
        return api(Api.firebase)(token).delete(`/member/${id}`)
    }

    getMemberInfo(id: string, token: string) {
        return api(Api.firebase)(token).get(`/member/${id}`)
    }

    deleteOperation(id: string, token: string) {
        return api(Api.firebase)(token).delete(`/operation/${id}`)
    }

    getOperationInfo(id: string, token: string) {
        return api(Api.firebase)(token).get(`/partner_operation/${id}`)
    }

    getRoleInfo(id: string, token: string) {
        return api(Api.firebase)(token).get(`/role/${id}`)
    }
    getFirebaseCustomToken(member: Membership, token: string) {
        return api(Api.kash)(token).post(`/members/${member.id}/firebasetoken`)
    }

    getAccountInfo(id: string, memberId: string, token: string) {
        return api(Api.kash)(token).get(`/members/${memberId}/partner_accounts/${id}`)
    }

    getPhone(uid: string): Promise<Phone | null> {
        let db = fs.getDb()
        return new Phone().collectionReference(db)
            .where("userId", "==", uid)
            .get()
            .then(BaseRecord.fromSnapshot(Phone))
            .then(res => {
                if (res.length == 0) return Promise.resolve(null)
                return Promise.resolve(res[0])
            })
    }
    getVerificationInfo(id: string, fbToken: string) {
        return api(Api.firebase)(fbToken).get(`/partner_verification/${id}`)
    }
    signRequest(kr: KashRequest) {
        return api(Api.kash)(kr.signature()).post(`sign/${kr.controller()}`)
    }
    getUserDocuments(id: string, fbToken: string) {
        return api(Api.firebase)(fbToken).get(`/user_documents/${id}`)
    }

    createOperation(op: Operation, fbToken: string) {
        return api(Api.firebase)(fbToken).post(`/partner_operations`, op.documentData())
            .catch(apiError)
    }

    addNewRole(role: Role, fbToken: string): Promise<Role> {
        return api(Api.firebase)(fbToken).post(`/roles`, role.documentData())
            .then(resp => Promise.resolve(resp.data as Role))
            .catch(apiError)
    }

    updateRole(role: Role, fbToken: string): Promise<Role> {
        return api(Api.firebase)(fbToken).put(`/role/${role.id}`, role.documentData())
            .then(resp => Promise.resolve(resp.data as Role))
            .catch(apiError)
    }

    deleteRole(id: String, fbToken: string): AxiosPromise<any> {
        return api(Api.firebase)(fbToken).delete(`/role/${id}`)
            .catch(apiError)
    }

    getSignatories(accountId: string, fbToken: string): Promise<Signatory[]> {
        return api(Api.firebase)(fbToken).get(`/account/${accountId}/signatories`)
            .then(resp => Promise.resolve(resp.data as Signatory[]))
            .catch(apiError)
    }

    getPartnerAccounts(partner: Organization): Promise<RawAccount[]> {
        return fs.getDb().collection(Table.Account)
            .where("partnerId", "==", partner.id)
            .where("status", "==", ActivationStatus.activated)
            .get()
            .then(BaseRecord.fromSnapshot(RawAccount))
    }

    getIBCurrencies(member: Membership): Promise<MobileCurrency[]> {
        return fs.getDb().collection(Table.MobileCurrency)
            .where("partnerId", "==", member.partnerId)
            .get()
            .then(BaseRecord.fromSnapshot(MobileCurrency))
    }

    getCurrencyAccounts(mcs: MobileCurrency[]): Promise<RawAccount[]> {
        let db = fs.getDb()
        let reducer = (prev: Promise<RawAccount[]>, curr: MobileCurrency) => {
            return prev.then(accounts => {
                return curr.documentReference(db).collection(Table.Account)
                    .get()
                    .then(BaseRecord.fromSnapshot(RawAccount))
                    .then(accs => accounts.concat(accs))
            })
        }
        return mcs.reduce(reducer, Promise.resolve([]))
    }

    getCompanyApprovalRequests(): Promise<Organization[]> {
        let base = new Approval()
        let db = fs.getDb()
        return base.collectionReference(db)
            .get()
            .then(BaseRecord.fromSnapshot(Approval))
            .then(approvals => {
                let ref = new Organization().collectionReference(db)
                let promises = approvals.map(x => {
                    return ref.doc(x.id).get()
                        .then(BaseRecord.fromDocRef(Organization))
                        .then(partner => {
                            if (partner == null) return Promise.resolve(null)
                            partner.approval = x
                            const result = partner.test ? null : partner
                            return Promise.resolve(result)
                        })
                })
                return Promise.all(promises)
            })
            .then(list => list.filter(notEmpty))
            .then(fs.setBosses)
            .then(fs.setDocuments)
            .then(fs.setVerifications)
    }

    getPartnerBosses(partner: Organization, token: string): Promise<User[]> {
        const queries: QueryVar[] = []
        queries.push(["partnerId", "==", partner.id])
        queries.push(["role", "==", Role.Boss.name])
        return fs.getRecordDocuments(Membership, undefined, queries)
            .then(members => this.getUsersWithDocuments(members.map(x => x.userId), token))
    }

    generatePartnerVerification(partner: Organization, token: string): AxiosPromise<any> {
        let refs = partner.documents
        if (refs == undefined) return Promise.reject(new Error("No documents"))
        return api(Api.firebase)(token).post(`/partner_verification/${partner.id}`, { documents: refs.map(x => x.fullPath) })
            .catch(apiError)
    }

    deleteValidationRequest(target: Organization, member: FullMembership): Promise<void> {
        const { partner } = member
        if (partner == undefined) return Promise.reject(new Error("partner undefined"))
        if (partner.type != OrganizationType.IssuingBank) return Promise.reject(new Error("Partner not authorized"))
        let app = new Approval()
        app.id = target.id
        return app.documentReference(fs.getDb()).delete()
    }

    updateApproval(approval: Approval): Promise<void> {
        return approval.save(Approval, fs.getDb())
    }

    setDefaultAccount(token: string): Promise<any> {
        let data = { mcId: BASE_CURRENCY }
        console.log("creating account with data", data)
        console.log("and token", token)
        return api(Api.firebase)(token).post('/partner_accounts', data)
    }

    getAccount(accountId: string): Promise<RawAccount> {
        let db = fs.getDb()
        let acc = new RawAccount(); acc.id = accountId
        return acc.documentReference(db)
            .get()
            .then(BaseRecord.fromDocRef(RawAccount))
            .then(res => {
                if (res == null) return Promise.reject(new Error("Invalid reference account code"))
                return Promise.resolve(res)
            })

    }

    getUserFromPhone(number: string): Promise<User> {
        let db = fs.getDb()
        let ph = new Phone(); ph.id = number
        let errMsg = "User not in Database"
        return ph.documentReference(db)
            .get()
            .then(BaseRecord.fromDocRef(Phone))
            .then(res => {
                if (res == null) return Promise.reject(new Error(errMsg))
                let ref = new User(); ref.id = res.userId
                return ref.documentReference(db).get()
                    .then(BaseRecord.fromDocRef(User))
                    .then(res => {
                        if (res == null) return Promise.reject(errMsg)
                        return Promise.resolve(res)
                    })
            })
    }

    getUsersWithDocuments(uids: string[], token: string): Promise<User[]> {
        let promises = uids.map(uid => {
            return fs.getDocument(User, uid)
                .then(user => {
                    if (user == null) return Promise.reject(new NotFoundError(`user #${uid} not found`))
                    return Promise.resolve(user)
                })
        })
        return Promise.all(promises)
            .then(users => {
                let docPromises = users.map(user => {
                    return this.getUserDocuments(user.id, token)
                        .then(docs => {
                            //console.log("---> docs data ", docs.data)
                            user.documents = docs.data
                            return Promise.resolve(user)
                        })
                })
                return Promise.all(docPromises)
            })
    }

    getStores(member: Membership): Promise<Store[]> {
        let db = fs.getDb()
        let partner = new Organization(); partner.id = member.partnerId
        return new Store(partner).collectionReference(db).get()
            .then(BaseRecord.fromSnapshot(Store))
    }

    getStore(id: string, token: string): Promise<any> {
        return api(Api.firebase)(token).get(`/store/${id}`)
    }

    deleteStore(id: string, token: string): Promise<any> {
        return api(Api.firebase)(token).delete(`/store/${id}`)
    }

    createStore(store: Store, token: string): Promise<any> {
        return api(Api.firebase)(token).post('/stores', _.pick(store, ...Store.dbFields))
    }

    updateStore(store: Store, token: string): Promise<any> {
        return api(Api.firebase)(token).put(`/store/${store.id}`, _.pick(store, ...Store.dbFields))
    }

}

