import { DocumentData, QuerySnapshot, DocumentSnapshot } from "@google-cloud/firestore";
import firebase from 'firebase';
import * as _ from 'lodash'
import { NEW_ID } from "Constants";
import { AxiosResponse } from "axios";
import moment from 'moment'
import { toTimeStamp } from "Lib/timestamp_tool";

export abstract class BaseRecord {
    id: string = NEW_ID
    createdAt?: firebase.firestore.Timestamp
    parent?: BaseRecord
    abstract coll_ref: string

    constructor(parent?: BaseRecord, data?: Partial<BaseRecord>) {
        this.parent = parent
        if (data) {
            this.assign(data)
        }
    }

    eq(other: BaseRecord, idEvaluated: boolean = true): boolean {
        const data1 = this.documentData()
        const data2 = other.documentData()
        if (idEvaluated) {
            data1.id = this.id
            data2.id = other.id
        }
        return _.isEqual(data1, data2)
    }

    getDate(): string {
        if (!this.createdAt) return ""
        return moment(this.createdAt.toDate().getTime()).format('DD/MM/YYYY')
    }

    getDocumentPath(): string {
        return [this.getCollectionPath(), this.id].join("/")
    }

    getCollectionPath(): string {
        const prefix = !this.parent ? "" : this.parent.getDocumentPath()
        return [prefix, this.coll_ref].join("/")
    }

    assign(data: DocumentData) {
        let keys = Object.keys(data)
        _.remove(keys, ["parent", "id"])
        for (var key of keys) {
            this[key] = data[key]
        }
        return
    }

    setRecordId(): string | undefined {
        return undefined
    }

    documentReference(db: firebase.firestore.Firestore): firebase.firestore.DocumentReference {
        const collRef = !this.parent ? db : this.parent.documentReference(db)
        return collRef.collection(this.coll_ref).doc(this.id)
    }

    collectionReference(db: firebase.firestore.Firestore): firebase.firestore.CollectionReference {
        let collRef = !this.parent ? db : this.parent.documentReference(db)
        return collRef.collection(this.coll_ref)
    }

    protected preventedField(): string[] {
        return ["coll_ref", "id", "parent"]
    }

    readOnlySet(): string[] {
        return []
    }

    protected objectData(obj?: any): any {
        if (obj == undefined) obj = {}
        let data = Object.assign(obj, this)
        this.preventedField().forEach(key => {
            delete data[key]
        })
        return data
    }

    protected beforeCreate(data: any): any {
        return data
    }

    relativePath(): string {
        if (this.parent == null) return this.id
        return [this.parent.relativePath(), this.coll_ref, this.id].join("/")
    }

    documentData(): DocumentData {
        return this.objectData() as DocumentData
    }

    save<T extends BaseRecord>(k: new () => T, db: firebase.firestore.Firestore): Promise<void> {
        if (this.id == null) return Promise.reject(new Error("Cannot save without ID"))
        let _data = this.objectData({ createdAt: firebase.firestore.Timestamp.fromDate(new Date()) })
        let data = this.id = NEW_ID ? this.beforeCreate(_data) : _data
        return this.documentReference(db).set(data, { merge: true })
    }

    create<T extends BaseRecord>(k: new () => T, db: firebase.firestore.Firestore): Promise<T> {
        let base = new k()
        let _data = this.objectData({ createdAt: firebase.firestore.Timestamp.fromDate(new Date()) })
        let data = this.beforeCreate(_data)
        if (this.id == null) {
            return base.collectionReference(db).add(data)
                .then(BaseRecord.fromDocRef(k))
                .then(res => {
                    if (res == null) return Promise.reject(new Error("Null Data"))
                    else return Promise.resolve(res)
                })
        } else {
            let id = this.id
            return base.collectionReference(db).doc(id).set(data)
                .then(() => {
                    let res = new k()
                    res.assign(data)
                    res.id = id
                    return Promise.resolve(res)
                })
        }

    }

    delete(db: firebase.firestore.Firestore): Promise<void> {
        return this.collectionReference(db).doc(this.id).delete()
    }

    static fromSnapshot<Q extends BaseRecord>(k: new (parent?: BaseRecord) => Q, parent?: BaseRecord): (QuerySnapshot) => Q[] {
        return snap => {
            let array: Q[] = []
            snap.docs.map(doc => {
                let e = new k(parent)
                e.assign(doc.data())
                e.id = doc.id
                array.push(e)
            })
            return array
        }
    }

    static fromArrayAxiosResponse<Q extends BaseRecord>(k: new (parent?: BaseRecord) => Q, parent?: BaseRecord): (AxiosResponse) => Q[] {
        return (response: AxiosResponse) => {
            const list = response.data as Array<DocumentData>
            return list.map(elm => {
                const obj = new k(parent)
                if (elm.createdAt) elm.createdAt = toTimeStamp(elm.createdAt)
                if (elm.updatedAt) elm.updatedAt = toTimeStamp(elm.updatedAt)
                obj.assign(elm)
                return obj
            })
        }
    }

    static resolveSnapshotDocuments<Q extends BaseRecord>(k: new (parent?: BaseRecord) => Q, parent?: BaseRecord): (DocumentSnapshot) => Promise<Q[]> {
        return (snap: DocumentSnapshot) => {
            let array = BaseRecord.fromSnapshot(k, parent)(snap)
            return Promise.resolve(array)
        }
    }

    static resolveSnapshotDocument<Q extends BaseRecord>(k: new (parent?: BaseRecord) => Q, parent?: BaseRecord): (DocumentSnapshot) => Promise<Q> {
        return (snap: DocumentSnapshot) => {
            let res = this.fromDocRef(k, parent)(snap)
            if (res != null) return Promise.resolve(res)
            return Promise.reject(new Error("Record not found"))
        }
    }

    static fromDocRef<Q extends BaseRecord>(k: new (parent?: BaseRecord) => Q, parent?: BaseRecord): (DocumentSnapshot) => Q | null {
        return (snap: DocumentSnapshot) => {
            if (!snap.exists) return null
            let doc = new k(parent)
            let data = snap.data()
            if (data != undefined) doc.assign(data)
            doc.id = snap.id
            return doc
        }
    }
}

export class ParentCreator extends BaseRecord {
    coll_ref: string
    constructor(id: string, type: string, parent?: BaseRecord) {
        super(parent)
        this.id = id
        this.coll_ref = type
    }
}

export interface ModelCreator<Q extends BaseRecord> {
    new(parent?: BaseRecord): Q
}

export interface CsvCreator<Q extends BaseRecord> extends ModelCreator<Q> {
    mapping: Map<string, keyof Q>
}