import Address from './Address';
import { BaseRecord } from './base';
import Table from './table';

export enum SexType {
    Male = 'M',
    Female = 'F'
}

export enum IdType {
    NIC = 'NIC',
    Passport = 'PASS'
}

export class UserDocumentUrls {
    front: string[]
    back: string[]
    selfie: string[]
}

export class User extends BaseRecord {
    coll_ref: string = Table.User
    id: string;
    name: string;
    givenNames: string;
    phoneNumber: string;
    email?: string;
    dob: string;
    idNumber: string;
    idType: IdType;
    validUntil: string;
    country: string
    address?: Address;
    documents?: UserDocumentUrls

    static fullName(user: User): string {
        let fNames = user.givenNames.trim().split(' ');
        let i = 0;
        let elements: string[] = [];
        while (i < 2) {
            if (fNames.length > i) {
                if (i > 0) {
                    let abbrv = fNames[i][0] + '.';
                    elements.push(abbrv);
                } else {
                    elements.push(fNames[i]);
                }
            }
            i++;
        }
        elements.push(user.name);
        return elements.join(' ');
    }

    fullName(): string {
        return User.fullName(this)
    }

    selfie(): string | undefined {
        if (this.documents == undefined) return
        return this.documents.selfie[0]
    }

    frontIdDoc(): string | undefined {
        if (this.documents == undefined) return
        return this.documents.front[0]
    }

    backIdDoc(): string | undefined {
        if (this.documents == undefined) return
        return this.documents.back[0]
    }
}