export const BASE_CURRENCY = "xaf1"
export const DOCS_REF = "prt.documents"
export const MARKET_PLACE_DOC_REF = "market_place"
export const USER_DOCS_REF = "iddocs"
export const SUPPORT_DOCS = "prt.support"
export const DPO_ACCOUNT_CODE = "DPO"
export const DEMO_ID = "EkjoDwKcShQ6xmevAt8k"
export const DEMO_CURRENCY = "xaf0"
export const NULL_UID = "NULL_UID"
export const NEW_ID = "new"
export const KASH_SIGN_VALUE = "sign"
export const SING_OP_CONTROLLER = "operations"
export const OPS_CURRENCY_ACCOUNTS = ["DPO", "VAT", "CRC"]
export const PUBLIC_USER_ID = "public"
export const STANDARD_ACCOUNT_LENGTH = 16
export const SWING_APP_URL = "https://play.google.com/store/apps/details?id=com.brentsys.kashme.payment"
export const MARKET_SERVICE_IMG_PATH = "gs://kash-base.appspot.com/market_place/images"
export const INIT_DATE = new Date(2018, 1)
export const DEFAULT_I18N_ID = "strings_1"
export const LOCAL_APP = "local"
export const RECORDS_FETCHING_SIZE = 20
export const RECORDS_GUEST_OFFSET = 10
export const ACTION_VIEW = "view"
export const ACTION_EDIT = "edit"
export enum Category {
    UNDEF,
    LICENSE,
    XFER,
    SHOPPING,
    SERVICES,
    CASH,
    RESTAURANT,
    TRAVEL,
    ENTERTAINMENT,
    TRANSPORT,
    HEALT,
    INSURANCE,
    STOCK,
    GAS,
    FOOD,
    FEES
}
export enum MicroService {
    Card = 'card'
}


export enum NotificationType {
    DANGER, WARNING, INFO, DEFAULT
}

export const NotificationTypeValues = ["Danger", "Warning", "Info", "Default"]

export const NotificationTypeValue: (type: NotificationType) => string = (type) => {
    return NotificationTypeValues[type]
}
