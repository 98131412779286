import { BaseRecord } from "./base";
import Table from './table';
import { Payee } from "./payee";
import { DocumentData } from "@google-cloud/firestore";

const IBAN_MINI_LENGHT = 12

export interface AccountReference {
    bankCode: string
    branch: string
    accNumber: string
    check: string
}

export class BankInfo extends BaseRecord {
    coll_ref: string = Table.BankInfo

    bankName: string
    accountName: string
    bankRef?: string
    account: string | AccountReference

    isValid(): boolean {
        return !!this.accountName && !!this.bankName && this.accountName.length > 0
            && this.isAccountValid()
    }

    isAccountValid(): boolean {
        if (!this.account) return false
        if (typeof this.account === 'string') return this.account.length >= IBAN_MINI_LENGHT
        return !!this.account.accNumber && !!this.account.branch &&
            !!this.account.check && !!this.account.bankCode
    }

    static withParent(payeeId: any): BankInfo {
        let payee = new Payee()
        payee.id = payeeId
        const bi = new BankInfo()
        bi.parent = payee
        bi.bankName = ""
        bi.accountName = ""
        bi.account = ""
        return bi
    }


    isIban(): boolean {
        return typeof this.account === 'string'
    }


}