import { User } from '../models/user';
export interface IAuthUserState {
    user: User | null;
    token: string | null;
    loginMsg: String | null;
    loading: boolean;
    uuidReg: boolean;
    reload: boolean;
    uuidRegError: Error | null;
    member: Membership | null;
    tokenGen: boolean
    firebaseToken: string | null
}
/**
 * Auth User Reducers
 */
import {
    UUID_REGISTRATION,
    UUID_REGISTRATION_SUCCESS,
    UUID_REGISTRATION_FAILED,
    USER_SET_TOKEN,
    SET_USER_INFO,
    LOGIN_MSG,
    RESET_LOGIN_MSG,
    LOGOUT_USER,
    LOGOUT_MEMBER,
    MEMBERSHIP_SET,
    FIREBASE_CUSTOM_TOKEN,
} from '../actions/types';
import { USER_PREF, TOKEN_PREF } from '../constants/storagePrefs';
import { Membership } from 'src/models/membership';

/**
 * initial auth user
 */
const INIT_STATE: IAuthUserState = {
    user: null,
    token: localStorage.getItem(TOKEN_PREF),
    loginMsg: null,
    loading: false,
    uuidReg: false,
    uuidRegError: null,
    reload: false,
    member: null,
    tokenGen: false,
    firebaseToken: null
};

function initState() {
    var state = INIT_STATE;
    var jsonString = localStorage.getItem(USER_PREF);
    if (jsonString != null) {
        try {
            state.user = JSON.parse(jsonString);
        } catch (e) {
            console.error('could not parse ', jsonString, ' from localStorage');
        }
    }
    return state;
}

export default (state = initState(), action) => {
    switch (action.type) {

        case UUID_REGISTRATION:
            return { ...state, uuidReg: true, loading: true, tokenGen: true };

        case UUID_REGISTRATION_SUCCESS:
            return { ...state, loading: false };

        case UUID_REGISTRATION_FAILED:
            return { ...state, loading: false, uuidRegError: action.payload }

        case USER_SET_TOKEN:
            return { ...state, token: action.payload }

        case SET_USER_INFO:
            return { ...state, user: action.payload }

        case LOGIN_MSG:
            return { ...state, loginMsg: action.payload }

        case RESET_LOGIN_MSG:
            return { ...state, loginMsg: null };

        case MEMBERSHIP_SET:
            return { ...state, member: action.payload }

        case LOGOUT_USER:
            return { ...state, user: null, token: null, member: null }

        case LOGOUT_MEMBER:
            return { ...state, member: null }

        case FIREBASE_CUSTOM_TOKEN:
            return { ...state, firebaseToken: action.payload }

        default: return { ...state };
    }
}
