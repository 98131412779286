module.exports = {
	"auth.accounts": "Validation des Ajout/modification de comptes",
	"auth.addresses": "Validation des changements d'adresse",
	"auth.specs": "Validation des spécifications de monnaie numérique",
	"auth.roles": "Validation des Ajout/modification des rôles",
	"auth.members": "Validation des Ajout/modification des collaborateurs",
	"auth.servers": "Validation des Ajout/modification des serveurs",
	"auth.signatories": "Validation des Ajout/modification des signataires de compte",
	"auth.stores": "Validation des Ajout/modification des points de vente",
	"auth.activation_maps": "Validation des Ajout/modification des autorisations",
	"form.date": "Date",
	"form.time": "Heure",
	"form.text": "Texte",
	"form.html":"Html",
	"form.title": "Titre",
	"form.Address1": "Rue 1",
	"form.Address2": "Rye 2",
	"form.City": "Ville",
	"form.StateRegion": "Etat/Région",
	"form.Country": "Pays",
	"form.CustomerID": "ID Carte",
	"form.fullName": "Nom - Prénom",
	"form.localization": "Localisation",
	"form.successValue": "Valeur du Status 'OK'",
	"form.statusField": "Champ de statut",
	"form.creditAccountId": "Compte à créditer",
	"form.serviceId": "ID Service",
	"form.contractType": "Type d'Action",
	"form.isPublic": "Public",
	"form.url": "Url",
	"form.def-params": "Parametres pas défaut",	
	"form.active": "Actif",
	"form.value": "Value",
	"form.options": "Options",
	"form.params": "Parametres",
	"form.type": "Type",
	"form.optional": "Facultatif",
	"form.api-url":"Url de l'API",
	"form.amount": "Montant",
	"form.select-spec": "Selectionnez une spécification",
	"form.times":" X ",
	"form.terms":"Conditions paiement",
	"form.every":"Tous les",
	"form.record_not_found": "Document introuvable",
	"form.actionContractId": "Action Contract",
	"form.accountTypes": "Type de compte",
	"form.select-actionContract": "Selectionnez Action-Contract",
	"form.accountId": "Numéro de compte",
	"form.accountType": "Type de compte",
	"form.price": "Prix",
	"form.coeff":"Coefficient",
	"form.prefix": "Etiquette",
	"form.baseAccountId": "Compte Maître",
	"form.mcId": "Code devise",
	"form.creditMax": "Max Crédits / période",
	"form.creditMaxDays": "Periode-Crédits(jours)",
	"form.cashOutMax": "Max CashOut / Période",
	"form.cashOutMaxDays": "Période CashOut(jours)",
	"form.ceiling": "Plafond",
	"form.singleOpMax": "Maxi / Opération",
	"form.30d-creditMax": "Max/30 jours",
	"form.supporting-docs": "Documents justificatifs",
	"form.no-shipping-contract": "Pas de Shipping Contract",
	"form.storage_file_not_found": "Fichier non trouvé !",
	"form.callbackUrl": "Callback URL",
	"form.version-code": "Numéro de version",
	"form.code": "Code",
	"form.hint": "Indice",
	"form.calc-url": "Url Calcul frais livraison",
	"form.contract-type": "Type de contract",
	"form.name-taken": "Ce nom est déja utilisé",
	"form.field_required": "Ce champ est obligatoire",
	"form.invalid_acc_code": "Numéro de compte invalide",
	"form.debit_account": "Compte a débiter",
	"form.credit_account": "Compte a créditer",
	"form.select_account": "Selectinnez un compte",
	"form.select_op": "Selectionnes une opération",
	"form.iban": "IBAN",
	"form.accountRefs": "Références",
	"form.privileges": "Privilèges",
	"form.name": "Nom",
	"form.representative": "Représentant",
	"form.contact": "Contact",
	"form.role": "Rôle",
	"form.phone": "Téléphone",
	"form.email": "Email",
	"form.maxAmount": "Plafong",
	"form.select_role": "Selectionnez le role",
	"form.select_type": "Selectionnez le type",
	"form.REGUL": "Operation sur Comptes de Cantonnement",
	"form.DIRECT": "Recharge de compte",
	"form.description": "Description",
	"title.market-service": "Market Service",
	"title.auth": "Authorisations",
	"title.role_management": "Gestion des rôles",
	"title.signatories": "Signataires",
	"title.payee_accounts": "Comptes bénéficiaie",
	"role.new-privileges": "Nouveaux privilèges",
	"role.OPS_R": "Accès en lecture des opérations",
	"role.OPS_W": "Accès en écriture des opérations",
	"role.CONFIG_R": "Accès en lecture des configurations",
	"role.CONFIG_W": "Accès en écriture des configurations",
	"role.BOSS": "Propriétaire/créateur de la sociéte. Dispose de tous les droits par default",
    "role.ADMN": "Administrateur de back-office. Effectue les changements de configuration du back-office",
    "role.SPRV": "Valide les changements proposés par l'administrateur",
    "role.CTRL": "Valide les opérations d'exploitation proposées par l'agent",
    "role.AGNT": "Gère les opérations d'exploitation au jour le jour",
	"role.GUEST": "Simple invité, sans autorisation particulière",
	"link.trial_licnse": "Licence complète 15 jours d'utilisation",
	"link.swing_team": "L'équipe SWING",
	"link.invited_by": "Gracieusement invité par",
	"link.no_exists": "Ce lien est obsolète ou n'existe pas",
	"link.check_url": "Vérifiez l'url du lien",
	"link.received_from": "Envoyé par",
	"link.phone_number": "Ce numéro sera associé à votre compte SWING",
	"link.warning": "Attention",
	"link.invalid_number": "Le numéro de téléphone n'est pas valide",
	"link.os_restriction": "* Swing tourne uniquement sur Android pour le moment",
	"link.download_swing": "Télécharger SWING",
	"dialog.delete_title": "Confirmez la suppression",
	"dialog.delete_message":"Etes vous sûr de bien vouloir supprimer définitivement cet enregistrement ?",
	"dialog.undelete_title": "Annulation des changements",
	"dialog.undelete_message":"Vous confirmez l'annulation des changements ?",
	"dialog.button.done": "OK, c'est fait",
	"dialog.button.undone": "Pas encore fait",
	"dialog.declineReasons": "Causes de refus",
	"dialog.confirmVerificationRequest.header": "Demande de validation",
	"dialog.confirmVerificationRequest.content": "Assurez vous d'avoir soumis tous les documents nécessaire avant de demande la vérification.",
	"dialog.confirmVerificationFortest.content": "Etant donné que vous êtes en evironement de test, vous pouvez appuyer juste sur 'OK'",
	"components.batch_stores": "Fichier Points de Vente",
	"components.addNewServer": "Nouveau Serveur d'accès API",
	"components.organizationStatus": "Organization status",
	"components.addNewAccount": "Cliquez ici pour générer un compte société",
	"components.addNewActionContract": "Nouvel Action Contract",
	"components.addNewStore": "Nouveau Point de Ventes",
	"component.companyProfile": "Profil société",
	"components.documents": "Documents",
	"components.bosses": "Dirigeants",
	"components.server": "Serveur",
	"step.uploadDocs": "Uploader les documents sociétés",
	"step.processingDocs": "Documents en étude",
	"step.documentsAccepted": "Documents acceptés",
	"step.documentsRejected": "Documents refusés",
	"components.addNewStaticParameter": "Nouveau parametre fixe",
	"components.addNewServiceParameter": "Nouveau parametre de service",
	"components.addNewCompany": "Ajouter une nouvelle organisation",
	"components.addNewTestCompany": "Ajouter une nouvelle organisation dans en test",
	"sidebar.customers": "Cients",
	"sidebar.groupNotifications": "Notifications globales",
	"sidebar.group-notifications": "Notifications globales",
	"sidebar.prepaid-cards": "Cartes Prépayées",
	"sidebar.ordered-cards": "Cartes commandées",
	"sidebar.orderedCards": "Cartes à livrer",
	"sidebar.prepaidCards": "Cartes prépayées",
	"sidebar.backfires": "Parametres Post Action",
	"sidebar.actions": "Actions",
	"sidebar.license_contracts": "Action Contracts de Licence",
	"sidebar.types": "Types de licences",
	"sidebar.specs": "Specifications licences",
	"sidebar.license_types": "Types de licences",
	"sidebar.license_specs": "Specifications licences",
	"sidebar.servers": "Serveurs",
	"sidebar.translations": "Traductions",
	"sidebar.marketServices": "Market Services",
	"sidebar.templates": "Modèles",
	"sidebar.services": "Services",
	"sidebar.market_services": "Market Services",
	"sidebar.DPO": "Depôt",
	"sidebar.CIRC": "Circulation",
	"sidebar.accounts": "Comptes",
	"sidebar.edit": "Modifier",
	"sidebar.view": "Voir",
	"sidebar.contracts": "Contrats",
	"sidebar.action": "Action Contract",
	"sidebar.sharing": "Sharing Contract",
	"sidebar.sharings": "Sharing Contracts",
	"sidebar.developers": "Développeurs",
	"sidebar.sharing_contracts": "Sharing contracts",
	"sidebar.action_contracts": "Action contracts",
	"sidebar.payees": "Bénéficiaires Swing",	
	"sidebar.extPayees": "Bénéficiaires IBAN",	
	"sidebar.payeesList": "Liste bénéficiaires",
	"sidebar.stores": "Points de vente",
	"sidebar.agencyBanking": "Agency Banking",
	"sidebar.organization": "Organisation",
	"sidebar.validations": "Validations",
	"sidebar.validation": "Validation",
	"sidebar.all": "liste",
	"widgets.group-notification": "Notification globale",
	"widgets.shipping-address": "Adresse de livraison",
	"widgets.prepaid-pending": "Cartes à livrer",
	"widgets.backfire": "Post Action",
	"widgets.parameter": "Paramètre personnalisé",
	"widgets.license-specs": "Spécifications License",
	"widgets.addACLPartner": "Ajouter un code partenaire",
	"widgets.acl-parners": "Partnaires autorisés",
	"widgets.servers": "Serveurs",
	"widgets.stores": "Points de vente",
	"widgets.no_more_records": "Fin de la liste",
	"widgets.store-manager": "Chef de caisse",
	"widgets.shipping-contract": "Shipping Contract",
	"widgets.action-contract": "Action Contract",
	"widgets.publish": "Publier",
	"widgets.published": "Publié",
	"widgets.unpublished": "Non publié",
	"widgets.publication-status": "Publication",
	"widgets.staticParams": "Parametres fixes",
	"widgets.locale": "Langue",
	"widgets.translation": "Traduction",
	"widgets.translations": "Traductions",
	"widgets.mandatory": "Obligatoire",
	"widgets.choices": "Choix",
	"widgets.addChoice": "Nouveau choix",
	"widgets.logo": "Logo",
	"widgets.upload_image": "Téléverser une image",
	"widgets.new-translation": "Nouvelle traduction",
	"widgets.new-service": "Nouveau service",
	"widgets.newCIRC": "Nouveau Compte de Cantonnement",
	"widgets.balance": "Solde",
	"widgets.operations": "Opérations",
	"widgets.payee": "Bénéficiaire",
	"widgets.optional": "Facultatif",
	"widgets.bankReference": "Références Complementaires",
	"widgets.rib-key": "Clé RIB",
	"widgets.accNumber": "N° compte",
	"widgets.branch": "Code Guichet",
	"widgets.bankCode": "Code Banque",
	"widgets.actions": "Actions",
	"widgets.bankName": "Banque",
	"widgets.accountName": "Intitulé",
	"widgets.payeeAccount": "Compte Bénéficiaire",
	"widgets.selectPayee": "Sélectionner un bénéficiaire",
	"widgets.no_limit": "Pas de limite",
	"widgets.account": "Compte",
	"widgets.roles": "Rôles",
	"widgets.newRole": "Nouveau Rôle",
	"widgets.viewRole": "Rôle",
	"widgets.editRole": "Modification du Rôle",
	"widgets.addParam": "Ajouter parametre",
	"widgets.reqParams":"Parametres requis",
	"widgets.companyDetails": "Détails société",
	"widgets.confirmation": "Confirmation de l'action",
	"widgets.expectedSignatures": "Signatures  attendues",
	"widgets.signWithSwing": "Signez avec l'application SWING",
	"widgets.signAs": "Signer en tant que",
	"widgets.newMember": "Nouveau collaborateur",
	"widgets.newOperation": "Nouvelle Opération",
	"widgets.newPayee": "Nouveau Bénéficiaire",
	"widgets.myOrganisations": "Mes organisations",
	"widgets.withArchives": "Tous les roles",
	"widgets.withoutArchives": "Roles actifs",
	"widgets.withTests": "Test",
	"widgets.withoutTests": "Production",
	"widgets.withoutPos": "Pas de Point de Ventes",
	"widgets.withPos": "Inclure un Point de Ventes",
	"sidebar.operationList": "Liste operations",
	"widgets.pendingOps": "Operations en instance",
	"widgets.currencyOps": "Emission/Démonétisation",
	"widgets.OrganizationConfig": "Configuration de l'Organization",
	"widgets.CompanyMembers": "Collaborateurs de l'organisation",
	"widgets.CompanyAccounts": "Comptes société",
	"widgets.CompanyNotVerified": "Société non vérifiée",
	"widgets.NoActivatedAccount": "Comptes société créés mais non activés",
	"widgets.CreateAndActivateAccounts": "Create and activate your corporate account",
	"widgets.CompanyUnderVerification": "Les documents de la société sont à l'étude",
	"widgets.CompanyVerificationFalied": "La creation de la société n'a pas pu etre validée",
	"widgets.requestValidation": "Demander la validation",
	"widgets.empty": "Vide",
	"widgets.noOrganization": "Vous n'êtes pas encore membre d'une organisation enregistrée",
	"widgets.noTestOrganization": "Vous n'êtes pas encore membre d'une organisation dans un environement de tests",
	"widgets.startToCreate": "Commmencez à en créer une",
	"sidebar.authorizations": "Authorisations",
	"sidebar.account": "Compte",
	"sidebar.signatories": "Signataires",	
	"sidebar.roles": "Rôles",
	"sidebar.rolesList": "Liste",
	"sidebar.administration": "Administration",
	"sidebar.banking": "Banking",
	"sidebar.currencies": "Devices mobiles",
	"sidebar.xaf1": "XAF1",
	"sidebar.xaf0": "XAF0 - (demo & tests)",
	"sidebar.specifications": "Spécifications",
	"sidebar.licenses": "Licences",
	"sidebar.license_types": "Type de Licenses",
	"sidebar.management": "Gestion",
	"sidebar.operations": "Opérations",
	"sidebar.pendings": "Pending ops",
	"sidebar.funding": "Approvisionnement",
	"sidebar.withdrawal": "Retrait",
	"sidebar.transfer": "Transfert",
	"sidebar.settings": "Configuration",
	"sidebar.app": "App",
	"sidebar.horizontal": "Horizontal",
	"sidebar.horizontalMenu": "Menu horizontal",
	"sidebar.general": "Général",
	"sidebar.component": "Composant",
	"sidebar.features": "Caractéristiques",
	"sidebar.applications": "Applications",
	"sidebar.dashboard": "Tableau de bord",
	"sidebar.dashboard1": "Tableau de bord 1",
	"sidebar.dashboard2": "Tableau de bord 2",
	"sidebar.dashboard3": "Tableau de bord 3",
	"sidebar.modules": "Modules",
	"sidebar.agency": "Agence",
	"sidebar.pages": "Pages",
	"sidebar.gallery": "Galerie",
	"sidebar.pricing": "Tarification",
	"sidebar.terms&Conditions": "Termes et conditions",
	"sidebar.feedback": "Retour d'information",
	"sidebar.report": "rapport",
	"sidebar.faq(s)": "Faq (s)",
	"sidebar.advancedComponent": "Composant avancé",
	"sidebar.blank": "Blanc",
	"sidebar.session": "Session",
	"sidebar.login": "S'identifier",
	"sidebar.register": "Registre",
	"sidebar.lockScreen": "Écran verrouillé",
	"sidebar.forgotPassword": "Mot de passe oublié",
	"sidebar.404": "404",
	"sidebar.500": "500",
	"sidebar.uiComponents": "Composants de l'interface utilisateur",
	"sidebar.alerts": "Alertes",
	"sidebar.appBar": "App Bar",
	"sidebar.avatars": "Avatars",
	"sidebar.buttons": "Boutons",
	"sidebar.bottomNavigations": "Navigation par le bas",
	"sidebar.badges": "Badges",
	"sidebar.cards": "Cartes",
	"sidebar.cardsMasonry": "Cartes Maçonnerie",
	"sidebar.chip": "Puce",
	"sidebar.dialog": "Dialogue",
	"sidebar.dividers": "Intercalaires",
	"sidebar.drawers": "Tiroirs",
	"sidebar.popover": "Popover",
	"sidebar.expansionPanel": "Panneau d'extension",
	"sidebar.gridList": "Grille Liste",
	"sidebar.list": "liste",
	"sidebar.menu": "Menu",
	"sidebar.popoverAndToolTip": "Pop Over et pointe d'outil",
	"sidebar.progress": "Le progrès",
	"sidebar.snackbar": "Snackbar",
	"sidebar.selectionControls": "Contrôles de sélection",
	"sidebar.advanceUiComponents": "Composants d'interface utilisateur avancés",
	"sidebar.dateAndTimePicker": "Sélecteur de date et d'heure",
	"sidebar.tabs": "onglets",
	"sidebar.stepper": "stepper",
	"sidebar.notification": "Notification",
	"sidebar.sweetAlert": "Alerte douce",
	"sidebar.autoComplete": "auto complet",
	"sidebar.aboutUs": "À propos de nous",
	"sidebar.widgets": "widgets",
	"sidebar.forms": "Formes",
	"sidebar.formElements": "Éléments de formulaire",
	"sidebar.textField": "Champ de texte",
	"sidebar.selectList": "Sélectionner la liste",
	"sidebar.charts": "Graphiques",
	"sidebar.reCharts": "Re Graphiques",
	"sidebar.reactChartjs2": "Réagir Chartjs 2",
	"sidebar.icons": "Icônes",
	"sidebar.themifyIcons": "Thémifier des icônes",
	"sidebar.simpleLineIcons": "Icônes de ligne simple",
	"sidebar.fontAwesome": "Police impressionnante",
	"sidebar.materialIcons": "Icônes de matériel",
	"sidebar.tables": "les tables",
	"sidebar.basic": "De base",
	"sidebar.dataTable": "Tableau de données",
	"sidebar.responsive": "Sensible",
	"sidebar.reactTable": "Réagir à la table",
	"sidebar.maps": "Des plans",
	"sidebar.googleMaps": "Google Plans",
	"sidebar.leafletMaps": "Cartes de brochures",
	"sidebar.inbox": "Boîte de réception",
	"sidebar.users": "Utilisateurs",
	"sidebar.companies": "Sociétés",
	"sidebar.userProfile1": "Profil de l'utilisateur 1",
	"sidebar.userProfile2": "Profil d'utilisateur 2",
	"sidebar.userManagement": "Gestion des utilisateurs",
	"sidebar.userProfile": "Profil de l'utilisateur",
	"sidebar.userList": "liste d'utilisateur",
	"sidebar.calendar": "Calendrier",
	"sidebar.cultures": "Des cultures",
	"sidebar.dnd": "Dnd",
	"sidebar.selectable": "Sélectionnable",
	"sidebar.customRendering": "Rendu personnalisé",
	"sidebar.chat": "Bavarder",
	"sidebar.toDo": "Faire",
	"sidebar.editor": "Éditeur",
	"sidebar.wysiwygEditor": "Éditeur WYSIWYG",
	"sidebar.quillEditor": "Éditeur de piquants",
	"sidebar.reactAce": "Réagir Ace",
	"sidebar.dragAndDrop": "Glisser déposer",
	"sidebar.reactDragula": "Réagir Dragula",
	"sidebar.reactDnd": "Réagir Dnd",
	"sidebar.blogManagement": "Gestion de blog",
	"sidebar.ecommerce": "Commerce électronique",
	"sidebar.shopList": "Liste de magasins",
	"sidebar.shopGrid": "Boutique Grid",
	"sidebar.invoice": "Facture d'achat",
	"sidebar.multilevel": "multiniveau",
	"sidebar.sublevel": "sous-niveau",
	"widgets.totalEarns": "total gagne",
	"widgets.emailsStatistics": "Statistiques des emails",
	"widgets.totalRevenue": "Revenu total",
	"widgets.onlineVistors": "Visiteurs en ligne",
	"widgets.trafficSources": "Sources de trafic",
	"widgets.RecentOrders": "Dernières commandes",
	"widgets.topSellings": "Meilleures ventes",
	"widgets.productReports": "Rapports de produit",
	"widgets.productStats": "Statistiques du produit",
	"widgets.ComposeEmail": "Écrire un email",
	"widgets.employeePayroll": "Employee Payroll",
	"widgets.visitors": "Visiteurs",
	"widgets.orders": "Ordres",
	"widgets.orderStatus": "Statut de la commande",
	"widgets.totalSales": "Ventes totales",
	"widgets.ratings": "Évaluations",
	"widgets.netProfit": "Bénéfice net",
	"widgets.overallTrafficStatus": "État général du trafic",
	"widgets.tax": "Impôt",
	"widgets.expenses": "Expenses",
	"widgets.currentTime": "Heure actuelle",
	"widgets.currentDate": "Date actuelle",
	"widgets.todayOrders": "Commandes d'aujourd'hui",
	"widgets.toDoList": "Faire des listes",
	"widgets.discoverPeople": "Découvrir les gens",
	"widgets.commments": "commentaires",
	"widgets.newCustomers": "nouveaux clients",
	"widgets.recentNotifications": "Notifications récentes",
	"widgets.appNotifications": "Notifications d'application",
	"widgets.newEmails": "Nouveaux emails",
	"widgets.siteVisitors": "Visiteurs du site",
	"widgets.socialCompanines": "Entreprises sociales",
	"widgets.recentActivities": "Activités récentes",
	"widgets.recentOrders": "Dernières commandes",
	"widgets.gallery": "Galerie",
	"widgets.pricing": "Tarification",
	"widgets.enterpriseEdition": "Edition pour entreprise",
	"widgets.personalEdition": "Édition personnelle",
	"widgets.teamEdition": "Édition d'équipe",
	"widgets.standard": "la norme",
	"widgets.advanced": "Avancée",
	"widgets.master": "Maîtriser",
	"widgets.Mega": "Mega",
	"widgets.logIn": "s'identifier",
	"widgets.signUp": "S'inscrire",
	"widgets.lockScreen": "Écran verrouillé",
	"widgets.alertsWithLink": "Alertes avec lien",
	"widgets.additionalContent": "Contenu additionnel",
	"widgets.alertDismiss": "Refus d'alerte",
	"widgets.uncontrolledDisableAlerts": "Désactiver les alertes non contrôlées",
	"widgets.contexualAlerts": "Alertes contextuelles",
	"widgets.alertsWithIcons": "Alertes avec des icônes",
	"widgets.Simple App Bars": "Barres d'application simples",
	"widgets.appBarsWithButtons": "Barres d'applications avec boutons",
	"widgets.imageAvatars": "Avatars d'images",
	"widgets.lettersAvatars": "Lettres Avatars",
	"widgets.iconsAvatars": "Avatars d'icônes",
	"widgets.flatButtons": "Boutons plats",
	"widgets.raisedButton": "Bouton surélevé",
	"widgets.buttonWithIconAndLabel": "Bouton avec icône et étiquette",
	"widgets.floatingActionButtons": "Boutons d'action flottants",
	"widgets.iconButton": "Bouton d'icône",
	"widgets.socialMediaButton": "Bouton Médias sociaux",
	"widgets.reactButton": "Bouton Réagir",
	"widgets.buttonOutline": "Contour du bouton",
	"widgets.buttonSize": "Taille du bouton",
	"widgets.buttonState": "Etat du bouton",
	"widgets.buttonNavigationWithNoLabel": "bouton Navigation sans étiquette",
	"widgets.buttonNavigation": "Bouton de navigation",
	"widgets.iconNavigation": "Icône de navigation",
	"widgets.badgeWithHeadings": "Badge avec des titres",
	"widgets.contexualVariations": "Variations Contextuelles",
	"widgets.badgeLinks": "Liens de Badge",
	"widgets.materialBadge": "Badge de matériel",
	"widgets.simpleCards": "Cartes simples",
	"widgets.backgroundVarient": "Varient d'arrière-plan",
	"widgets.cardOutline": "Carte contour",
	"widgets.overlayCard": "Carte de superposition",
	"widgets.cardGroup": "Groupe de cartes",
	"widgets.cardTitle": "Titre de la carte",
	"widgets.speacialTitleTreatment": "Traitement du titre speacial",
	"widgets.chipWithClickEvent": "Puce avec Click Click",
	"widgets.chipArray": "Chip Array",
	"widgets.dialogs": "Dialogues",
	"widgets.listDividers": "List Diviseurs",
	"widgets.insetDividers": "Intercalaires",
	"widgets.temporaryDrawers": "Tiroirs temporaires",
	"widgets.permanentDrawers": "Tiroirs permanents",
	"widgets.simpleExpansionPanel": "Panneau d'extension simple",
	"widgets.controlledAccordion": "Accordéon contrôlé",
	"widgets.secondaryHeadingAndColumns": "En-tête secondaire et colonnes",
	"widgets.imageOnlyGridLists": "Listes de grille d'images seulement",
	"widgets.advancedGridLists": "Listes de grille avancées",
	"widgets.singleLineGridLists": "Listes de grille unifilaires",
	"widgets.simpleLists": "Listes simples",
	"widgets.folderLists": "Listes de dossiers",
	"widgets.listItemWithImage": "Elément de liste avec image",
	"widgets.switchLists": "Commuter les listes",
	"widgets.insetLists": "Listes d'encart",
	"widgets.nestedLists": "Listes imbriquées",
	"widgets.checkboxListControl": "Contrôle de liste de cases à cocher",
	"widgets.pinedSubHeader": "Sous-en-tête",
	"widgets.InteractiveLists": "Listes interactives",
	"widgets.simpleMenus": "Menus simples",
	"widgets.selectedMenu": "Menu sélectionné",
	"widgets.maxHeightMenu": "Max Height Menu",
	"widgets.changeTransition": "Changer la transition",
	"widgets.paper": "Papier",
	"widgets.anchorPlayGround": "Terrain de jeu d'ancre",
	"widgets.tooltip": "Info-bulle",
	"widgets.positionedToolTips": "Snackbar positionné",
	"widgets.circularProgressBottomStart": "Progression circulaire Début inférieur",
	"widgets.interactiveIntegration": "Intégration interactive",
	"widgets.determinate": "Déterminé",
	"widgets.linearProgressLineBar": "Barre de progression linéaire",
	"widgets.indeterminate": "Indéterminé",
	"widgets.buffer": "Tampon",
	"widgets.query": "Question",
	"widgets.transitionControlDirection": "Direction du contrôle de la transition",
	"widgets.simpleSnackbar": "Snackbar simple",
	"widgets.positionedSnackbar": "Snackbar positionné",
	"widgets.contexualColoredSnackbars": "Snackbars colorés contexuels",
	"widgets.simpleCheckbox": "Case à cocher simple",
	"widgets.interminateSelection": "Sélection interminable",
	"widgets.disabledCheckbox": "Case à cocher désactivée",
	"widgets.customColorCheckbox": "Case à cocher de couleur personnalisée",
	"widgets.VerticalStyleCheckbox": "Case à cocher Style vertical",
	"widgets.horizontalStyleCheckbox": "Case à cocher Style horizontal",
	"widgets.radioButtons": "Badges publicitaires",
	"widgets.disabledRadio": "Radio désactivée",
	"widgets.withError": "Avec erreur",
	"widgets.switches": "Swiches",
	"widgets.dateAndTimePicker": "Sélecteur de date et d'heure",
	"widgets.defaultPicker": "Sélection par défaut",
	"widgets.timePicker": "Time Picker",
	"widgets.weekPicker": "Sélecteur de semaine",
	"widgets.defaultDatePicker": "Sélecteur de date par défaut",
	"widgets.customPicker": "Sélecteur personnalisé",
	"widgets.tabs": "Tabs",
	"widgets.fixedTabs": "Onglets fixes",
	"widgets.basicTab": "Onglet Basic",
	"widgets.wrappedLabels": "Étiquettes enveloppées",
	"widgets.centeredLabels": "Étiquettes centrées",
	"widgets.forcedScrolledButtons": "Boutons défilants forcés",
	"widgets.iconsTabs": "Onglets d'icônes",
	"widgets.withDisableTabs": "Avec les onglets Désactiver",
	"widgets.iconWithLabel": "Icône avec étiquette",
	"widgets.stepper": "Stepper",
	"widgets.horizontalLinear": "Linéaire linéaire",
	"widgets.horizontalNonLinear": "Horizontal non linéaire",
	"widgets.horizontalLinerAlternativeLabel": "Étiquette alternative de doublure horizontale",
	"widgets.horizontalNonLinerAlternativeLabel": "Étiquette alternative horizontale non doublure",
	"widgets.verticalStepper": "Stepper vertical",
	"widgets.descriptionAlert": "Description Alerte",
	"widgets.customIconAlert": "Alerte d'icône personnalisée",
	"widgets.withHtmlAlert": "avec Html Alert",
	"widgets.promptAlert": "Alerte rapide",
	"widgets.passwordPromptAlert": "Alerte de mot de passe",
	"widgets.customStyleAlert": "Alerte de style personnalisé",
	"widgets.autoComplete": "Auto complet",
	"widgets.reactSelect": "React Select",
	"widgets.downshiftAutoComplete": "Réacheminement automatique terminé",
	"widgets.reactAutoSuggests": "Réagissez Auto Suggests",
	"widgets.aboutUs": "À propos de nous",
	"widgets.ourVission": "Notre vision",
	"widgets.ourMissions": "Nos missions",
	"widgets.ourMotivation": "Notre motivation",
	"widgets.defualtReactForm": "Defualt React Form",
	"widgets.url": "Url",
	"widgets.textArea": "Zone de texte",
	"widgets.file": "Fichier",
	"widgets.formGrid": "Grille de formulaire",
	"widgets.inlineForm": "Formulaire en ligne",
	"widgets.inputSizing": "Dimensionnement d'entrée",
	"widgets.inputGridSizing": "Dimensionnement de la grille d'entrée",
	"widgets.hiddenLabels": "Étiquettes cachées",
	"widgets.formValidation": "Validation de formulaire",
	"widgets.number": "Nombre",
	"widgets.date": "Rendez-vous amoureux",
	"widgets.time": "Temps",
	"widgets.color": "Couleur",
	"widgets.search": "Chercher",
	"widgets.selectMultiple": "Sélectionnez plusieurs",
	"widgets.inputWithSuccess": "Entrer avec succès",
	"widgets.inputWithDanger": "Entrée avec danger",
	"widgets.simpleTextField": "Champ de texte simple",
	"widgets.componet": "Composants",
	"widgets.layouts": "Layouts",
	"widgets.inputAdorements": "Adorations d'entrée",
	"widgets.formattedInputs": "Entrées formatées",
	"widgets.simpleSelect": "Simple Select",
	"widgets.nativeSelect": "Sélection native",
	"widgets.MutltiSelectList": "Liste de sélection Mutlti",
	"widgets.lineChart": "Graphique en ligne",
	"widgets.barChart": "Diagramme à bandes",
	"widgets.stackedBarChart": "Graphique à barres empilées",
	"widgets.lineBarAreaChart": "Graphique de la barre de ligne",
	"widgets.areaChart": "Graphique de zone",
	"widgets.stackedAreaChart": "Graphique de zone empilée",
	"widgets.verticalChart": "Graphique vertical",
	"widgets.radarChart": "Graphique radar",
	"widgets.doughnut": "Beignet",
	"widgets.polarChart": "Graphique polaire",
	"widgets.pieChart": "Camembert",
	"widgets.bubbleChart": "Graphique à bulles",
	"widgets.horizontalBar": "Barre horizontale",
	"widgets.basicTable": "Table de base",
	"widgets.contexualColoredTable": "Table colorée contexuelle",
	"widgets.dataTable": "Tableau de données",
	"widgets.employeeList": "Liste des employés",
	"widgets.responsiveTable": "Responsive Table",
	"widgets.responsiveFlipTable": "Table à bascule souple",
	"widgets.reactGridControlledStateMode": "Réagir le mode d'état contrôlé par la grille",
	"widgets.googleMaps": "Google Maps",
	"widgets.productsReports": "Rapports de produits",
	"widgets.taskList": "Liste de tâches",
	"widgets.basicCalender": "Calendrier basique",
	"widgets.culturesCalender": "Cultures Calendrier",
	"widgets.dragAndDropCalender": "Drag and Drop Calender",
	"widgets.selectableCalender": "Calendrier sélectionnable",
	"widgets.customRendering": "Rendu personnalisé",
	"widgets.customCalender": "Calendrier personnalisé",
	"widgets.searchMailList": "Rechercher une liste de courrier",
	"components.buyNow": "Acheter maintenant",
	"compenets.choose": "Choisir",
	"compenets.username": "Nom d'utilisateur",
	"compenets.passwords": "Mots de passe",
	"widgets.forgetPassword": "Mot de passe oublié",
	"compenets.signIn": "Se connecter",
	"compenets.dontHaveAccountSignUp": "N'avez pas d'inscription de compte",
	"compenets.enterUserName": "Saisissez votre nom d'utilisateur",
	"compenets.enterEmailAddress": "Entrer l'adresse e-mail",
	"compenets.confirmPasswords": "Confirmer les mots de passe",
	"components.alreadyHavingAccountSignIn": "Vous avez déjà un compte Connexion",
	"components.enterYourPassword": "Tapez votre mot de passe",
	"components.unlock": "Ouvrir",
	"components.enterPasswords": "Entrer les mots de passe",
	"components.resetPassword": "réinitialiser le mot de passe",
	"components.pageNotfound": "Page non trouvée",
	"components.goToHomePage": "Aller à la page d'accueil",
	"components.sorryServerGoesWrong": "Désolé, le serveur va mal",
	"components.persistentDrawer": "Tiroir persistant",
	"components.back": "Arrière",
	"components.next": "Prochain",
	"components.completeStep": "Étape complète",
	"components.withHtml": "Avec Html",
	"components.prompt": "Rapide",
	"components.withDescription": "Avec description",
	"components.success": "Succès",
	"components.passwordPrompt": "Invite de mot de passe",
	"components.warning": "Attention",
	"components.customIcon": "Icône personnalisée",
	"components.customStyle": "Style personnalisé",
	"components.basic": "De base",
	"components.submit": "Soumettre",
	"components.compose": "Composer",
	"components.sendMessage": "Envoyer le message",
	"components.addNewTasks": "Ajouter de nouvelles tâches",
	"components.addToCart": "Ajouter au panier",
	"components.payNow": "Payez maintenant",
	"components.print": "Impression",
	"components.cart": "Chariot",
	"components.viewCart": "Voir le panier",
	"components.checkout": "Check-out",
	"widgets.QuickLinks": "Liens rapides",
	"widgets.upgrade": "améliorer",
	"widgets.app": "App",
	"widgets.addNew": "Ajouter un nouveau",
	"widgets.import": "Importer .csv",
	"widgets.orderDate": "Date de commande",
	"widgets.status": "Statut",
	"widgets.trackingNumber": "Numéro de suivi",
	"widgets.action": "action",
	"widgets.designation": "La désignation",
	"widgets.subject": "assujettir",
	"widgets.send": "Envoyer",
	"widgets.saveAsDrafts": "Enregistrer en tant que brouillons",
	"widgets.onlineSources": "Sources en ligne",
	"widgets.lastMonth": "Le mois dernier",
	"widgets.widgets": "Widgets",
	"widgets.listing": "Inscription",
	"widgets.paid": "Payé",
	"widgets.refunded": "Remboursé",
	"widgets.done": "Terminé",
	"widgets.pending": "en attendant",
	"widgets.cancelled": "Annulé",
	"widgets.approve": "Approuver",
	"widgets.following": "Suivant",
	"widgets.follow": "suivre",
	"widgets.graphs&Charts": "Graphiques et graphiques",
	"widgets.open": "Ouvrir",
	"widgets.bounced": "Rebondi",
	"widgets.spam": "Spam",
	"widgets.unset": "Annuler",
	"widgets.bandwidthUse": "Utilisation de la bande passante",
	"widgets.dataUse": "Utilisation de données",
	"widgets.unsubscribe": "Se désabonner",
	"widgets.profile": "Profil",
	"widgets.messages": "messages",
	"widgets.support": "Soutien",
	"widgets.faq(s)": "Faq (s)",
	"widgets.upgradePlains": "Mettre à niveau les plaines",
	"widgets.logOut": "Connectez - Out",
	"widgets.mail": "Courrier",
	"widgets.adminTheme": "Thème Admin",
	"widgets.wordpressTheme": "Thème Wordpress",
	"widgets.addToCart": "Ajouter au panier",
	"widgets.plan": "Plan",
	"widgets.basic": "De base",
	"widgets.pro": "Pro",
	"widgets.startToBasic": "Début à la base",
	"widgets.upgradeToPro": "Passer à Pro",
	"widgets.upgradeToAdvance": "Mettre à niveau à l'avance",
	"widgets.comparePlans": "Comparer les plans",
	"widgets.free": "Gratuit",
	"widgets.frequentlyAskedQuestions": "Questions fréquemment posées",
	"widgets.searchIdeas": "Rechercher des idées",
	"widgets.startDate": "Date de début",
	"widgets.endDate": "Date de fin",
	"widgets.category": "Catégorie",
	"widgets.apply": "Appliquer",
	"widgets.downloadPdfReport": "Télécharger le rapport Pdf",
	"widgets.yesterday": "Hier",
	"widgets.totalOrders": "Total des commandes",
	"widgets.totalVisitors": "Nombre total de visiteurs",
	"widgets.typeYourQuestions": "Tapez vos questions",
	"widgets.username": "Nom d'utilisateur",
	"widgets.password": "Mot de passe",
	"widgets.signIn": "Se connecter",
	"widgets.enterYourPassword": "Tapez votre mot de passe",
	"widgets.alreadyHavingAccountLogin": "Vous avez déjà un identifiant de compte",
	"widgets.composeMail": "Écrire un email",
	"widgets.issue": "Problème",
	"widgets.recentChat": "Chat récent",
	"widgets.previousChat": "Chat précédent",
	"widgets.all": "Tout",
	"widgets.filters": "Filtres",
	"widgets.deleted": "Supprimé",
	"widgets.starred": "Marqué",
	"widgets.frontend": "L'extrémité avant",
	"widgets.backend": "Backend",
	"widgets.api": "Api",
	"widgets.simpleAppBar": "Barre d'application simple",
	"widgets.recents": "Récents",
	"widgets.cardLink": "Lien de carte",
	"widgets.anotherLink": "Un autre lien",
	"widgets.cardSubtitle": "Sous-titre de la carte",
	"widgets.confirmationDialogs": "Dialogues de confirmation",
	"widgets.deletableChip": "Puce Deletable",
	"widgets.customDeleteIconChip": "Chip d'icône de suppression personnalisée",
	"widgets.openAlertDialog": "Ouvrir la boîte de dialogue d'alerte",
	"widgets.openResponsiveDialog": "Ouvrir le dialogue réactif",
	"widgets.openSimpleDialog": "Ouvrir une boîte de dialogue simple",
	"widgets.openFormDialog": "Ouvrir la boîte de dialogue de formulaire",
	"widgets.follower": "Disciple",
	"widgets.important": "Important",
	"widgets.private": "Privé",
	"widgets.openLeft": "Ouvrir à gauche",
	"widgets.openRight": "Ouvert à droite",
	"widgets.openTop": "Toit ouvert",
	"widgets.openBottom": "Fond ouvert",
	"widgets.selectTripDestination": "Sélectionnez la destination du voyage",
	"widgets.pinnedSubheaderList": "Liste de sous-titres épinglés",
	"widgets.singleLineItem": "Élément de ligne unique",
	"widgets.acceptTerms": "Accepter les termes",
	"widgets.optionA": "Option A",
	"widgets.optionB": "Option B",
	"widgets.optionC": "Option C",
	"widgets.optionM": "Option M",
	"widgets.optionN": "Option N",
	"widgets.optionO": "Option O",
	"widgets.customColor": "Couleur personnalisée",
	"widgets.centeredTabs": "Onglets centrés",
	"widgets.multipleTabs": "Onglets multiples",
	"widgets.preventScrolledButtons": "Empêcher les boutons défilés",
	"widgets.browse": "Feuilleter",
	"widgets.formValidate": "Formulaire de validation",
	"widgets.code": "Code",
	"widgets.company": "Compagnie",
	"widgets.price": "Prix",
	"widgets.change": "Changement",
	"widgets.high": "Haute",
	"widgets.low": "Faible",
	"widgets.volume": "Le volume",
	"widgets.personalDetails": "Détails personnels",
	"widgets.occupation": "Occupation",
	"widgets.companyName": "Nom de la compagnie",
	"widgets.phoneNo": "Pas de téléphone",
	"widgets.city": "Ville",
	"widgets.zipCode": "Code postal",
	"widgets.updateProfile": "Mettre à jour le profil",
	"widgets.reject": "Rejeter",
	"widgets.exportToExcel": "Exporter vers Excel",
	"widgets.addNewUser": "Ajouter un nouvel utilisateur",
	"widgets.workWeek": "Semaine de travail",
	"widgets.agenda": "Ordre du jour",
	"widgets.conference": "Conférence",
	"widgets.multilevel": "Multilevel",
	"widgets.dailySales": "Ventes quotidiennes",
	"widgets.today": "Aujourd'hui",
	"widgets.campaignPerformance": "Performance de la campagne",
	"widgets.supportRequest": "Demande de soutien",
	"widgets.usersList": "Liste des utilisateurs",
	"widgets.lastWeek": "La semaine dernière",
	"themeOptions.sidebarOverlay": "Superposition de la barre latérale",
	"themeOptions.sidebarBackgroundImages": "Images de fond de la barre latérale",
	"themeOptions.sidebarImage": "Image de la barre latérale",
	"themeOptions.miniSidebar": "Mini barre latérale",
	"themeOptions.boxLayout": "Box Layout",
	"themeOptions.rtlLayout": "Rtl Layout",
	"themeOptions.darkMode": "Mode sombre",
	"themeOptions.appSettings": "Paramètres de l'application",
	"themeOptions.sidebarLight": "Lumière",
	"themeOptions.sidebarDark": "Foncé",
	"button.cancel": "Annuler",
	"button.add": "Ajouter",
	"button.update": "Mettre à jour",
	"button.reply": "Répondre",
	"button.delete": "Effacer",
	"button.yes": "Oui",
	"button.viewAll": "Voir tout",
	"button.like": "Comme",
	"button.assignNow": "Assigner maintenant",
	"button.seeInsights": "Voir Insights",
	"sidebar.dateTimePicker": "Sélecteur de date et d'heure",
	"components.summary": "Résumé",
	"hint.whatAreYouLookingFor": "Que recherchez-vous",
	"components.yesterday": "Hier",
	"components.last7Days": "Les 7 derniers jours",
	"components.last1Month": "Le dernier mois",
	"components.last6Month": "Les 6 derniers mois",
	"components.spaceUsed": "Espace utilisé",
	"components.followers": "disciple",
	"components.trending": "Tendance",
	"components.paid": "Payé",
	"components.refunded": "Remboursé",
	"components.done": "Terminé",
	"components.pending": "En attente",
	"components.cancelled": "Annulé",
	"components.approve": "Approuver",
	"components.week": "Semaine",
	"components.month": "Month",
	"components.year": "Année",
	"components.today": "Aujourd'hui",
	"components.popularity": "Popularité",
	"components.email": "Email",
	"components.drafts": "brouillons",
	"components.sent": "Envoyé",
	"components.trash": "Corbeille",
	"components.all": "Tous",
	"components.do": "faire",
	"components.title": "Titre",
	"components.projectName": "Nom du projet",
	"components.companyName": "Nom de l'entreprise",
	"components.openAlert": "Ouvrir l'alerte",
	"components.slideInAlertDialog": "Diapositive dans la boîte de dialogue d'alerte",
	"components.openFullScreenDialog": "Ouvrir les dialogues plein écran",
	"components.basicChip": "Puce de base",
	"components.clickableChip": "Cliquable",
	"components.left": "Gauche",
	"components.right": "Droit",
	"components.expansionPanel1": "Panneau d'extension 1",
	"components.expansionPanel2": "Panneau d'extension 2",
	"components.generalSetting": "Paramètre général",
	"components.advancedSettings": "Paramètres avancés",
	"components.firstName": "Prénom",
	"components.lastName": "Nom de famille",
	"components.shortName": "Abrégé",
	"components.occupation": "Occupation",
	"components.phoneNo": "Numéro de téléphone",
	"components.address": "Adresse",
	"components.city": "Ville",
	"components.state": "Etat",
	"components.zipCode": "Code postal",
	"components.social Connection": "Connexion sociale",
	"widgets.buyMore": "Acheter plus",
	"widgets.trafficChannel": "Canal de trafic",
	"widgets.stockExchange": "Bourse",
	"widgets.tweets": "Tweets",
	"widgets.ourLocations": "Nos sites",
	"widgets.sales": "Ventes",
	"widgets.to": "Pour",
	"widgets.shipTo": "Expédier à",
	"widgets.description": "Description",
	"widgets.unitPrice": "Prix unitaire",
	"widgets.total": "total",
	"widgets.note": "Remarque",
	"widgets.chipWithAvatar": "Puce avec Avatar",
	"widgets.chipWithTextAvatar": "Chip avec l'avatar de texte",
	"widgets.chipWithIconAvatar": "Puce avec icône Avatar",
	"widgets.customClickableChip": "Puce cliquable personnalisée",
	"widgets.outlineChip": "Puce de contour",
	"widgets.disableChip": "Désactiver la puce",
	"widgets.alertDialog": "Dialogue d'alerte",
	"widgets.animatedSlideDialogs": "Dialogues de diapositives animées",
	"widgets.fullScreenDialogs": "Dialogue plein écran",
	"widgets.formDialogs": "Form Dialogues",
	"widgets.simpleDialogs": "Dialogues simples",
	"widgets.responsiveFullScreen": "Plein écran réactif",
	"widgets.primary": "Primaire",
	"widgets.social": "Social",
	"widgets.user": "Utilisateur",
	"widgets.admin": "Admin",
	"widgets.permanentdrawer": "Tiroir permanent",
	"widgets.persistentdrawer": "Tiroir persistant",
	"widgets.swiches": "Swiches",
	"widgets.horizontalLinearAlternativeLabel": "Étiquette alternative linéaire horizontale",
	"widgets.horizontalNonLinearAlternativeLabel": "Étiquette alternative non linéaire horizontale",
	"widgets.notifications": "Notifications",
	"widgets.basicAlert": "Alerte de base",
	"button.acceptTerms": "Accepter les termes",
	"button.reject": "Rejeter",
	"button.addNew": "Ajouter un nouveau",
	"button.goToCampaign": "Aller à la campagne",
	"button.viewProfile": "Voir le profil",
	"button.sendMessage": "Envoyer un message",
	"button.saveNow": "Enregistrer maintenant",
	"button.pen": "Stylo",
	"button.search": "Rechercher",
	"button.downloadPdfReport": "Télécharger le rapport Pdf",
	"button.primary": "Primaire",
	"bouton.secondaire": "Secondaire",
	"bouton.danger": "Danger",
	"button.info": "Info",
	"button.success": "Succès",
	"button.warning": "Avertissement",
	"button.link": "Lien",
	"button.smallButton": "Petit bouton",
	"button.largeButton": "Grand bouton",
	"button.blockLevelButton": "Bouton de niveau de bloc",
	"button.primaryButton": "Bouton principal",
	"button.button": "Bouton",
	"button.save": "Enregistrer",
	"button.openMenu": "Ouvrir le menu",
	"button.openWithFadeTransition": "Ouvrir avec une transition de fondu",
	"button.openPopover": "Ouvrir Popover",
	"button.accept": "Accepter",
	"button.click": "Cliquer",
	"button.complete": "Achevée",
	"button.back": "Arrière",
	"button.next": "Prochain",
	"button.completeStep": "Étape complète",
	"button.error": "Erreur",
	"button.writeNewMessage": "Ecrire un nouveau message",
	"button.saveChanges": "Sauvegarder les modifications",
	"button.addNewUser": "Ajouter un nouvel utilisateur",
	"button.more": "Plus",
	"hint.searchMailList": "Rechercher une liste de courrier",
	"widgets.AcceptorrRejectWithin": "Accepter ou rejeter dans",
	"widgets.quoteOfTheDay": "Citation du jour",
	"widgets.updated10Minago": "Mis à jour il y a 10 minutes",
	"widgets.personalSchedule": "Horaire personnel",
	"widgets.activeUsers": "Utilisateurs actifs",
	"widgets.totalRequest": "Demande totale",
	"widgets.new": "Nouveau",
	"widgets.ShareWithFriends": "Partager avec des amis!",
	"widgets.helpToShareText": "Aidez-nous à propager le monde en partageant notre site avec vos amis et adeptes sur les réseaux sociaux!",
	"widgets.thisWeek": "Cette semaine",
	"widgets.howWouldYouRateUs": "Comment nous évalueriez-vous?",
	"widgets.booking": "Réservation",
	"widgets.confirmed": "Confirmé",
	"widgets.monthly": "Mensuel",
	"widgets.weekly": "hebdomadaire",
	"widgets.target": "Target",
	"widgets.totalActiveUsers": "Total des utilisateurs actifs",
	"sidebar.user": "Utilisateur",
	"sidebar.miscellaneous": "Divers",
	"sidebar.promo": "Promo",
	"themeOptions.themeColor": "Couleur du thème",
	"module.inbox": "Boîte de réception",
	"module.drafts": "brouillons",
	"module.sent": "Envoyé",
	"module.trash": "Corbeille",
	"module.spam": "Spam",
	"module.frontend": "L'extrémité avant",
	"module.backend": "Backend",
	"module.api": "Api",
	"module.issue": "Problème",
	"components.emailPrefrences": "Préférences de messagerie",
	"components.myProfile": "Mon profil",
	"sidebar.gettingStarted": "Commencer",
	"widgets.deadline": "Date limite",
	"widgets.team": "Équipe",
	"widgets.projectManagement": "Gestion de projet",
	"widgets.latestPost": "Dernier message",
	"widgets.projectTaskManagement": "Gestion des tâches de projet",
	"widgets.selectProject": "Sélectionner un projet",
	"widgets.activityBoard": "Conseil d'activité",
	"widgets.checklist": "Liste de contrôle",
	"sidebar.shop": "Boutique",
	"sidebar.cart": "Chariot",
	"sidebar.checkout": "Check-out",
	"components.companiesAndRoles": "Organisations et Roles",
	"components.product": "Produit",
	"components.quantity": "Quantité",
	"components.totalPrice": "Prix total",
	"components.removeProduct": "Supprimer le produit",
	"components.mobileNumber": "Numéro de portable",
	"components.address2Optional": "Adresse 2 (facultatif)",
	"components.country": "Pays",
	"components.zip": "Zip *: français",
	"components.saveContinue": "Enregistrer continuer",
	"components.placeOrder": "Passer la commande",
	"components.payment": "Paiement",
	"components.billingAddress": "Adresse de facturation",
	"components.ShippingAddressText": "L'adresse de livraison est la même que l'adresse de facturation.",
	"components.CartEmptyText": "Votre panier est vide!",
	"components.NoItemFound": "Aucun article trouvé",
	"components.goToShop": "Aller à la boutique",
	"components.cardNumber": "Numéro de carte",
	"components.expiryDate": "Date d'expiration",
	"components.cvv": "CVV",
	"components.nameOnCard": "Nom sur carte",
	"components.confirmPayment": "Confirmer le paiement",
	"sidebar.saas": "SAAS",
	"sidebar.multiLevel": "MultiLevel",
	"sidebar.level1": "Niveau 1",
	"sidebar.level2": "Niveau 2",
	"sidebar.boxed": "En boîte",
	"sidebar.news": "Nouvelles",
	"sidebar.extensions": "Les extensions",
	"sidebar.imageCropper": "Cropper d'image",
	"sidebar.videoPlayer": "Lecteur vidéo",
	"sidebar.dropzone": "Zone de largage",
	"widgets.baseConfig": "Base Config",
	"widgets.customControlBar": "Barre de contrôle personnalisée",
	"widgets.withDownloadButton": "Avec bouton de téléchargement",
	"widgets.httpLiveStreaming": "HTTP Live Streaming",
	"widgets.keyboardShortcuts": "Raccourcis clavier",
	"button.useDefaultImage": "Utiliser l'image par défaut",
	"button.cropImage": "Crop Image",
	"widgets.preview": "Aperçu",
	"widgets.croppedImage": "Image recadrée"
}