import { FullMembership, OrganizationType, Organization } from 'Models';
import navLinks, { NavCategory, ISidebarMenu } from 'Components/Sidebar/NavLinks';
import { NavMenu } from "Models/nav_menu";
import * as _ from 'lodash'
import { filterUndef } from 'Helpers/helpers';

export interface ISidebarItemProps {
    header: string
    category: string
    types?: OrganizationType[]
}

export function sidebarItem(categ: NavCategory): ISidebarItemProps {
    let res: ISidebarItemProps
    switch (categ) {
        case "category2":
            res = {
                header: "sidebar.developers",
                category: "category2"
            }
            break;
        case "category3":
            res = {
                header: "sidebar.component",
                category: "category3"
            }
            break;
        case "category4":
            res = {
                header: "sidebar.features",
                category: "category4"
            }
            break;
        case "category5":
            res = {
                header: "sidebar.applications",
                category: "category5"
            }
            break;
        case "category6":
            res = {
                header: "sidebar.extensions",
                category: "category6"
            }
            break;
        case "banking":
            res = {
                header: "sidebar.banking",
                category: "banking"
            }
            break;
        case "management":
            res = {
                header: "sidebar.administration",
                category: "management"
            }
            break;
        default:
            //category1
            res = {
                header: "sidebar.general",
                category: "category1"
            }
    }
    return res
}

const full: NavCategory[] = ["category1", "category2", "category3", "category4", "category5", "category6"]

function memberUndef(menu: NavMenu, member: FullMembership): boolean {
    return menu.partnerTypes !== undefined && menu.partnerTypes.indexOf(member.partner.type) < 0
}

export function getNavLinks(category: NavCategory, member: FullMembership): NavMenu[] {
    const { partner } = member
    let _categories = (navLinks[category] || []).map(ctg => {
        return memberUndef(ctg, member) ? undefined : ctg
    })
    let categories = filterUndef<NavMenu>(_categories)
    if (categories == undefined) categories = []
    if (partner == null) return []
    else if (partner.shortName == Organization.TEST_SHORT_NAME) return categories
    else {
        categories.forEach(cat => {
            let subRoutes = cat.child_routes || []
            let _filtered = subRoutes.map(sr => {
                return memberUndef(sr, member) ? undefined : sr
            })
            let filtered = filterUndef<NavMenu>(_filtered)
            cat.child_routes = filtered
        })
        return categories
        //return filterUndef<NavMenu>(rootMenus)
    }
}

export function _getNavLinks(category: NavCategory, member: FullMembership): NavMenu[] | undefined {
    return navLinks[category]
}

export const fullSubmenuItem = full

function partnerCategories(member: FullMembership): NavCategory[] {
    let res: NavCategory[] = ["category1", "management", "category2"]
    switch (member.partner.type) {
        case OrganizationType.IssuingBank:
            res.push("banking")
            break;
    }
    return res
}

function generateSideMenu(member: FullMembership): ISidebarMenu {
    //console.log("generating sideMenu for ", member)
    let group = partnerCategories(member)
    let sideMenu: ISidebarMenu = {}
    group.forEach(categ => {
        sideMenu[categ] = getNavLinks(categ, member)
    })
    return sideMenu
}

export function sidemenuProps(member: FullMembership): ISidebarMenu {
    if (member.partner == undefined) return {}
    else if (member.partner.shortName == Organization.TEST_SHORT_NAME) return navLinks
    else return generateSideMenu(member)
}
