export interface IConfig{
    api_end_point: string,
    client_name: string,
    client_code: string,
    client_id: string,
    private_key: string,
    private_key_id: string
}
export default <IConfig> {
    api_end_point: "https://api.system-id.com",
    client_name: "Kash-Abriva",
    client_code: "noisy-lake-4597",
    client_id: "HrzaXO3c3YZWhD9MBodn",
    private_key: "-----BEGIN RSA PRIVATE KEY-----\nMIIEowIBAAKCAQEAk/iReupk9IXPaddt9wHNfaBqgZNFEx453w1uf1Pkt2SIo5gN\nqT+22Il1ZCj4CMnY3ui6DfQTm6C0+hoRvVv9UNSY4pHRPDPXezjXR9K8rlXfRbHf\nXbuNfRKdFeUhdMWCfzUvvRr5OZ2keC8gcfHKQnQ7O1ugrMVBxbN/B/cXcC0z0AlG\naZazIzUb4tHm50tDW0+ShQNQrcb1MJfCW1ymacHLWg9r7e0L+Kk5aU7+fiKRazZv\nZqZn82r50WqAwgl11RIe1DP4NOfOef47GkFUmKCpzLPussmB8dyhlaEbIr9J13h6\n+9XmeiIdBIqJkTgmL3vh7xUr16mOW8mU8rAt6wIDAQABAoIBADs/8vDm3lnTIsRc\n0NlxTVkaXMtZrvUCy2V3OWGetByDEvt+rSmyK4L1N55NkVPjjwVi5u0jaJ6OcSkc\nOzI5nWs6VaWTgda7Y3MhxYIq7gZYuDhuCuS7buJAC8pynzzkwWYyY9Uy8cySRQMi\ny7kUekq1w+n2vJWxvq5tO1ZzKF1wADgqOhYoTYf2ye6CAh9HMjoxGweipCoDi8Ia\nl0gnoxJk1LcVDWidxaIm2eQ/xA+xyqyucxIdfnfF3DNvjP33RSSSAIIEzF/TCFZO\nhTSESLdfkac5i7CjYRlebS8gIcLRNV3h3fw0GOInLWRNEznpSwKbBNi1vguD8WWP\nctrfTzECgYEAyLn8p8ayCU6DeWfyp8qFA++PeRHnSMYszj3fANUlKsNgXm7fUDxg\nGePcfrqIKaKhGUOB7J4F5wwV7pyA0l6IrsqrM6z0TH2z2crJAu4nS91XVrx7y0Oh\nIkEgwcBFtSx4wMvggw3mXKB2OYiVgpdrHR2NT4KqLqOWG/hGGp4xuV8CgYEAvLeh\n88H9TveEcutzDbLgfpVBlH8Xn5gUT5ekbom4PeDz4avvvNDzh86ibfmzfksvvEMq\nTKfN8DMqyKmb2UwlauSjPDNaJJkQj9ILr5ZSrmF+0BSJ+hbXgSTYw6oRxpbb1FiS\nItUyADgkRiypO5YnmFuQpxuFUjgEaij5JJPN+vUCgYEAnYqEB3aGk5Fg1S/FnvXv\nckhalg58NXz8+55J4dBojGitwVBJyyKn1oolq/1hEzSGNlrqpTjdrxqoOA/zd6kk\no/ODbVqIn2RlfUGQhNFAXGXLv9LNYpqg5E6zk/4BeBIAcGlTu+hz7i0uvTc7CTUo\np8BjAY2vXk2cajVxaEDTDAcCgYBk14uHAcYmCEWSUxZr34Xunjso6rDo6w8PaOzI\n1HbLOgWYNZQpxU4c5+IL+j5b4jlj1FAqHhEBpZXbHCbXesUT1ZEUfixRGimrcIYm\nEaGk5RwqfI8uQD5wGAxsodhV0g8t7RZJ3rqVO2rGxN65pIcL9wHZCe+3+48AW1ym\nmwSacQKBgBnzowx+0/irflvuK4gNxCW/Aq5lONrrrtgaQyCXSiGq1d3EpzwjZauk\nZZ9Fpough00Pj7Dy02+17fzdFXm04wK+qmMES+apgHDyT+t1qqw/KaMSnmlon5KU\nJNQy5C2sJB7HoznPK8xlfVH8Rgt1d8bwKWZKMmfQ1OJI/X9Jwban\n-----END RSA PRIVATE KEY-----",
    private_key_id: "381d0cb8af6687dba7fcccab0bd9b61c139bf92f"
};