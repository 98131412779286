import { NotificationManager } from 'react-notifications';
import { sidMessaging } from '../firebase_config';
import {
    REQUEST_FCM_PERMISSION,
    FCM_PERMISSION_GRANTED,
    FCM_PERMISSION_REFUSED,
    TOKEN_REQUEST,
    TOKEN_SUCCESS,
    TOKEN_FAILURE,
    UNKNOWN_MSG,
    LOGIN_MSG
} from 'Actions/types';


export const requestFCMPermission = () =>{
    function request(){
        return {type: REQUEST_FCM_PERMISSION};
    }
    return (dispatch) => {
        dispatch(request());
        sidMessaging.requestPermission()
            .then(()=> {
                dispatch({type: FCM_PERMISSION_GRANTED});
                dispatch(requestToken());
            })
            .catch((e) =>{
              dispatch({type: FCM_PERMISSION_REFUSED});
            });
    }
};


export const requestToken = () => {
  return (dispatch) => {
    dispatch(request());
    sidMessaging.getToken()
      .then((token) => {
            // you probably want to send your new found FCM token to the
            // application server so that they can send any push
            // notification to you.
        dispatch(success(token));
      })
      .catch((error) => {
        if (error.code === 'messaging/permission-blocked') {
          console.log('Please Unblock Notification Request  Manually');
        } else {
          console.log('Error Occurred', error);
        }
        dispatch(failure(error));
        // return Promise.reject(error);
      });
    sidMessaging.onMessage((payload) => {
      console.log('received FCM message:', payload);
      dispatch(parseFCMMessage(payload));
    });
  };
  function request() {
    return {type: TOKEN_REQUEST};
  }
  function success(token) {
    return {type: TOKEN_SUCCESS, payload: token};
  }
  function failure(error) {
    NotificationManager.warning(error.message, 'Login registration error');
    return {type: TOKEN_FAILURE, error};
  }

  function parseFCMMessage(payload) {
    return (dispatch) => {
      var data = payload.data;
      switch (data.type) {
        case LOGIN_MSG:
          dispatch({type: LOGIN_MSG, payload: data.token});
          break;
        default:
          dispatch({type: UNKNOWN_MSG, data});
      }
    };
  }

};