import { BaseRecord } from "./base";
import Table from './table';
import { DocumentData } from "@google-cloud/firestore";
import { STANDARD_ACCOUNT_LENGTH } from "Constants";
import * as _ from 'lodash'

export enum PayeeType {
    USER, PARTNER
}

export class NewPayee extends BaseRecord {
    coll_ref: string = Table.Payee

    account: string
    email: string
    contact: string
    name: string
    external: boolean


    static init(ext: boolean): NewPayee {
        let obj = new NewPayee()
        obj.account = ""
        obj.email = ""
        obj.contact = ""
        obj.name = ""
        obj.external = ext
        return obj
    }


    isValid(): boolean {
        return this.external ?
            this.name.length > 1 :
            this.account.length === STANDARD_ACCOUNT_LENGTH
    }
}

export class Payee extends NewPayee {

    name: string
    phone: string
    type: PayeeType
    mcId: string
    external: boolean

    static fromAccountData(accountData: DocumentData): Payee {
        const payee = new Payee()
        payee.account = accountData.code
        payee.name = accountData.name
        payee.type = accountData.accountType
        payee.mcId = accountData.mcId
        return payee
    }

    data(): DocumentData {
        const _data: DocumentData = {}
        const fields = ["name", "phone", "type", "account", "email", "contact", "mcId", "external"]
        fields.forEach(field => {
            if (this[field] !== undefined) _data[field] = this[field]
        })
        return _data
    }
}

export class ExtPayee extends Payee {
    external: boolean = true

    data(): DocumentData {
        const _data: DocumentData = {}
        const fields = ["name", "phone", "email", "contact", "external"]
        fields.forEach(field => {
            if (this[field] !== undefined) _data[field] = this[field]
        })
        return _data
    }
}

