import React, { Component, Fragment } from "react";
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IntlMessages from 'Util/IntlMessages';
import NavMenuItem from './NavMenuItem';
import { NavMenu } from "Models/nav_menu";

// redux actions
import { onToggleMenu } from 'Actions';
import { ApplicationState } from "src/reducers";
import { FullMembership } from "Models";
import { NavCategory } from "./NavLinks";

class SidebarItem extends Component<SidebarItemProps> {

    toggleMenu(menu: NavMenu, stateCategory: string) {
		let data = {
			menu,
			stateCategory
        }
		this.props.onToggleMenu(data);
	}

    render() {
        const { sidebarMenus } = this.props.sidebar;
        const { category, header, member } = this.props;
        //console.log("sidebarMenus", sidebarMenus)
        //console.log("category", category)
        return (
            <Fragment>
                <List
                    className="rct-mainMenu p-0 m-0 list-unstyled"
                    subheader={
                        <ListSubheader className="side-title">
                            <IntlMessages id={header} />
                        </ListSubheader>}
                >
                    {sidebarMenus[category].map((menu, key) => (
                        <NavMenuItem
                            menu={menu}
                            key={menu.menu_title}
                            onToggleMenu={() => this.toggleMenu(menu, category)}
                        />
                    ))}
                </List>
            </Fragment>
        )
    }
}


// map state to props
const mapStateToProps = ({ sidebar, settings, authUser }: ApplicationState) => {
    const { member } = authUser
    return { sidebar, settings, member };
};

// Props passed from mapStateToProps
interface PropsFromState {
    sidebar: any
    settings: any
    member: FullMembership
}

// Component-specific props.
interface ComponentProps {
    children: (props: PropsFromState) => React.ReactNode
    onToggleMenu: ({menu: NavMenu, stateCategory: string}) => void
    category: NavCategory
    header: string
}

type SidebarItemProps = PropsFromState & ComponentProps;

export default withRouter(connect(mapStateToProps, { onToggleMenu })(SidebarItem));
//export default withRouter(connect(mapStateToProps)(SidebarContent));
