import { BaseGroup } from './base';
import { Entry } from 'Models/entry';
import { CorporateAccount } from 'Models';
import {
  BaseAccount,
  accountTypeString,
  AccountType,
  RawAccount,
} from 'Models/account/base';
import * as _ from 'lodash';
import { groupBy } from 'Lib/group_by';

export class AccountGroup extends BaseGroup {
  code: string;
  name: string;
  status: number;
  balance: string;
  entries: Entry[];

  constructor(account: BaseAccount, entries?: Entry[]) {
    super(account.code);
    this.code = account.code;
    this.name = account.name;
    this.status = account.status;
    //let balance = account.balance == undefined ? DEFAULT_BALANCE : account.balance
    //this.balance = Intl.NumberFormat('fr-FR',{ style: 'currency', currency: 'XAF'}).format(balance.value)
    if (entries != undefined && entries != null) {
      this.setEntries(entries);
    }
  }

  setEntries(_entries: Entry[]) {
    const entries = _.uniqBy(_entries, e => e.id);
    let sum = (list: Entry[]) => {
      return list.map(x => x.amount).reduce((prev, curr) => prev + curr, 0);
    };
    let credits = entries.filter(x => x.creditId == this.code);
    let debits = entries.filter(x => x.debitId == this.code);
    this.entries = debits
      .concat(credits)
      .sort((a, b) => b.effectDate.toMillis() - a.effectDate.toMillis());
    let balance = sum(credits) - sum(debits);
    this.balance = Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'XAF',
    }).format(balance);
  }
}

export class CategoryGroup extends BaseGroup {
  accounts: AccountGroup[];
  categ: AccountType;
  constructor(categ: AccountType) {
    super(categ.toString());
    this.categ = categ;
    this.header = accountTypeString(categ);
  }
}

export class CurrencyGroup extends BaseGroup {
  categories: CategoryGroup[];
}

export type RawAccountGroup = { [mcId: string]: RawAccount[] };

export function generateAccountsGroup(
  list: BaseAccount[],
  entries: Entry[]
): CurrencyGroup[] {
  let fn = (elm: BaseAccount) => {
    return elm.mcId;
  };
  let group1 = groupBy(list, fn);
  let currencyGroups: CurrencyGroup[] = [];
  group1.forEach((value, key) => {
    let cg = new CurrencyGroup(key);
    cg.categories = [];
    let fn2 = (elm: CorporateAccount) => {
      return elm.accountType;
    };
    let group2 = groupBy(value, fn2);
    group2.forEach((accounts, _categ) => {
      let categ = _categ as AccountType;
      let cg1 = new CategoryGroup(categ);
      cg1.accounts = accounts.map(acc => new AccountGroup(acc, entries));
      cg.categories.push(cg1);
    });
    currencyGroups.push(cg);
  });
  return currencyGroups;
}
