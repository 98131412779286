import { BaseRecord } from "./base";
import firebase from 'firebase';
import { Category } from "Constants/swing_parameters";
import Table from './table'
import { DocumentData } from "@google-cloud/firestore";

export enum OperationStatus {
    INIT = 0,
    SUBMITTED = 10,
    NOTIFIED = 20,
    NOT_CONFIRMED = 100,
    BID = 110,
    EXECUTING_CONTRACT = 120,
    SHIPPING = 140,
    OK = 200,
    CANCELLED = -1,
    FAILED = -10,
    ACTION_FAILED = -20,
    OUTBIDDED = -30
}

export enum OperationType {
    WIRE_IN,
    WIRE_OUT,
    INT_XFER,
    EXT_XFER
}

export class Operation extends BaseRecord {
    coll_ref: string = Table.Operation

    amount: number
    mcId: string
    debitId: string
    debitName: string
    creditId: string
    creditName: string
    reference: string
    info: string
    status: OperationStatus
    ref: string
    category: Category
    partnerId: string
    effectDate?: firebase.firestore.Timestamp
    createdAt: firebase.firestore.Timestamp
    supporting?: string | string[]
    extBankAccount?: DocumentData
    currencyOp: boolean
}