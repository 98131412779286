import { Activable } from "./activable";
import { NotificationType, NotificationTypeValue } from "Constants";
import moment from 'moment';
import { firestore } from 'firebase';


export class GroupNotification extends Activable {
    coll_ref: string = "group-notifications"

    title: string
    content: string
    nType: NotificationType = NotificationType.DEFAULT
    html: string
    scheduledAt: firestore.Timestamp = firestore.Timestamp.now()

    nTypeString(): string {
        return NotificationTypeValue(this.nType)
    }

    getDate(): string {
        let mm = moment(this.scheduledAt.toDate())
        return mm.format("YYYY-MM-DD")
    }

    getTime(): string {
        let mm = moment(this.scheduledAt.toDate())
        return mm.format("HH:mm:ss")
    }

    setTime(data: string) {
        console.log("should set time from ", data)
    }

    setDate(data: string) {
        console.log("should set date from ", data)
    }

}