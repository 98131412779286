import Table from './table';
import { Activable } from "./activable";
import * as _ from 'lodash';

export enum AuthType {
    Operation,
    Setting
}

export enum AccessType {
    R, W
}

export enum DefaultRole {
    Boss = 'BOSS',
    Administator = 'ADMN',
    Supervisor = 'SPRV',
    Controller = 'CTRL',
    Agent = 'AGNT',
    Guest = 'GUEST'
}

export enum RolePrivilege {
    OPS_R = "OPS_R",
    OPS_W = "OPS_W",
    CONFIG_R = "CONFIG_R",
    CONFIG_W = "CONFIG_W",
}

export function allPrivileges(): RolePrivilege[] {
    return Object.keys(RolePrivilege).map(x => RolePrivilege[x] as RolePrivilege)
}

export class Role extends Activable {
    coll_ref: string = Table.Role
    id: string;
    name: string;
    description: string
    privileges: RolePrivilege[]

    static NEW_ID = "new"

    private static boss: Role
    private static admn: Role
    private static srpv: Role
    private static ctrl: Role
    private static agnt: Role
    private static guest: Role

    private static makeNullRole(): Role {
        let role = new Role()
        role.id = this.NEW_ID
        role.name = "form.select_role"
        role.description = "form.select_role"
        role.privileges = []
        return role
    }

    private static make(field: DefaultRole, privs: RolePrivilege[]): Role {
        let type = field.toString().toLocaleLowerCase()
        let role = Role[type]
        if (role === undefined) {
            role = new Role()
            role.id = field.toString()
            role.name = field.toString()
            role.description = `role.${field}`
            role.privileges = privs
        }
        return role
    }

    static get(roleString: string, custom: Role[]): Role {
        const roles = this.defaults.concat(custom)
        return roles.find(x => x.id === roleString) || Role.Guest
    }

    static SELECT = Role.makeNullRole()
    static Boss = Role.make(DefaultRole.Boss, allPrivileges())
    static Administator = Role.make(DefaultRole.Administator, [RolePrivilege.CONFIG_W, RolePrivilege.CONFIG_R])
    static Supervisor = Role.make(DefaultRole.Supervisor, [RolePrivilege.CONFIG_W, RolePrivilege.CONFIG_R])
    static Controller = Role.make(DefaultRole.Controller, [RolePrivilege.OPS_W, RolePrivilege.OPS_R])
    static Agent = Role.make(DefaultRole.Agent, [RolePrivilege.OPS_W, RolePrivilege.OPS_R])
    static Guest = Role.make(DefaultRole.Guest, [RolePrivilege.OPS_R])

    static defaults: Role[] = [
        Role.Boss, Role.Supervisor, Role.Administator,
        Role.Controller, Role.Agent, Role.Guest
    ]

    static MANAGEMENT: Role[] = [
        Role.Controller,
        Role.Agent
    ]

    static SETTING: Role[] = [
        Role.Supervisor,
        Role.Administator
    ]

    static NULL: Role

    static getManagementRoles(roles: Role[], selector?: boolean): Role[] {
        let list = !selector ? [] : [Role.SELECT]
        list = list.concat(roles)
        return list.concat(Role.MANAGEMENT)
    }

    static getSettingRoles(roles: Role[], selector?: boolean): Role[] {
        let list = !selector ? [] : [Role.SELECT]
        list = list.concat(roles)
        return list.concat(Role.SETTING)
    }

    toString(): string {
        return name
    }

    isDefault(): boolean {
        let list = Role.defaults.concat([Role.SELECT])
        return list.map(x => x.id).includes(this.id)
    }

    hasAuth(type: AuthType, access?: AccessType): boolean {
        let xcross: RolePrivilege[]
        switch (type) {
            case AuthType.Operation:
                if (access != undefined) {
                    xcross = access == AccessType.R ? [RolePrivilege.OPS_R] : [RolePrivilege.OPS_W]
                } else xcross = [RolePrivilege.OPS_R, RolePrivilege.OPS_W]
                return _.intersection(this.privileges, xcross).length > 0
            case AuthType.Setting:
                if (access != undefined) {
                    xcross = access == AccessType.R ? [RolePrivilege.CONFIG_R] : [RolePrivilege.CONFIG_W]
                } else xcross = [RolePrivilege.CONFIG_R, RolePrivilege.CONFIG_W]
                return _.intersection(this.privileges, xcross).length > 0
        }

    }

}
