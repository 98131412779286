import { BaseRecord } from "./base";
import Table from './table'
import * as _ from 'lodash'
import { Invitation } from "./invitation";

interface IProspectData {
    userId: string
    accountId: string
    mcId: string
}

export class Prospect extends BaseRecord {
    coll_ref: string = Table.Prospect

    userId: string
    accountId: string
    link: Invitation

    extractData(): IProspectData {
        return {
            userId: this.link.userId,
            accountId: this.link.debitId,
            mcId: this.link.mcId
        }
    }


}