import { BaseRecord, Entry } from "Models";
import * as _ from 'lodash'
import {
    LIST_FETCHED,
    LIST_ELEMENT_ADD,
    LIST_ELEMENT_UPDATE,
    LIST_RESET,
    LIST_FETCHING,
    LIST_DELETED,
    ENTRIES_RESET,
    ENTRIES_FETCHED,
    LIST_I18N,
    ADD_I18N,
} from "Actions/types";
import { I18n } from "Models/i18n";

export interface ListState {
    records: BaseRecord[]
    fetching: boolean
    entries: Entry[]
    strings?: I18n[]
}

// initial state
const INIT_STATE: ListState = {
    records: [],
    entries: [],
    fetching: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_FETCHED:
            return action.payload == undefined ? { ...state, fetching: false } : { ...state, records: action.payload, fetching: false }
        case ENTRIES_FETCHED:
            return { ...state, entries: action.payload, fetching: false }
        case LIST_RESET:
            return { ...state, records: [], fetching: false };
        case ENTRIES_RESET:
            return { ...state, entries: [], fetching: false };
        case LIST_FETCHING:
            return { ...state, fetching: true };
        case LIST_DELETED:
            var { records } = state
            _.remove(records, (e: BaseRecord) => e.id === action.payload.id)
            return { ...state, records: records }
        case LIST_I18N:
            return { ...state, strings: action.payload, fetching: false }
        case ADD_I18N:
            const strings = state.strings || []
            strings.push(action.payload)
            return { ...state, strings: strings }
        case LIST_ELEMENT_ADD:
            var records = state.records || []
            records.push(action.payload)
            return { ...state, records: records }
        case LIST_ELEMENT_UPDATE:
            var { records } = state
            var idx = _.indexOf(records.map(x => x.id), action.payload.id)
            if (idx >= 0) {
                records[idx] = action.payload
            }
            return { ...state, records: records }

        default: return { ...state };
    }
}