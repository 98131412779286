/**
* Main App
*/
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import ReactGA from 'react-ga';

// css
import './lib/reactifyCss';

// firebase
import './firebase_config';

// app component
import App from './container/App';

import { configureStore } from './store';

function initializeReactGA() {
    ReactGA.initialize('UA-150314562-1');
    ReactGA.pageview('/invite');
}

const MainApp = () => (
	<Provider store={configureStore()}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Router>
				<Switch>
					<Route path="/" component={App} />
				</Switch>
			</Router>
		</MuiPickersUtilsProvider>
	</Provider>
);

initializeReactGA()

export default MainApp;
