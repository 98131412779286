import { GET_OPERATIONS, LOADING_DATA, GET_CURRENCY_OPERATIONS} from "Actions/types";
import { PartnerOperation } from "Models/partner_operation";

export interface OperationState {
    ops: PartnerOperation[] | null
    currencyOps: PartnerOperation[] | null
    selectedOp: PartnerOperation | null
    searchOp: string
    loading: boolean
}

// initial state
const INIT_STATE: OperationState = {
    ops: null,
    currencyOps: null,
    selectedOp: null,
    searchOp: '',
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_OPERATIONS:
            return { ...state, ops: action.payload}
        case GET_CURRENCY_OPERATIONS:
            return { ...state, currencyOps: action.payload}
        case LOADING_DATA:
                return { ...state, loading: action.payload}
        default: return { ...state };
    }
}