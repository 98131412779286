/**
 * App Reducers
 */
import { combineReducers } from 'redux';
import settings from './settings';
import chatAppReducer from './ChatAppReducer';
import emailAppReducer from './EmailAppReducer';
import sidebarReducer from './SidebarReducer';
import todoAppReducer from './TodoAppReducer';
import authUserReducer, { IAuthUserState } from './AuthUserReducer';
import feedbacksReducer from './FeedbacksReducer';
import ecommerceReducer from './EcommerceReducer';
import kashRequestReducer, { KashRequestState } from './KashRequestReducer';
import fcmReducer from './FcmReducer';
import operationReducer from './OperationReducer';
import membershipReducer, { MyOrganizationsState } from "./CompanyReducer"
import listReducer, { ListState } from './ListReducer';
import { OperationState } from './OperationReducer';
import ibReducer, { IssuingBankState } from './IssuingbankReducer';
import sharings, { ContractsState } from './ContractsReducer';

export interface ApplicationState {
  settings: any
  chatAppReducer: any
  fcm: any
  emailApp: any
  sidebar: any
  todoApp: any
  operation: OperationState
  authUser: IAuthUserState
  feedback: any
  ecommerce: any
  membership: MyOrganizationsState
  kashRequest: KashRequestState
  list: ListState
  ib: IssuingBankState
  sharings: ContractsState
}

const reducers = combineReducers<ApplicationState>({
  settings,
  chatAppReducer,
  fcm: fcmReducer,
  emailApp: emailAppReducer,
  sidebar: sidebarReducer,
  todoApp: todoAppReducer,
  operation: operationReducer,
  authUser: authUserReducer,
  feedback: feedbacksReducer,
  ecommerce: ecommerceReducer,
  membership: membershipReducer,
  kashRequest: kashRequestReducer,
  list: listReducer,
  ib: ibReducer,
  sharings: sharings
});

export default reducers;
