import { UserMembership, Membership } from "Models"
import * as _ from 'lodash'
import api, { listPartnerMembers } from "Api"
import { PARTNER_MEMBERS_LIST } from "./types"


export const getPartnerMembersList = (member: Membership, members: UserMembership[]) => (dispatch) => {
    let cb = (dispatch) => (list: UserMembership[]) => {
        //setPhoneNumbers(list)(dispatch)
        dispatch({ type: PARTNER_MEMBERS_LIST, payload: list })
    }
    listPartnerMembers(member, members, cb(dispatch))
}

const setPhoneNumbers = (members: UserMembership[]) => (dispatch) => {
    const uidx = _.uniq(members.filter(x => x.userPhoneNumber == undefined).map(x => x.userId))
    const promises = uidx.map(x => api.getPhone(x))
    return Promise.all(promises)
        .then(res => {
            let map: { [key: string]: string } = {}
            res.forEach(phone => {
                if (phone !== null) {
                    map[phone.userId] = phone.id
                }
            })
            return Promise.resolve(map)
        })
        .then(map => {
            members.forEach(member => {
                if (member.userPhoneNumber === undefined) {
                    member.userPhoneNumber = map[member.userId]
                }
            })
            dispatch({ type: PARTNER_MEMBERS_LIST, payload: members })
        })
        .catch(e => {
            console.error(e)
        })
}