export default [
    {
        id: 5,
        name: 'John Doe',
        photo_url: require('Assets/avatars/user-2.jpg')
    },
    {
        id: 6,
        name: 'Smith Lee',
        photo_url: require('Assets/avatars/user-3.jpg')
    },
    {
        id: 7,
        name: 'Lisa Roy',
        photo_url: require('Assets/avatars/user-4.jpg')
    },
    {
        id: 8,
        name: 'Lusile Beck',
        photo_url: require('Assets/avatars/user-5.jpg')
    }
];
