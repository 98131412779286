module.exports = {
	"sidebar.app": "앱",
	"sidebar.roles": "역할",
	"sidebar.horizontal": "수평",
	"sidebar.horizontalMenu": "가로 메뉴",
	"sidebar.general": "일반",
	"sidebar.component": "구성 요소",
	"sidebar.features": "풍모",
	"sidebar.applications": "응용 분야",
	"sidebar.dashboard": "계기반",
	"sidebar.dashboard1": "대시 보드 1",
	"sidebar.dashboard2": "대시 보드 2",
	"sidebar.dashboard3": "대시 보드 3",
	"sidebar.modules": "모듈",
	"sidebar.agency": "대리점",
	"sidebar.pages": "페이지",
	"sidebar.gallery": "갱도",
	"sidebar.pricing": "가격",
	"sidebar.terms&Conditions": "이용 약관",
	"sidebar.feedback": "피드백",
	"sidebar.report": "보고서",
	"sidebar.faq(s)": "주장 (들)",
	"sidebar.advancedComponent": "고급 구성 요소",
	"sidebar.blank": "공백",
	"sidebar.session": "세션",
	"sidebar.login": "로그인",
	"sidebar.register": "레지스터",
	"sidebar.lockScreen": "잠금 화면",
	"sidebar.forgotPassword": "비밀번호를 잊으 셨나요",
	"sidebar.404": "404",
	"sidebar.500": "500",
	"sidebar.uiComponents": "UI 구성 요소",
	"sidebar.alerts": "알리미",
	"sidebar.appBar": "앱 바",
	"sidebar.avatars": "아바타",
	"sidebar.buttons": "버튼",
	"sidebar.bottomNavigations": "하단 네비게이션",
	"sidebar.badges": "배지",
	"sidebar.cards": "카드",
	"sidebar.cardsMasonry": "카드 석공 직",
	"sidebar.chip": "칩",
	"sidebar.dialog": "대화",
	"sidebar.dividers": "분할기",
	"sidebar.drawers": "서랍",
	"sidebar.popover": "изменение",
	"sidebar.expansionPanel": "확장 패널",
	"sidebar.gridList": "그리드리스트",
	"sidebar.list": "명부",
	"sidebar.menu": "메뉴",
	"sidebar.popoverAndToolTip": "팝 오버 및 툴팁",
	"sidebar.progress": "진행",
	"sidebar.snackbar": "스낵바",
	"sidebar.selectionControls": "선택 컨트롤",
	"sidebar.advanceUiComponents": "고급 UI 구성 요소",
	"sidebar.dateAndTimePicker": "날짜 및 시간 선택기",
	"sidebar.tabs": "탭",
	"sidebar.stepper": "스테퍼",
	"sidebar.notification": "공고",
	"sidebar.sweetAlert": "달콤한 알리미",
	"sidebar.autoComplete": "자동 완성",
	"sidebar.aboutUs": "회사 소개",
	"sidebar.widgets": "위젯",
	"sidebar.forms": "양식",
	"sidebar.formElements": "양식 요소",
	"sidebar.textField": "텍스트 필드",
	"sidebar.selectList": "목록 선택",
	"sidebar.charts": "차트",
	"sidebar.reCharts": "레 차트",
	"sidebar.reactChartjs2": "차트 2 반응",
	"sidebar.icons": "아이콘",
	"sidebar.themifyIcons": "테마 아이콘",
	"sidebar.simpleLineIcons": "단순한 선 아이콘",
	"sidebar.materialIcons": "재료 아이콘",
	"sidebar.fontAwesome": "글꼴 굉장",
	"sidebar.tables": "테이블",
	"sidebar.basic": "기본",
	"sidebar.dataTable": "데이터 표",
	"sidebar.responsive": "반응 형",
	"sidebar.reactTable": "반응 테이블",
	"sidebar.maps": "지도",
	"sidebar.googleMaps": "구글지도",
	"sidebar.leafletMaps": "전단지지도",
	"sidebar.inbox": "받은 편지함",
	"sidebar.users": "사용자",
	"sidebar.userProfile1": "사용자 프로필 1",
	"sidebar.userProfile2": "사용자 프로필 2",
	"sidebar.userManagement": "사용자 관리",
	"sidebar.userProfile": "유저 프로필",
	"sidebar.userList": "사용자 목록",
	"sidebar.calendar": "달력",
	"sidebar.cultures": "문화",
	"sidebar.dnd": "Dnd",
	"sidebar.selectable": "선택 가능",
	"sidebar.customRendering": "사용자 정의 렌더링",
	"sidebar.chat": "잡담",
	"sidebar.toDo": "할 것",
	"sidebar.editor": "편집자",
	"sidebar.wysiwygEditor": "WYSIWYG 편집기",
	"sidebar.quillEditor": "퀼 에디터",
	"sidebar.reactAce": "반응하는 에이스",
	"sidebar.dragAndDrop": "끌어서 놓기",
	"sidebar.reactDragula": "반응 드래그 라",
	"sidebar.reactDnd": "반응 Dnd",
	"sidebar.blogManagement": "블로그 관리",
	"sidebar.ecommerce": "전자 상거래",
	"sidebar.shopList": "숍리스트",
	"sidebar.shopGrid": "샵 그리드",
	"sidebar.invoice": "송장",
	"sidebar.multilevel": "다중 레벨",
	"sidebar.sublevel": "하위 레벨",
	"widgets.totalEarns": "총 수입",
	"widgets.emailsStatistics": "이메일 통계",
	"widgets.totalRevenue": "총 수익",
	"widgets.onlineVistors": "온라인 방문자",
	"widgets.trafficSources": "트래픽 소스",
	"widgets.RecentOrders": "최근 주문들",
	"widgets.topSellings": "인기 판매",
	"widgets.productReports": "제품 보고서",
	"widgets.productStats": "제품 통계",
	"widgets.ComposeEmail": "이메일을 쓰다",
	"widgets.employeePayroll": "직원 급여",
	"widgets.visitors": "방문객",
	"widgets.orders": "명령",
	"widgets.orderStatus": "주문 상태",
	"widgets.totalSales": "총 매출",
	"widgets.netProfit": "순이익",
	"widgets.overallTrafficStatus": "전체 트래픽 상태",
	"widgets.tax": "세",
	"widgets.expenses": "경비",
	"widgets.currentTime": "현재 시간",
	"widgets.currentDate": "현재 날짜",
	"widgets.todayOrders": "오늘 주문",
	"widgets.toDoList": "할일 목록",
	"widgets.discoverPeople": "사람들을 발견하십시오",
	"widgets.commments": "코멘트",
	"widgets.newCustomers": "신규 고객",
	"widgets.recentNotifications": "최근 알림",
	"widgets.ratings": "평점",
	"widgets.appNotifications": "앱 알림",
	"widgets.newEmails": "새 이메일",
	"widgets.siteVisitors": "사이트 방문자",
	"widgets.socialCompanines": "사회 동반자",
	"widgets.recentActivities": "최근 활동",
	"widgets.recentOrders": "최근 주문들",
	"widgets.gallery": "갱도",
	"widgets.pricing": "가격",
	"widgets.enterpriseEdition": "기업용 에디션",
	"widgets.personalEdition": "개인용 버전",
	"widgets.teamEdition": "팀 에디션",
	"widgets.standard": "표준",
	"widgets.advanced": "많은",
	"widgets.master": "석사",
	"widgets.Mega": "메가",
	"widgets.logIn": "로그인",
	"widgets.signUp": "가입하기",
	"widgets.lockScreen": "잠금 화면",
	"widgets.alertsWithLink": "링크 경고",
	"widgets.additionalContent": "추가 콘텐츠",
	"widgets.alertDismiss": "알림 닫기",
	"widgets.uncontrolledDisableAlerts": "제어 할 수없는 해제 경고",
	"widgets.contexualAlerts": "문맥 경고",
	"widgets.alertsWithIcons": "아이콘으로 경고",
	"widgets.Simple App Bars": "간단한 응용 프로그램 모음",
	"widgets.appBarsWithButtons": "단추가있는 응용 프로그램 막대",
	"widgets.imageAvatars": "이미지 아바타",
	"widgets.lettersAvatars": "편지 아바타",
	"widgets.iconsAvatars": "아이콘 아바타",
	"widgets.flatButtons": "플랫 버튼",
	"widgets.raisedButton": "제기 된 버튼",
	"widgets.buttonWithIconAndLabel": "아이콘 및 레이블 단추",
	"widgets.floatingActionButtons": "플로팅 액션 버튼",
	"widgets.iconButton": "아이콘 버튼",
	"widgets.socialMediaButton": "소셜 미디어 버튼",
	"widgets.reactButton": "반응 버튼",
	"widgets.buttonOutline": "버튼 개요",
	"widgets.buttonSize": "버튼 크기",
	"widgets.buttonState": "버튼 상태",
	"widgets.buttonNavigationWithNoLabel": "레이블이없는 단추 탐색",
	"widgets.buttonNavigation": "버튼 탐색",
	"widgets.iconNavigation": "아이콘 탐색",
	"widgets.badgeWithHeadings": "제목이있는 배지",
	"widgets.contexualVariations": "문맥 변형",
	"widgets.badgeLinks": "배지 링크",
	"widgets.materialBadge": "재료 배지",
	"widgets.simpleCards": "단순 카드",
	"widgets.backgroundVarient": "배경 바리 언트",
	"widgets.cardOutline": "카드 개요",
	"widgets.overlayCard": "오버레이 카드",
	"widgets.cardGroup": "카드 그룹",
	"widgets.cardTitle": "카드 제목",
	"widgets.speacialTitleTreatment": "비평 제목 치료",
	"widgets.chipWithClickEvent": "클릭 이벤트 칩",
	"widgets.chipArray": "칩 어레이",
	"widgets.dialogs": "대화 상자",
	"widgets.listDividers": "목록 분할 자",
	"widgets.insetDividers": "삽입 분배기",
	"widgets.temporaryDrawers": "임시 서랍",
	"widgets.permanentDrawers": "영구 서랍",
	"widgets.simpleExpansionPanel": "단순 확장 패널 ",
	"widgets.controlledAccordion": "통제 아코디언",
	"widgets.secondaryHeadingAndColumns": "보조 제목 및 열",
	"widgets.imageOnlyGridLists": "그리드 목록 전용 이미지",
	"widgets.advancedGridLists": "고급 그리드 목록",
	"widgets.singleLineGridLists": "단일 선 그리드 목록",
	"widgets.simpleLists": "단순 목록",
	"widgets.folderLists": "폴더 목록",
	"widgets.listItemWithImage": "이미지가있는 항목 나열",
	"widgets.switchLists": "스위치 목록",
	"widgets.insetLists": "삽입 목록",
	"widgets.nestedLists": "중첩 목록",
	"widgets.checkboxListControl": "확인란 목록 제어",
	"widgets.pinedSubHeader": "소나무 하위 헤더",
	"widgets.InteractiveLists": "대화식 목록",
	"widgets.simpleMenus": "간단한 메뉴",
	"widgets.selectedMenu": "선택한 메뉴",
	"widgets.maxHeightMenu": "최대 높이 메뉴 ",
	"widgets.changeTransition": "전환 전환 ",
	"widgets.paper": "종이",
	"widgets.anchorPlayGround": "앵커 플레이 그라운드",
	"widgets.tooltip": "도구 설명",
	"widgets.positionedToolTips": "위치 지정된 스낵바",
	"widgets.circularProgressBottomStart": "순환 진행 최저 시작",
	"widgets.interactiveIntegration": "대화 형 통합",
	"widgets.determinate": "한정된",
	"widgets.linearProgressLineBar": "선형 진행률 막대",
	"widgets.indeterminate": "불확정",
	"widgets.buffer": "완충기",
	"widgets.query": "질문",
	"widgets.transitionControlDirection": "전환 제어 방향",
	"widgets.simpleSnackbar": "간단한 스낵바",
	"widgets.positionedSnackbar": "위치 지정된 스낵바",
	"widgets.contexualColoredSnackbars": "컨텍 스 컬러 스낵바",
	"widgets.simpleCheckbox": "간단한 체크 박스",
	"widgets.interminateSelection": "간첩 선택",
	"widgets.disabledCheckbox": "사용 안 함 확인란",
	"widgets.customColorCheckbox": "사용자 정의 색상 확인란",
	"widgets.VerticalStyleCheckbox": "세로 스타일 체크 박스",
	"widgets.horizontalStyleCheckbox": "가로 스타일 체크 상자",
	"widgets.radioButtons": "라디오 버튼",
	"widgets.disabledRadio": "사용 중지 된 라디오",
	"widgets.withError": "오류 발생",
	"widgets.switches": "Swiches",
	"widgets.dateAndTimePicker": "날짜 및 시간 선택기",
	"widgets.defaultPicker": "기본 선택 도구",
	"widgets.timePicker": "시간 선택기",
	"widgets.weekPicker": "주 선택 도구",
	"widgets.defaultDatePicker": "기본 날짜 선택 도구",
	"widgets.customPicker": "사용자 지정 선택기",
	"widgets.tabs": "탭",
	"widgets.fixedTabs": "고정 탭",
	"widgets.basicTab": "기본 탭",
	"widgets.wrappedLabels": "래핑 된 라벨",
	"widgets.centeredLabels": "중앙 레이블",
	"widgets.forcedScrolledButtons": "강제 스크롤 된 버튼",
	"widgets.iconsTabs": "아이콘 탭",
	"widgets.withDisableTabs": "탭 사용 중지",
	"widgets.iconWithLabel": "레이블이있는 아이콘",
	"widgets.stepper": "스테퍼",
	"widgets.horizontalLinear": "수평 선형",
	"widgets.horizontalNonLinear": "가로 비선형",
	"widgets.horizontalLinerAlternativeLabel": "가로형 라이너 대체 라벨",
	"widgets.horizontalNonLinerAlternativeLabel": "가로 비선형 대체 라벨",
	"widgets.verticalStepper": "수직 스테퍼",
	"widgets.descriptionAlert": "설명 경고",
	"widgets.customIconAlert": "사용자 정의 아이콘 경고",
	"widgets.withHtmlAlert": "HTML 경고 있음",
	"widgets.promptAlert": "프롬프트 경고",
	"widgets.passwordPromptAlert": "비밀번호 프롬프트 알림",
	"widgets.customStyleAlert": "사용자 정의 스타일 경고",
	"widgets.autoComplete": "자동 완성",
	"widgets.reactSelect": "반응 선택",
	"widgets.downshiftAutoComplete": "다운 시프트 자동 완성",
	"widgets.reactAutoSuggests": "자동 제안 제안",
	"widgets.aboutUs": "회사 소개",
	"widgets.ourVission": "우리의 비젼",
	"widgets.ourMissions": "우리의 사명",
	"widgets.ourMotivation": "우리의 동기 부여",
	"widgets.defualtReactForm": "불확실한 반응 양식",
	"widgets.url": "유아르 자형엘",
	"widgets.textArea": "텍스트 영역",
	"widgets.file": "파일",
	"widgets.formGrid": "양식 격자",
	"widgets.inlineForm": "인라인 양식",
	"widgets.inputSizing": "입력 크기 조정",
	"widgets.inputGridSizing": "입력 격자 크기 조정",
	"widgets.hiddenLabels": "숨겨진 라벨",
	"widgets.formValidation": "양식 유효성 검사",
	"widgets.number": "숫자",
	"widgets.date": "날짜",
	"widgets.time": "시간",
	"widgets.color": "색깔",
	"widgets.search": "검색",
	"widgets.selectMultiple": "여러 항목 선택",
	"widgets.inputWithSuccess": "성공 입력",
	"widgets.inputWithDanger": "위험으로 입력",
	"widgets.simpleTextField": "간단한 텍스트 필드",
	"widgets.componet": "구성 요소",
	"widgets.layouts": "레이아웃",
	"widgets.inputAdorements": "입력 된 장식품",
	"widgets.formattedInputs": "형식화 된 입력 사항",
	"widgets.simpleSelect": "단순 선택",
	"widgets.nativeSelect": "기본 선택",
	"widgets.MutltiSelectList": "목록 선택",
	"widgets.lineChart": "꺾은 선형 차트",
	"widgets.barChart": "막 대형 차트",
	"widgets.stackedBarChart": "스택 막 대형 차트",
	"widgets.lineBarAreaChart": "선 막대 영역 차트",
	"widgets.areaChart": "영역 차트",
	"widgets.stackedAreaChart": "스택 영역 차트",
	"widgets.verticalChart": "세로 형 차트",
	"widgets.radarChart": "레이더 차트",
	"widgets.doughnut": "도넛",
	"widgets.polarChart": "폴라 차트",
	"widgets.pieChart": "파이 차트",
	"widgets.bubbleChart": "버블 차트",
	"widgets.horizontalBar": "수평 바",
	"widgets.basicTable": "기본 테이블",
	"widgets.contextualColoredTable": "컨텍 스 컬러 테이블",
	"widgets.dataTable": "데이터 테이블",
	"widgets.employeeList": "직원 목록",
	"widgets.responsiveTable": "반응 형 테이블",
	"widgets.responsiveFlipTable": "반응 형 플립 테이블",
	"widgets.reactGridControlledStateMode": "반응 그리드 제어 상태 모드",
	"widgets.googleMaps": "Google지도",
	"widgets.productsReports": "제품 보고서",
	"widgets.taskList": "작업 목록",
	"widgets.basicCalender": "기본 캘린더",
	"widgets.culturesCalender": "문화 캘린더",
	"widgets.dragAndDropCalender": "드래그 앤 드롭 캘린더",
	"widgets.selectableCalender": "선택 가능한 캘린더",
	"widgets.customRendering": "사용자 정의 렌더링",
	"widgets.customCalender": "맞춤 캘린더",
	"widgets.searchMailList": "메일 목록 검색",
	"components.buyNow": "지금 구입",
	"compenets.choose": "선택",
	"compenets.username": "사용자 이름",
	"compenets.passwords": "비밀번호",
	"widgets.forgetPassword": "비밀번호 잊어 버림",
	"compenets.signIn": "로그인",
	"compenets.dontHaveAccountSignUp": "계정 가입이 없습니다.",
	"compenets.enterUserName": "사용자 이름 입력",
	"compenets.enterEmailAddress": "이메일 주소 입력",
	"compenets.confirmPasswords": "암호 확인",
	"components.alreadyHavingAccountSignIn": "이미 계정 로그인 중입니다.",
	"components.enterYourPassword": "비밀번호 입력",
	"components.unlock": "잠금 해제",
	"components.enterPasswords": "비밀번호 입력",
	"components.resetPassword": "암호를 재설정",
	"components.pageNotfound": "페이지를 찾을 수 없습니다",
	"components.goToHomePage": "홈 페이지로 이동",
	"components.sorryServerGoesWrong": "죄송합니다 서버가 잘못되었습니다.",
	"components.persistentDrawer": "영구 드로어",
	"components.back": "뒤로",
	"components.next": "다음",
	"components.completeStep": "완료 단계",
	"components.withHtml": "HTML과 함께",
	"components.prompt": "프롬프트",
	"components.withDescription": "설명 포함",
	"components.success": "성공",
	"components.passwordPrompt": "비밀번호 프롬프트",
	"components.warning": "경고",
	"components.customIcon": "사용자 지정 아이콘",
	"components.customStyle": "사용자 정의 스타일",
	"components.basic": "기본",
	"components.submit": "제출",
	"components.compose": "작성",
	"components.sendMessage": "메시지 보내기",
	"components.addNewTasks": "새 작업 추가",
	"components.addToCart": "장바구니에 추가",
	"components.payNow": "지금 지불",
	"components.print": "인쇄",
	"components.cart": "카트",
	"components.viewCart": "장바구니보기",
	"components.checkout": "점검",
	"widgets.QuickLinks": "빠른 링크",
	"widgets.upgrade": "업그레이드",
	"widgets.app": "앱",
	"widgets.addNew": "새로 추가",
	"widgets.orderDate": "주문 날짜",
	"widgets.status": "상태",
	"widgets.trackingNumber": "추적 번호",
	"widgets.action": "액션",
	"widgets.designation": "지정",
	"widgets.subject": "제목",
	"widgets.send": "보내기",
	"widgets.saveAsDrafts": "임시 보관함에 저장",
	"widgets.onlineSources": "온라인 소스",
	"widgets.lastMonth": "지난 달",
	"widgets.widgets": "위젯",
	"widgets.listing": "목록",
	"widgets.paid": "유료",
	"widgets.refunded": "환불 됨",
	"widgets.done": "완료",
	"widgets.pending": "대기 중",
	"widgets.cancelled": "취소됨",
	"widgets.approve": "승인",
	"widgets.following": "다음",
	"widgets.follow": "따르다",
	"widgets.graphs & Charts": "그래프 및 차트",
	"widgets.open": "열기",
	"widgets.bounced": "반송 됨",
	"widgets.spam": "스팸",
	"widgets.unset": "설정 해제",
	"widgets.bandwidthUse": "대역폭 사용",
	"widgets.dataUse": "데이터 사용",
	"widgets.unsubscribe": "구독 취소",
	"widgets.profile": "프로필",
	"widgets.messages": "메시지",
	"widgets.support": "지원",
	"widgets.faq (s)": "주장 (들)",
	"widgets.upgradePlains": "업그레이드 평야",
	"widgets.logOut": "로그 아웃",
	"widgets.mail": "우편",
	"widgets.adminTheme": "관리자 테마",
	"widgets.wordpressTheme": "자료 Wordpress 테마",
	"widgets.addToCart": "장바구니에 추가",
	"widgets.plan": "계획",
	"widgets.basic": "기본",
	"widgets.pro": "찬성",
	"widgets.startToBasic": "기본 시작",
	"widgets.upgradeToPro": "Pro로 업그레이드",
	"widgets.upgradeToAdvance": "전진로 업그레이드",
	"widgets.comparePlans": "비교 계획",
	"widgets.free": "무료",
	"widgets.frequentlyAskedQuestions": "자주 묻는 질문",
	"widgets.searchIdeas": "검색 아이디어",
	"widgets.startDate": "시작 날짜",
	"widgets.endDate": "종료 날짜",
	"widgets.category": "케이트 게이트",
	"widgets.apply": "적용",
	"widgets.downloadPdfReport": "Pdf 보고서 다운로드",
	"widgets.yesterday": "어제",
	"widgets.totalOrders": "총 주문 수",
	"widgets.totalVisitors": "총 방문자수",
	"widgets.typeYourQuestions": "질문 입력",
	"widgets.username": "사용자 이름",
	"widgets.password": "비밀번호",
	"widgets.signIn": "로그인",
	"widgets.enterYourPassword": "비밀번호 입력",
	"widgets.alreadyHavingAccountLogin": "이미 계정 로그인 필요",
	"widgets.composeMail": "편지 쓰기",
	"widgets.issue": "발행물",
	"widgets.recentChat": "최근 채팅",
	"widgets.previousChat": "이전 채팅",
	"widgets.all": "모두",
	"widgets.filters": "필터",
	"widgets.deleted": "삭제됨",
	"widgets.starred": "별표가 있음",
	"widgets.frontend": "프론트 엔드",
	"widgets.backend": "백엔드",
	"widgets.api": "Api",
	"widgets.simpleAppBar": "간단한 응용 프로그램 막대",
	"widgets.recents": "최근",
	"widgets.cardLink": "카드 링크",
	"widgets.anotherLink": "다른 링크",
	"widgets.cardSubtitle": "카드 자막",
	"widgets.confirmationDialogs": "확인 대화 상자",
	"widgets.deletableChip": "삭제 가능 칩",
	"widgets.customDeleteIconChip": "사용자 정의 아이콘 칩 삭제",
	"widgets.openAlertDialog": "경고 대화 상자 열기",
	"widgets.openResponsiveDialog": "반응 형 대화 상자 열기",
	"widgets.openSimpleDialog": "간단한 대화 상자 열기",
	"widgets.openFormDialog": "양식 열기 대화 상자",
	"widgets.follower": "추종자",
	"widgets.important": "중요",
	"widgets.private": "비공개",
	"widgets.openLeft": "왼쪽 열기",
	"widgets.openRight": "오른쪽 열기",
	"widgets.openTop": "상단 열기",
	"widgets.openBottom": "아래쪽 열기",
	"widgets.selectTripDestination": "여행 목적지 선택",
	"widgets.pinnedSubheaderList": "고정 된 하위 헤더 목록",
	"widgets.singleLineItem": "단일 품목",
	"widgets.acceptTerms": "약관에 동의",
	"widgets.optionA": "옵션 에이",
	"widgets.optionB": "옵션 비",
	"widgets.optionC": "옵션 기음",
	"widgets.optionM": "옵션 엠",
	"widgets.optionN": "Option 엔",
	"widgets.optionO": "옵션 영형",
	"widgets.customColor": "사용자 정의 색상",
	"widgets.centeredTabs": "가운데 맞춤 탭",
	"widgets.multipleTabs": "여러 탭",
	"widgets.preventScrolledButtons": "스크롤 된 단추 방지",
	"widgets.browse": "찾아보기",
	"widgets.formValidate": "양식 유효성 검사",
	"widgets.code": "코드",
	"widgets.company": "회사",
	"widgets.price": "가격",
	"widgets.change": "변경",
	"widgets.high": "높음",
	"widgets.low": "낮음",
	"widgets.volume": "볼륨",
	"widgets.personalDetails": "개인 정보",
	"widgets.occupation": "직업",
	"widgets.companyName": "회사 이름",
	"widgets.phoneNo": "전화 번호",
	"widgets.city": "도시",
	"widgets.zipCode": "우편 번호",
	"widgets.updateProfile": "프로필 업데이트",
	"widgets.reject": "거부",
	"widgets.exportToExcel": "뛰어나다로 내보내기",
	"widgets.addNewUser": "새 사용자 추가",
	"widgets.workWeek": "일주일",
	"widgets.agenda": "비망록",
	"widgets.conference": "회의",
	"widgets.multilevel": "다중 레벨",
	"widgets.dailySales": "일일 판매",
	"widgets.today": "오늘",
	"widgets.campaignPerformance": "캠페인 실적",
	"widgets.supportRequest": "지원 요청",
	"widgets.usersList": "사용자 목록",
	"widgets.lastWeek": "지난주",
	"themeOptions.sidebarOverlay": "사이드 바 오버레이",
	"themeOptions.sidebarBackgroundImages": "사이드 바 배경 이미지",
	"themeOptions.appSettings": "앱 설정",
	"themeOptions.sidebarImage": "사이드 바 이미지",
	"themeOptions.miniSidebar": "미니 사이드 바",
	"themeOptions.boxLayout": "상자 레이아웃",
	"themeOptions.rtlLayout": "Rtl 레이아웃",
	"themeOptions.darkMode": "어두운 모드",
	"themeOptions.sidebarLight": "빛",
	"themeOptions.sidebarDark": "어두운",
	"button.cancel": "취소",
	"button.add": "추가",
	"button.update": "업데이트",
	"button.reply": "답장",
	"button.delete": "삭제",
	"button.yes": "예",
	"button.viewAll": "모두보기",
	"button.like": "처럼",
	"button.assignNow": "지금 할당",
	"button.seeInsights": "통계보기",
	"sidebar.dateTimePicker": "날짜 및 시간 선택기",
	"components.summary": "개요",
	"hint.whatAreYouLookingFor": "무엇을 찾고 계십니까?",
	"components.yesterday": "어제",
	"components.last7Days": "최근 7 일",
	"components.last1Month": "지난 1 개월",
	"components.last6Month": "지난 6 개월",
	"components.spaceUsed": "사용 된 공간",
	"components.followers": "수행원",
	"components.trending": "인기 급상승",
	"components.paid": "유료",
	"components.refunded": "환불 됨",
	"components.done": "끝난",
	"components.pending": "대기중인",
	"components.cancelled": "취소 된",
	"components.approve": "승인하다",
	"components.week": "주",
	"components.month": "달",
	"components.year": "년",
	"components.today": "오늘",
	"components.popularity": "인기",
	"components.email": "이메일",
	"components.drafts": "체커",
	"components.sent": "전송 됨",
	"components.trash": "폐물",
	"components.all": "모든",
	"components.do": "해야 할 것",
	"components.title": "표제",
	"components.projectName": "프로젝트 이름",
	"components.companyName": "회사 이름",
	"components.openAlert": "열린 알리미",
	"components.slideInAlertDialog": "슬라이드 알림 대화 상자",
	"components.openFullScreenDialog": "전체 화면 대화 상자 열기",
	"components.basicChip": "기본 칩",
	"components.clickableChip": "클릭 가능한 칩",
	"components.left": "왼쪽",
	"components.right": "권리",
	"components.expansionPanel1": "확장 패널 1",
	"components.expansionPanel2": "확장 패널 2",
	"components.generalSetting": "일반 설정",
	"components.advancedSettings": "고급 설정",
	"components.firstName": "이름",
	"components.lastName": "성",
	"components.occupation": "직업",
	"components.phoneNo": "전화 번호",
	"components.address": "주소",
	"components.city": "시티",
	"components.state": "상태",
	"components.zipCode": "우편 번호",
	"components.social Connection": "사회적 연결",
	"widgets.buyMore": "더 사",
	"widgets.trafficChannel": "트래픽 채널",
	"widgets.stockExchange": "증권 거래소",
	"widgets.tweets": "짹짹",
	"widgets.ourLocations": "우리의 위치",
	"widgets.sales": "매상",
	"widgets.to": "에",
	"widgets.shipTo": "배송지",
	"widgets.description": "기술",
	"widgets.unitPrice": "단가",
	"widgets.total": "합계",
	"widgets.note": "노트",
	"widgets.chipWithAvatar": "아바타가있는 칩",
	"widgets.chipWithTextAvatar": "텍스트 아바타가있는 칩",
	"widgets.chipWithIconAvatar": "아이콘 아바타로 칩",
	"widgets.customClickableChip": "맞춤형 클릭 칩",
	"widgets.outlineChip": "개요 칩",
	"widgets.disableChip": "칩 사용 안 함",
	"widgets.alertDialog": "알림 대화 상자",
	"widgets.animatedSlideDialogs": "애니메이션 슬라이드 대화 상자",
	"widgets.fullScreenDialogs": "전체 화면 대화 상자",
	"widgets.formDialogs": "양식 대화 상자",
	"widgets.simpleDialogs": "간단한 대화 상자",
	"widgets.responsiveFullScreen": "반응 형 전체 화면",
	"widgets.primary": "행성",
	"widgets.social": "사회적인",
	"widgets.user": "사용자",
	"widgets.admin": "관리자",
	"widgets.permanentdrawer": "영구 서랍",
	"widgets.persistentdrawer": "지속 서랍",
	"widgets.swiches": "부끄러움",
	"widgets.horizontalLinearAlternativeLabel": "수평 선형 대체 상표",
	"widgets.horizontalNonLinearAlternativeLabel": "수평 비선형 대체 라벨",
	"widgets.notifications": "알림",
	"widgets.basicAlert": "기본 경고",
	"widgets.successAlert": "성공 알림",
	"widgets.warningAlert": "경고 경고",
	"widgets.reactAutoSuggest": "자동 제안에 반응하십시오.",
	"widgets.components": "구성 요소",
	"widgets.inputAdornments": "입력 경신",
	"widgets.multiSelectList": "다중 선택 목록",
	"widgets.updateYourEmailAddress": "이메일 주소 업데이트",
	"widgets.selectADefaultAddress": "기본 주소 선택",
	"widgets.activity": "활동",
	"widgets.basicCalendar": "기본 달력",
	"widgets.culturesCalendar": "문화 달력",
	"widgets.dragAndDropCalendar": "드래그 앤 드롭 캘린더",
	"widgets.quillEditor": "퀼 에디터",
	"widgets.reactDND": "DND에 대응",
	"widgets.dragula": "드래그 라",
	"button.acceptTerms": "약관 동의",
	"button.reject": "받지 않다",
	"button.addNew": "새로운 걸 더하다",
	"button.goToCampaign": "캠페인으로 이동",
	"button.viewProfile": "프로필보기",
	"button.sendMessage": "문자 보내",
	"button.saveNow": "지금 저장해",
	"button.pen": "펜",
	"button.search": "수색",
	"button.downloadPdfReport": "Pdf 보고서 다운로드",
	"button.primary": "행성",
	"button.secondary": "반성",
	"button.danger": "위험",
	"button.info": "정보",
	"button.success": "성공",
	"button.warning": "경고",
	"button.link": "링크",
	"button.smallButton": "작은 단추",
	"button.largeButton": "큰 버튼",
	"button.blockLevelButton": "블록 레벨 버튼",
	"button.primaryButton": "기본 버튼",
	"button.button": "단추",
	"button.save": "구하다",
	"button.openMenu": "메뉴 열기",
	"button.openWithFadeTransition": "페이드 전환으로 열기",
	"button.openPopover": "열린 팝 오버",
	"button.accept": "동의",
	"button.click": "딸깍 하는 소리",
	"button.complete": "완전한",
	"button.back": "뒤로",
	"button.next": "다음 것",
	"button.completeStep": "완료 단계",
	"button.error": "오류",
	"button.writeNewMessage": "새 메시지 쓰기",
	"button.saveChanges": "변경 사항을 저장하다",
	"button.addNewUser": "새 사용자 추가",
	"button.more": "더",
	"hint.searchMailList": "메일 목록 검색",
	"widgets.AcceptorrRejectWithin": "허용 또는 거부",
	"widgets.quoteOfTheDay": "오늘의 견적",
	"widgets.updated10Minago": "10 시간 전에 업데이트 됨",
	"widgets.personalSchedule": "개인 일정",
	"widgets.activeUsers": "활성 사용자",
	"widgets.totalRequest": "총 요청",
	"widgets.new": "新",
	"widgets.ShareWithFriends": "친구와 공유하십시오!",
	"widgets.helpToShareText": "소셜 미디어에있는 친구 및 추종자와 웹 사이트를 공유하여 세상을 넓힐 수있게 도와주십시오!",
	"widgets.thisWeek": "이번 주",
	"widgets.howWouldYouRateUs": "우리를 어떻게 평가하니?",
	"widgets.booking": "예매",
	"widgets.confirmed": "확인 됨",
	"widgets.monthly": "월간",
	"widgets.weekly": "주간",
	"widgets.target": "목표",
	"widgets.totalActiveUsers": "총 활성 사용자 수",
	"sidebar.user": "사용자",
	"sidebar.miscellaneous": "여러 가지 잡다한",
	"sidebar.promo": "프로모션",
	"themeOptions.themeColor": "테마 색",
	"module.inbox": "받은 편지함",
	"module.drafts": "체커",
	"module.sent": "전송 됨",
	"module.trash": "폐물",
	"module.spam": "스팸",
	"module.frontend": "프론트 엔드",
	"module.backend": "백엔드",
	"module.api": "Api",
	"module.issue": "발행물",
	"components.emailPrefrences": "이메일 환경 설정",
	"components.myProfile": "내 프로필",
	"sidebar.gettingStarted": "시작하기",
	"widgets.deadline": "마감 시간",
	"widgets.team": "팀",
	"widgets.projectManagement": "프로젝트 관리",
	"widgets.latestPost": "최신 우편물",
	"widgets.projectTaskManagement": "프로젝트 작업 관리",
	"widgets.selectProject": "프로젝트 선택",
	"widgets.activityBoard": "활동위원회",
	"widgets.checklist": "체크리스트",
	"sidebar.shop": "가게",
	"sidebar.cart": "카트",
	"sidebar.checkout": "점검",
	"components.companiesAndRoles": "회사와 역할",
	"components.product": "생성물",
	"components.quantity": "수량",
	"components.totalPrice": "총 가격",
	"components.removeProduct": "제품 제거",
	"components.mobileNumber": "휴대 전화 번호",
	"components.address2Optional": "주소 2 (선택 사항)",
	"components.country": "국가",
	"components.zip": "지퍼",
	"components.saveContinue": "저장하고 계속하기",
	"components.placeOrder": "주문하기",
	"components.payment": "지불",
	"components.billingAddress": "청구 지 주소",
	"components.ShippingAddressText": "배송 주소는 청구서 수신 주소와 동일합니다.",
	"components.CartEmptyText": "귀하의 쇼핑 바구니가 비어 있습니다!",
	"components.NoItemFound": "항목을 찾을 수 없음",
	"components.goToShop": "가게에 가다",
	"components.cardNumber": "카드 번호",
	"components.expiryDate": "만료일",
	"components.cvv": "CVV",
	"components.nameOnCard": "카드 이름",
	"components.confirmPayment": "지불 확인",
	"sidebar.saas": "SAAS",
	"sidebar.multiLevel": "다중 레벨",
	"sidebar.level1": "레벨 1",
	"sidebar.level2": "2 단계",
	"sidebar.boxed": "박스형",
	"sidebar.news": "뉴스",
	"sidebar.extensions": "확장 프로그램",
	"sidebar.imageCropper": "이미지 자르기 도구",
	"sidebar.videoPlayer": "Video Player",
	"sidebar.dropzone": "비디오 플레이어",
	"widgets.baseConfig": "기본 구성",
	"widgets.customControlBar": "사용자 정의 컨트롤 막대",
	"widgets.withDownloadButton": "다운로드 버튼 포함",
	"widgets.httpLiveStreaming": "HTTP 실시간 스트리밍",
	"widgets.keyboardShortcuts": "키보드 단축키",
	"button.useDefaultImage": "기본 이미지 사용",
	"button.cropImage": "이미지 자르기",
	"widgets.preview": "시사",
	"widgets.croppedImage": "자른 이미지"
}