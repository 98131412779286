import { Activable } from "./activable";
import Table from './table';
import { AccountType } from "./account/base";
import { PaymentTerm } from "Lib/payment_term";
import { BaseRecord, ParentCreator } from "./base";

export class LicenseType extends Activable {

    coll_ref = Table.LicenseType

    accountTypes: AccountType[]
    actionContractId: string
    active: boolean
    code: string
    name: string
    price: number
    maturities: number = 1
    terms: PaymentTerm
    years: number
}

export class LicenseTypePlus extends LicenseType {
    mcId: string

    constructor(parent?: BaseRecord) {
        super(parent)
        if (parent) {
            this.mcId = parent.id
        } else {
            this.parent = new ParentCreator(this.mcId, Table.MobileCurrency)
            this.mcId = this.parent.id
        }
    }

    static from(ms: LicenseType): LicenseTypePlus {
        ms.code = ms.id
        const msp = ms as LicenseTypePlus
        msp.mcId = ms.parent ? ms.parent.id : "?"
        return msp
    }
}