import axios, { AxiosInstance } from 'axios';
import Sid from './system_id';
import * as firestore from './firestore_client'

let api = axios.create({
    baseURL: 'http://reactify.theironnetwork.org/data/',
    timeout: 2000
});

export default new Sid(api);
export * from './firestore_client'
export * from './error'