/**
 * Users Routes
 */
/* eslint-disable */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// async components
import {
    AsyncActionContractComponent,
    AsyncSharingContractComponent,
    AsyncSharingContractEditComponent,
    AsyncSharingContractNewComponent,
    AsyncActionContractEditComponent,
    AsyncBackfireComponent
} from 'Components/AsyncComponent/AsyncComponent';

const Forms = ({ match }) => (
    <div className="content-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/sharing`} to={`${match.url}/sharings`} />
            <Route path={`${match.url}/actions`} component={AsyncActionContractComponent} />
            <Route path={`${match.url}/action/:id`} component={AsyncActionContractEditComponent} />
            <Route path={`${match.url}/sharings`} component={AsyncSharingContractComponent} />
            <Route path={`${match.url}/sharing/new`} component={AsyncSharingContractNewComponent} />
            <Route path={`${match.url}/sharing/:id`} component={AsyncSharingContractEditComponent} />
            <Route path={`${match.url}/backfires`} component={AsyncBackfireComponent} />
        </Switch>
    </div>
);

export default Forms;
