/**
 * App.js Layout Start Here
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import { requestFCMPermission, requestToken } from '../actions'

// rct theme provider
import RctThemeProvider from './RctThemeProvider';

//Horizontal Layout
import HorizontalLayout from './HorizontalLayout';

//Agency Layout
import AgencyLayout from './AgencyLayout';

//Main App
import RctDefaultLayout from './DefaultLayout';

// boxed layout
import RctBoxedLayout from './RctBoxedLayout';

// app signin
import AppSignIn from './SigninSystemId';

// role
import CompanyRole from './CompanyRole';

//Tawk.to
import TawkTo from '../lib/tawk.to';

// async components
import {
	AsyncSessionLockScreenComponent,
	AsyncSessionPage404Component,
	AsyncSessionPage500Component,
	AsyncTermsConditionComponent,
	AsyncPaymentLinksComponent,
	AsyncInvitationLinksComponent
} from 'Components/AsyncComponent/AsyncComponent';

import {
	NO_FCM_STATUS,
	FCM_PERMISSION_GRANTED,
	NO_TOKEN_STATUS,
} from 'Actions/types';


/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const InitialPath = (props) => {
	const { component, authUser, member, ...rest } = props
	//console.log('props = ', props)
	//console.log("rest ==>", rest)
	return(
		<Route
		{...rest}
		render={props =>
			authUser
				? <SecondStep component={component} path={rest.path} member={member} />
				: <Redirect
					to={{
						pathname: '/signin',
						state: { from: props.location }
					}}
				/>}
	/>
	);
}

/**
 * Second Step Path To Check Whether User has a roleOr Not
 */
const SecondStep = ({ component: Component, member, ...rest }) => {
	return(
		<Route
		{...rest}
		render={props =>
			member
				? <Component {...props} />
				: <Redirect
					to={{
						pathname: '/roles',
						state: { from: props.location }
					}}
				/>}
	/>
	);
}

const TawkToId = "5daa98afdf22d913399fee62"


class App extends Component {


	componentWillMount(){
		if(this.props.fcm.status === NO_FCM_STATUS){
			this.props.requestFCMPermission();
		}
		if(this.props.fcm.status === FCM_PERMISSION_GRANTED){
			if(this.props.fcm.tokenStatus === NO_TOKEN_STATUS){
				this.props.requestToken();
			}
		}
	}

	componentDidMount(){
		if (process.env.NODE_ENV !== 'development'){
			TawkTo.init(TawkToId, ()=>{
				//console.log("Tawk.to is ready")
			})	
		}
	}

	render() {
		const { location, match, user, member } = this.props;
		if (location.pathname === '/') {
			if (user === null) {
				return (<Redirect to={'/signin'} />);
			} else {
				if(member === null){
					return (<Redirect to={{pathname: '/roles', state: {from: this.props.location}}} />);
				} else {
					return (<Redirect to={'/app/dashboard/ecommerce'} />);
				}
			}
		}
		return (
			<RctThemeProvider>
				<NotificationContainer />
				<InitialPath
					path={`${match.url}app`}
					authUser={user}
					member = {member}
					component={RctDefaultLayout}
				/>
				<Route path="/horizontal" component={HorizontalLayout} />
				<Route path="/agency" component={AgencyLayout} />
				<Route path="/boxed" component={RctBoxedLayout} />
				<Route path="/signin" component={AppSignIn} />
				<Route path="/roles" component={CompanyRole} />
				<Route path="/session/lock-screen" component={AsyncSessionLockScreenComponent} />
				<Route path="/session/404" component={AsyncSessionPage404Component} />
				<Route path="/session/500" component={AsyncSessionPage500Component} />
				<Route path="/terms-condition" component={AsyncTermsConditionComponent} />
				<Route path="/link/:linkId" component={AsyncPaymentLinksComponent} />
				<Route path="/invite/:linkId" component={AsyncInvitationLinksComponent} />
			</RctThemeProvider>
		);
	}
}

// map state to props
const mapStateToProps = ({ authUser, fcm }) => {
	const { user, member } = authUser;
	return { user, member, fcm };
};

export default connect(mapStateToProps, { requestFCMPermission, requestToken })(App);
