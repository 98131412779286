import { MarketService } from "Models/market_service"
import { fetchingData } from "./OperationActions"
import { NotificationManager } from 'react-notifications';
import api from "Api"

export const addTemplateService = (ms: MarketService, fbToken: string) => (dispatch) => {
    dispatch(fetchingData(true))
    return api.createMarketService(ms, fbToken)
        .then(_ => {
            dispatch(fetchingData(false))
            NotificationManager.success('Operation created !');
            return Promise.resolve()
        })
        .catch(e => {
            dispatch(fetchingData(false))
            console.error("===>", e)
            NotificationManager.error(e.message, 'Could not process Operation');
            return Promise.reject(e)
        })
}
