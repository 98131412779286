import { BaseRecord, ModelCreator } from "Models/base"
import { QueryVar, OrderVar } from "Lib/query"
import { LIST_FETCHING, LIST_RESET, LIST_ELEMENT_ADD, LIST_FETCHED, LIST_DELETED, LIST_ELEMENT_UPDATE } from "./types"
import api, { getRecordDocuments, delDocument, getDocument, addDocument, updateDocument } from "Api"
import { NotificationManager } from 'react-notifications';
import { Organization, Role, Membership, User } from "Models";
import * as _ from 'lodash'

export interface ActionGroup {
    fetch: string
    add: string
    delete: string
    update: string
    partnerId?: string
}

export const DEFAULT_ACTION_GROUP: ActionGroup = {
    add: LIST_ELEMENT_ADD,
    fetch: LIST_FETCHED,
    delete: LIST_DELETED,
    update: LIST_ELEMENT_UPDATE
}


export function dispatchList<T extends BaseRecord>(action: string, list: T[]) {
    return (dispatch) => {
        dispatch({ type: action, payload: list })
    }
}
export function getListRecords<T extends BaseRecord>(group: ActionGroup)
export function getListRecords<T extends BaseRecord>(group: ActionGroup, viaAPI: boolean, token: string)
export function getListRecords<T extends BaseRecord>(group: ActionGroup, viaAPI: boolean = false, token: string = "") {
    return (dispatch) => {
        return (creator: ModelCreator<T>, parent?: BaseRecord, queries?: QueryVar[], order?: OrderVar) => {
            const action = group.fetch
            const partnerId = group.partnerId
            dispatch({ type: LIST_FETCHING })
            const fn = viaAPI ? api.getRecordsFromApi(token) : getRecordDocuments
            return fn(creator, parent, queries, order)
                .then(res => {
                    dispatch({ type: action, payload: res, partnerId: partnerId })
                    dispatch({ type: LIST_FETCHED })
                    return Promise.resolve(res)
                })
                .catch(error => {
                    dispatch({ type: LIST_FETCHED })
                    console.error(error)
                    return Promise.reject(error)
                })
        }
    }
}

export function addNewRecord<T extends BaseRecord>(group: ActionGroup, viaAPI: boolean = false, token: string = "") {
    return (dispatch) => {
        return (element: T) => {
            dispatch({ type: LIST_FETCHING })
            const action = group.add
            const fn = viaAPI ? api.addRecordFromApi(token) : addDocument
            return fn(element)
                .then(res => {
                    dispatch({ type: action, payload: res })
                    dispatch({ type: LIST_FETCHED })
                    return Promise.resolve()
                })
                .catch(error => {
                    dispatch({ type: LIST_FETCHED })
                    return Promise.reject(error)
                })
        }
    }
}

export function updateRecord<T extends BaseRecord>(group: ActionGroup, record: T, viaAPI: boolean = false, token: string = "") {
    return (dispatch) => {
        dispatch({ type: LIST_FETCHING })
        const action = group.update
        const fn = viaAPI ? api.updateRecordFromApi(token) : updateDocument
        return fn(record)
            .then(_ => {
                dispatch({ type: action, payload: record })
                dispatch({ type: LIST_FETCHED })
                return Promise.resolve()
            })
            .catch(error => {
                dispatch({ type: LIST_FETCHED })
                NotificationManager.error(error.message, 'Could not delete');
                return Promise.reject(error)
            })
    }
}

export function deleteRecord<T extends BaseRecord>(group: ActionGroup, record: T, viaAPI: boolean = false, token: string = "") {
    return (dispatch) => {
        dispatch({ type: LIST_FETCHING })
        const action = group.delete
        const fn = viaAPI ? api.deleteRecordFromApi(token) : delDocument
        return fn(record)
            .then(res => {
                dispatch({ type: action, payload: record })
                dispatch({ type: LIST_FETCHED })
                return Promise.resolve()
            })
            .catch(error => {
                dispatch({ type: LIST_FETCHED })
                NotificationManager.error(error.message, 'Could not delete');
                return Promise.reject(error)
            })
    }
}

export const getUsersByRole = (partner: Organization, role: Role) => (dispatch) => {
    dispatch({ type: LIST_RESET })
    dispatch({ type: LIST_FETCHING })
    let queries: QueryVar[] = [["role", "==", role.id], ["partnerId", "==", partner.id]]
    return getRecordDocuments(Membership, undefined, queries)
        .then(res => res.map(x => x.userId).reduce((acc: Promise<User[]>, current: string) => {
            let users: User[]
            return acc.then(res => {
                users = res
                return getDocument(User, current)
                    .then(user => {
                        if (user !== null) users.push(user)
                        return Promise.resolve(users)
                    })
            })
        }, Promise.resolve([])))
        .then(res => {
            dispatch({ type: LIST_FETCHED })
            return Promise.resolve(res)
        })
}

