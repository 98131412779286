/**
 * Users Routes
 */
/* eslint-disable */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// async components
import {
    AsyncCurrencyOperationsListComponent,
    AsyncCurrencyAccountEntriesComponent,
    AsyncNewCurrencyAccountComponent
} from 'Components/AsyncComponent/AsyncComponent';

const Forms = ({ match }) => (
    <div className="content-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/operations`} />
            <Route path={`${match.url}/operations`} component={AsyncCurrencyOperationsListComponent} />
            <Route path={`${match.url}/DPO`} render={(props) => <AsyncCurrencyAccountEntriesComponent {...props} code="DPO"/>}  />
            <Route path={`${match.url}/CIRC`} render={(props) => <AsyncCurrencyAccountEntriesComponent {...props} code="CIRC" editable={true}/>}  />
            <Route path={`${match.url}/LIC`} render={(props) => <AsyncCurrencyAccountEntriesComponent {...props} code="LIC"/>}  />
            <Route path={`${match.url}/VAT`} render={(props) => <AsyncCurrencyAccountEntriesComponent {...props} code="VAT"/>}  />
            <Route path={`${match.url}/new`} component={AsyncNewCurrencyAccountComponent}  />
        </Switch>
    </div>
);

export default Forms;
