import { Activable } from "./activable";
import Table from './table';
import { RawAccount } from "./account/base";
import { BaseRecord } from "./base";

export class MobileCurrency extends Activable {
    coll_ref: string = Table.MobileCurrency
    bic: string
    ctnBankId: string
    currencyCode: string
    feesQuota: number
    iban: string
    name: string
    vat: number

    accounts(db: firebase.firestore.Firestore): Promise<RawAccount[]> {
        return this.documentReference(db).collection(Table.Account).get()
            .then(BaseRecord.resolveSnapshotDocuments(RawAccount, this))
    }

    static accounts(code: string, db: firebase.firestore.Firestore): Promise<RawAccount[]> {
        const mc = new MobileCurrency()
        mc.id = code
        return mc.accounts(db)
    }
}