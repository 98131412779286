import { Operation, OperationStatus } from "./operation";
import { BaseRecord } from "./base";
import { QuerySnapshot } from "@google-cloud/firestore";
import { currencyValue } from "../lib/tools"
import moment from 'moment';
import { DPO_ACCOUNT_CODE } from "Constants";
import { BankInfo } from "./bank_info";
export enum AmountType {
    CREDIT, DEBIT
}

export class PartnerOperation extends Operation {

    amountType: AmountType

    getAccountId(): string {
        switch (this.amountType) {
            case AmountType.CREDIT:
                return this.creditId
            case AmountType.DEBIT:
                return this.debitId
        }
    }

    currencyAmount(): string {
        return currencyValue(this.amount)
    }

    getParty(): string {
        switch (this.amountType) {
            case AmountType.CREDIT:
                return this.getDebitName()
            case AmountType.DEBIT:
                return this.getCreditName()
        }
    }

    getDebitName(): string {
        const { debitName, extBankAccount } = this
        if (debitName === DPO_ACCOUNT_CODE && !!extBankAccount) {
            const acc = new BankInfo()
            acc.assign(extBankAccount)
            return `${acc.accountName} - ${acc.bankName}`
        } else return this.debitName
    }

    getCreditName(): string {
        const { creditName, extBankAccount } = this
        if (creditName === DPO_ACCOUNT_CODE && !!extBankAccount) {
            const acc = new BankInfo()
            acc.assign(extBankAccount)
            return `${acc.accountName} - ${acc.bankName}`
        } else return this.creditName

    }

    getCredit(): string {
        switch (this.amountType) {
            case AmountType.CREDIT:
                return currencyValue(this.amount)
            case AmountType.DEBIT:
                return ""
        }
    }

    getDebit(): string {
        switch (this.amountType) {
            case AmountType.DEBIT:
                return currencyValue(this.amount)
            case AmountType.CREDIT:
                return ""
        }
    }
    badgeClass(): string {
        if (this.amountType == AmountType.CREDIT) return "badge-secondary"
        if (this.status == 0) return "badge-info"
        else if (this.status < 0) return "badge-secondary"
        else if (this.status == OperationStatus.OK) return "badge-success"
        return "badge-primary"
    }

    basdgeStatus(): string {
        switch (this.status) {
            case OperationStatus.INIT:
                return "created"
            case OperationStatus.CANCELLED:
                return "cancelled";
            case OperationStatus.FAILED:
                return "failed";
            case OperationStatus.OK:
                return "OK";
            case OperationStatus.ACTION_FAILED:
                return "Rejected";
            default: return "processing";
        }
    }
    getDate(): string {
        return moment(this.createdAt.toDate().getTime()).format('DD/MM/YYYY')
    }

    static fromSnapshotDb(aType: AmountType): (QuerySnapshot) => PartnerOperation[] {
        return (qs: QuerySnapshot) => {
            return BaseRecord.fromSnapshot(PartnerOperation)(qs).map(op => {
                op.amountType = aType
                return op
            })
        }
    }
}