export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = typeof keyGetter === "function" ? keyGetter(item) : keyGetter;
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}