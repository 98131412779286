import { Activable } from "./activable"
import Table from './table';

export class Backfire extends Activable {

    coll_ref = Table.Backfire

    serviceId: string
    creditAccountId: string
    creditAccountName: string
    creditAccountMcId: string | null = null
    statusField: string
    successValue: number
    isActionContract: boolean = false
}