/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import uuidv4 from 'uuid/v4';
import {QRCode} from 'react-qr-svg';
import { Redirect } from 'react-router-dom';
import {
	Card,
	CardImg,
	CardText,
	CardBody,
	CardTitle,
	CardSubtitle,
  } from 'reactstrap';

// components
import {
	SessionSlider
} from 'Components/Widgets';

// app config
import AppConfig from 'Constants/AppConfig';

// redux action
import {
	generateLoginToken,
	signinUserInSystemId
} from 'Actions';

//Auth File
import Auth from '../Auth/Auth';

const auth = new Auth();

class Signin extends Component {

	state = {
		uuid: uuidv4()
	}

	componentDidUpdate(){
		if(this.props.authUser.loginMsg != null){
			this.props.signinUserInSystemId(this.props.authUser.loginMsg, this.props.history);
			return;
		}
		
		if(this.props.fcm.token == null){
			console.log('props token ==  null');
		} else {
			if(this.props.authUser.tokenGen) return;
			if(!this.props.authUser.uuidReg){
				this.props.generateLoginToken(this.state.uuid, this.props.fcm.token);
			}
		}
	}

	componentWillMount(){
		//if this.props.authUser.uuidReg == true we are un a logout process
		// ==> we should generate login token
		if(this.props.authUser.uuidReg){
			this.props.generateLoginToken(this.state.uuid, this.props.fcm.token);
		}
	}

	render() {
		const { loading } = this.props;
		const { from } = this.props.location.state || { from: { pathname: "/" } };
		const { user } = this.props.authUser ;
		if (user != null) {
			return ( <Redirect to={from} /> );
		}
		return (
			<QueueAnim type="bottom" duration={2000}>
				<div className="">
					{loading &&
						<LinearProgress />
					}
					<AppBar position="static" className="session-header">
						<Toolbar>
							<div className="container">
								<div className="d-flex justify-content-between">
									<div className="session-logo">
										<Link to="/">
											<img src={AppConfig.appLogo} alt="session-logo" className="img-fluid" width="110" height="35" />
										</Link>
									</div>
								</div>
							</div>
						</Toolbar>
					</AppBar>
					<div className="session-inner-wrapper">
						<div className="container">
							<div className="row justify-content-center">
								<div className="col-sm-8 col-md-7 col-lg-6">
									<div className="session-body text-center">
										<div className="session-head mb-30">
											<h2 className="font-weight-bold">Get started with {AppConfig.brandName}</h2>
										</div>
										<div className="container">
										<div className="row justify-content-center">
										<div className="col-6">
											<Card>
												<QRCode bgColor="#FFFFFF" fgColor="#000000" level="Q" style={{width: 256}} value={this.state.uuid} />
												<CardBody>
													<CardTitle>Scan the QR code above</CardTitle>
													<CardSubtitle>To log in</CardSubtitle>
													<CardText>Use the <a href="https://play.google.com/store/apps/details?id=com.brentsys.systemid.idverifier">SystemID App</a></CardText>
												</CardBody>
											</Card>
										</div>
										</div>
										</div>
										<p className="text-muted">By signing up you agree to {AppConfig.brandName}</p>
										<p className="mb-0"><a target="_blank" href="/terms-condition" className="text-muted">Terms of Service</a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</QueueAnim>
		);
	}
}
// map state to props
const mapStateToProps = ({ fcm, authUser }) => {
	return { fcm, authUser }
}

export default connect(mapStateToProps, { generateLoginToken, signinUserInSystemId })(Signin);
