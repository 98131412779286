import Address from "./Address";
import { BaseRecord } from "./base";
import Table from './table';
import { User } from "./user";
import { Timestamp } from "@google-cloud/firestore";
import { getTheDate } from "Helpers/helpers";
import moment from 'moment';
import { Approval } from "./approval";
import firebase from 'firebase'
import { Verification } from "./verification";
import { ActivationStatus } from "./activable";
import { MobileCurrency } from "./mobile_currency";

export enum OrganizationType {
    Abriva = 'ABV',
    IssuingBank = 'IB',
    ContainmentBank = 'CTNB',
    Partner = 'PRT'
}

export class Organization extends BaseRecord {
    coll_ref: string = Table.Partner
    name: string
    shortName?: string
    type: OrganizationType
    adresses: Address[]
    defaultAddressId: string
    logo?: string
    bosses?: User[]
    documents?: firebase.storage.Reference[]
    approval?: Approval
    verification?: Verification
    test: boolean = false

    dateCreated(): string {
        if (this.createdAt == undefined) return "No date ..."
        return moment(this.createdAt.toDate().getTime()).format('LL')
    }

    isVerified(): boolean {
        if (this.test) return !!this.approval
        if (!this.verification) return false
        return this.verification.status == ActivationStatus.activated
    }

    initials(): string {
        return this.name.split(" ").slice(0, 2)
            .map(X => X.charAt(0)).join("").toUpperCase()
    }
    static TEST_SHORT_NAME = "acme"

    isContainmentBank(): Boolean {
        return this.type == OrganizationType.ContainmentBank
    }

    isIssuingBank(): boolean {
        return this.type == OrganizationType.IssuingBank
    }

}

