import {
    createStore,
    applyMiddleware,
    compose
} from 'redux';
import Thunk from 'redux-thunk';
import reducers from '../reducers';
import * as actionCreators from '../actions';

export function configureStore(initialState) {

    var store;

    if (process.env.NODE_ENV === 'development') {
        const enhancer = window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__({
                actionCreators,
                serialize: true,
                trace: true
            });
        if (!enhancer) {
            console.warn('Install Redux DevTools Extension to inspect the app state: ' +
                'https://github.com/zalmoxisus/redux-devtools-extension#installation')
        }

        store = createStore(
            reducers,
            initialState,
            compose(applyMiddleware(Thunk), enhancer)
        );

    } else {
        store = createStore(
            reducers,
            initialState,
            compose(applyMiddleware(Thunk))
        );
    }


    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}