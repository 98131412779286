import { Activable } from "./activable";
import Table from './table';
import { BaseRecord, ParentCreator } from "./base";
import { DocumentData } from "@google-cloud/firestore";

export class LicenseSpecs extends Activable {

    coll_ref = Table.LicenseSpecs

    code: string
    cashOutMax: number
    cashOutMaxDays: number
    ceiling: number
    creditMax: number
    creditMaxDays: number
    singleOpMax: number

    readOnlySet(): string[] {
        return super.readOnlySet().concat(["code"])
    }
}

export class LicenseSpecsPlus extends LicenseSpecs {
    actionContractId: string
    mcId: string

    constructor(parent?: BaseRecord) {
        super(parent)
        if (parent) {
            this.mcId = parent.id
        } else {
            this.parent = new ParentCreator(this.mcId, Table.MobileCurrency)
        }
    }

    assign(data: DocumentData) {
        super.assign(data)
        this.code = this.id
    }
}