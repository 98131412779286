// routes
import Widgets from 'Routes/widgets';
import Pages from 'Routes/pages';
import AdvanceUIComponents from 'Routes/advance-ui-components';
import CalendarComponents from 'Routes/calendar';
import ChartsComponents from 'Routes/charts';
import FormElements from 'Routes/forms';
import Users from 'Routes/users';
import Validation from 'Routes/validation';
import Operation from 'Routes/operation';
import Payees from 'Routes/payees';
import ExtPayees from 'Routes/payees/ext';
import Components from 'Routes/components';
import Tables from 'Routes/tables';
import Icons from 'Routes/icons';
import Maps from 'Routes/maps';
import DragAndDrop from 'Routes/drag-drop';
import Editor from 'Routes/editor';
import Ecommerce from 'Routes/ecommerce';
import Dashboard from 'Routes/dashboard';
import ImageCropper from 'Routes/image-cropper';
import VideoPlayer from 'Routes/video-player';
import Dropzone from 'Routes/dropzone';
import Currencies from 'Routes/currencies';
import Stores from 'Routes/stores';
import Contracts from 'Routes/contracts';
import Roles from 'Routes/roles';
import Signatories from 'Routes/signatories';
import Authorizations from 'Routes/authorizations';
import MarketServices from 'Routes/market-services';
import Translations from 'Routes/market-services/translation';
import Servers from 'Routes/servers';
import LicenseSpecs from 'Routes/licenses/specs';
import OrderedCards from 'Routes/prepaid-cards';
import GroupNotifications from 'Routes/group-notifications';

// async component
import {
	AsyncAboutUsComponent,
	AsyncChatComponent,
	AsyncMailComponent,
	AsyncTodoComponent,
} from 'Components/AsyncComponent/AsyncComponent';

export default [
	{
		path: 'dashboard',
		component: Dashboard
	},
	{
		path: 'widgets',
		component: Widgets
	},
	{
		path: 'ecommerce',
		component: Ecommerce
	},
	{
		path: 'icons',
		component: Icons
	},
	{
		path: 'about-us',
		component: AsyncAboutUsComponent
	},
	{
		path: 'pages',
		component: Pages
	},
	{
		path: 'chat',
		component: AsyncChatComponent
	},
	{
		path: 'mail',
		component: AsyncMailComponent
	},
	{
		path: 'todos',
		component: AsyncTodoComponent
	},
	{
		path: 'charts',
		component: ChartsComponents
	},
	{
		path: 'tables',
		component: Tables
	},
	{
		path: 'maps',
		component: Maps
	},
	{
		path: 'users',
		component: Users
	},
	{
		path: 'validation',
		component: Validation
	},
	{
		path: 'operations',
		component: Operation
	},
	{
		path: 'payees',
		component: Payees
	},
	{
		path: 'ext-payees',
		component: ExtPayees
	},
	{
		path: 'stores',
		component: Stores
	},
	{
		path: 'ui-components',
		component: Components
	},
	{
		path: 'advanced-component',
		component: AdvanceUIComponents
	},
	{
		path: 'drag-andDrop',
		component: DragAndDrop
	},
	{
		path: 'forms',
		component: FormElements
	},
	{
		path: 'editor',
		component: Editor
	},
	{
		path: 'calendar',
		component: CalendarComponents
	},
	{
		path: 'image-cropper',
		component: ImageCropper
	},
	{
		path: 'video-player',
		component: VideoPlayer
	},
	{
		path: 'dropzone',
		component: Dropzone
	},
	{
		path: 'currencies',
		component: Currencies
	},
	{
		path: 'contracts',
		component: Contracts
	},
	{
		path: 'roles',
		component: Roles
	},
	{
		path: 'account',
		component: Signatories
	},
	{
		path: 'authorizations',
		component: Authorizations
	},
	{
		path: 'market-services',
		component: MarketServices
	},
	{
		path: 'translations',
		component: Translations
	},
	{
		path: 'servers',
		component: Servers
	},
	{
		path: 'licenses',
		component: LicenseSpecs
	},
	{
		path: 'ordered-cards',
		component: OrderedCards
	},
	{
		path: 'group-notifications',
		component: GroupNotifications
	}

]