module.exports = {
	"sidebar.app": "アプリ",
	"sidebar.roles": "役割",
	"sidebar.horizontal": "水平",
	"sidebar.horizontalMenu": "水平メニュー",
	"sidebar.general": "一般",
	"sidebar.component": "成分",
	"sidebar.features": "特徴",
	"sidebar.applications": "アプリケーション",
	"sidebar.dashboard": "ダッシュボード",
	"sidebar.dashboard1": "ダッシュボード1",
	"sidebar.dashboard2": "ダッシュボード2",
	"sidebar.dashboard3": "ダッシュボード3",
	"sidebar.modules": "モジュール",
	"sidebar.agency": "代理店",
	"sidebar.pages": "ページ",
	"sidebar.gallery": "ギャラリー",
	"sidebar.pricing": "価格設定",
	"sidebar.terms&Conditions": "利用規約",
	"sidebar.feedback": "フィードバック",
	"sidebar.report": "報告する",
	"sidebar.faq(s)": "Faq（s）",
	"sidebar.advancedComponent": "アドバンスコンポーネント",
	"sidebar.blank": "ブランク",
	"sidebar.session": "セッション",
	"sidebar.login": "ログイン",
	"sidebar.register": "登録",
	"sidebar.lockScreen": "ロック画面",
	"sidebar.forgotPassword": "パスワードをお忘れですか",
	"sidebar.404": "404",
	"sidebar.500": "500",
	"sidebar.uiComponents": "UIコンポーネント",
	"sidebar.alerts": "アラート",
	"sidebar.appBar": "アプリバー",
	"sidebar.avatars": "アバター",
	"sidebar.buttons": "ボタン",
	"sidebar.bottomNavigations": "ボトムナビゲーション",
	"sidebar.badges": "バッジ",
	"sidebar.cards": "カード",
	"sidebar.cardsMasonry": "カードマセナリー",
	"sidebar.chip": "チップ",
	"sidebar.dialog": "ダイアログ",
	"sidebar.dividers": "仕切り",
	"sidebar.drawers": "引き出し",
	"sidebar.popover": "ポップオーバー",
	"sidebar.expansionPanel": "拡張パネル",
	"sidebar.gridList": "グリッドリスト",
	"sidebar.list": "リスト",
	"sidebar.menu": "メニュー",
	"sidebar.popoverAndToolTip": "ポップ＆ツールヒント",
	"sidebar.progress": "進捗",
	"sidebar.snackbar": "スナックバー",
	"sidebar.selectionControls": "選択コントロール",
	"sidebar.advanceUiComponents": "アドバンスUIコンポーネント",
	"sidebar.dateAndTimePicker": "日付と時刻のピッカー",
	"sidebar.tabs": "タブ",
	"sidebar.stepper": "ステッパー",
	"sidebar.notification": "お知らせ",
	"sidebar.sweetAlert": "スウィートアラート",
	"sidebar.autoComplete": "オートコンプリート",
	"sidebar.aboutUs": "私たちに関しては",
	"sidebar.widgets": "ウィジェット",
	"sidebar.forms": "フォーム",
	"sidebar.formElements": "フォーム要素",
	"sidebar.textField": "テキストフィールド",
	"sidebar.selectList": "リストを選択",
	"sidebar.charts": "チャート",
	"sidebar.reCharts": "リチャート",
	"sidebar.reactChartjs2": "反応チャート2",
	"sidebar.icons": "アイコン",
	"sidebar.themifyIcons": "テーマアイコン",
	"sidebar.simpleLineIcons": "シンプルラインアイコン",
	"sidebar.materialIcons": "マテリアルアイコン",
	"sidebar.fontAwesome": "フォント驚くばかり",
	"sidebar.tables": "テーブル",
	"sidebar.basic": "ベーシック",
	"sidebar.dataTable": "データ表",
	"sidebar.responsive": "応答性のある",
	"sidebar.reactTable": "反応表",
	"sidebar.maps": "地図",
	"sidebar.googleMaps": "グーグルマップ",
	"sidebar.leafletMaps": "リーフレットマップ",
	"sidebar.inbox": "受信トレイ",
	"sidebar.users": "ユーザー",
	"sidebar.userProfile1": "ユーザープロファイル1",
	"sidebar.userProfile2": "ユーザープロファイル2",
	"sidebar.userManagement": "ユーザー管理",
	"sidebar.userProfile": "ユーザープロファイル",
	"sidebar.userList": "ユーザーリスト",
	"sidebar.calendar": "カレンダー",
	"sidebar.cultures": "文化",
	"sidebar.dnd": "Dnd",
	"sidebar.selectable": "選択可能",
	"sidebar.customRendering": "カスタムレンダリング",
	"sidebar.chat": "チャット",
	"sidebar.toDo": "ToDo",
	"sidebar.editor": "編集者",
	"sidebar.wysiwygEditor": "WYSIWYGエディタ",
	"sidebar.quillEditor": "クイルエディタ",
	"sidebar.reactAce": "リアクションエース",
	"sidebar.dragAndDrop": "ドラッグアンドドロップ",
	"sidebar.reactDragula": "反撃ドラゴン",
	"sidebar.reactDnd": "反応Dnd",
	"sidebar.blogManagement": "ブログ管理",
	"sidebar.ecommerce": "eコマース",
	"sidebar.shopList": "ショップリスト",
	"sidebar.shopGrid": "ショップグリッド",
	"sidebar.invoice": "請求書",
	"sidebar.multilevel": "マルチレベル",
	"sidebar.sublevel": "サブレベル",
	"widgets.totalEarns": "総収入",
	"widgets.emailsStatistics": "メール統計",
	"widgets.totalRevenue": "総収入",
	"widgets.onlineVistors": "オンラインバリスタ",
	"widgets.trafficSources": "トラフィックソース",
	"widgets.RecentOrders": "最近の注文",
	"widgets.topSellings": "トップセリング",
	"widgets.productReports": "製品レポート",
	"widgets.productStats": "製品の統計",
	"widgets.ratings": "評価",
	"widgets.ComposeEmail": "Eメールの作成",
	"widgets.employeePayroll": "従業員の給与計算",
	"widgets.visitors": "訪問者",
	"widgets.orders": "注文",
	"widgets.orderStatus": "注文の状況",
	"widgets.totalSales": "トータルセールス",
	"widgets.netProfit": "純利益",
	"widgets.overallTrafficStatus": "全体のトラフィック状況",
	"widgets.tax": "税金",
	"widgets.expenses": "経費",
	"widgets.currentTime": "現在の時刻",
	"widgets.currentDate": "現在の日付",
	"widgets.todayOrders": "今日の注文",
	"widgets.toDoList": "To Doリスト",
	"widgets.discoverPeople": "人々を発見する",
	"widgets.commments": "コメント",
	"widgets.newCustomers": "新しいお客様",
	"widgets.recentNotifications": "最近のお知らせ",
	"widgets.appNotifications": "アプリの通知",
	"widgets.newEmails": "新しいメール",
	"widgets.siteVisitors": "サイト訪問者",
	"widgets.socialCompanines": "ソーシャルコンパニオン",
	"widgets.recentActivities": "最近の活動",
	"widgets.recentOrders": "最近の注文",
	"widgets.gallery": "ギャラリー",
	"widgets.pricing": "価格設定",
	"widgets.enterpriseEdition": "エンタープライズ版",
	"widgets.personalEdition": "パーソナルエディション",
	"widgets.teamEdition": "チーム版",
	"widgets.standard": "標準",
	"widgets.advanced": "上級",
	"widgets.master": "マスター",
	"widgets.Mega": "メガ",
	"widgets.logIn": "ログイン",
	"widgets.signUp": "サインアップ",
	"widgets.lockScreen": "ロック画面",
	"widgets.alertsWithLink": "リンク付きアラート",
	"widgets.additionalContent": "追加コンテンツ",
	"widgets.alertDismiss": "アラート終了",
	"widgets.uncontrolledDisableAlerts": "制御されていないディセーブルアラート",
	"widgets.contexualAlerts": "コンテンツアラート",
	"widgets.alertsWithIcons": "アイコン付きアラート",
	"widgets.Simple App Bars": "シンプルなアプリケーションバー",
	"widgets.appBarsWithButtons": "ボタン付きのアプリケーションバー",
	"widgets.imageAvatars": "イメージアバター",
	"widgets.lettersAvatars": "手紙アバター",
	"widgets.iconsAvatars": "アイコンアバター",
	"widgets.flatButtons": "フラットボタン",
	"widgets.raisedButton": "上げたボタン",
	"widgets.buttonWithIconAndLabel": "アイコンとラベルのボタン",
	"widgets.floatingActionButtons": "フローティングアクションボタン",
	"widgets.iconButton": "アイコンボタン",
	"widgets.socialMediaButton": "ソーシャルメディアボタン",
	"widgets.reactButton": "リアクションボタン",
	"widgets.buttonOutline": "ボタンの概要",
	"widgets.buttonSize": "ボタンのサイズ",
	"widgets.buttonState": "ボタンの状態",
	"widgets.buttonNavigationWithNoLabel": "ラベルなしのボタンナビゲーション",
	"widgets.buttonNavigation": "ボタンナビゲーション",
	"widgets.iconNavigation": "アイコンナビゲーション",
	"widgets.badgeWithHeadings": "見出しのバッジ",
	"widgets.contexualVariations": "文脈のバリエーション",
	"widgets.badgeLinks": "バッジリンク",
	"widgets.materialBadge": "マテリアルバッジ",
	"widgets.simpleCards": "シンプルカード",
	"widgets.backgroundVarient": "背景バリエント",
	"widgets.cardOutline": "カード概要",
	"widgets.overlayCard": "オーバーレイカード",
	"widgets.cardGroup": "カードグループ",
	"widgets.cardTitle": "カードタイトル",
	"widgets.speacialTitleTreatment": "広場タイトル治療",
	"widgets.chipWithClickEvent": "クリックイベントのチップ",
	"widgets.chipArray": "チップアレイ",
	"widgets.dialogs": "ダイアログ",
	"widgets.listDividers": "リストディバイダ",
	"widgets.insetDividers": "インセットディバイダー",
	"widgets.temporaryDrawers": "一時的な引き出し",
	"widgets.permanentDrawers": "常設引き出し",
	"widgets.simpleExpansionPanel": "シンプルな拡張パネル",
	"widgets.controlledAccordion": "制御アコーデオン",
	"widgets.secondaryHeadingAndColumns": "二次見出しと柱",
	"widgets.imageOnlyGridLists": "画像のみのグリッドリスト",
	"widgets.advancedGridLists": "高度なグリッドリスト",
	"widgets.singleLineGridLists": "単線グリッドリスト",
	"widgets.simpleLists": "シンプルリスト",
	"widgets.folderLists": "フォルダ一覧",
	"widgets.listItemWithImage": "画像付きアイテムの一覧表示",
	"widgets.switchLists": "スイッチリスト",
	"widgets.insetLists": "インセットリスト",
	"widgets.nestedLists": "ネストされたリスト",
	"widgets.checkboxListControl": "チェックボックスリストコントロール",
	"widgets.pinedSubHeader": "Pinedサブヘッダー",
	"widgets.InteractiveLists": "インタラクティブリスト",
	"widgets.simpleMenus": "シンプルメニュー",
	"widgets.selectedMenu": "選択されたメニュー",
	"widgets.maxHeightMenu": "最大高さメニュー",
	"widgets.changeTransition": "移行を変更する",
	"widgets.paper": "紙",
	"widgets.anchorPlayGround": "アンカープレーグラウンド",
	"widgets.tooltip": "ツールヒント",
	"widgets.positionedToolTips": "位置付けられたスナックバー",
	"widgets.circularProgressBottomStart": "円形進捗ボトムスタート",
	"widgets.interactiveIntegration": "インタラクティブな統合",
	"widgets.determinate": "決定する",
	"widgets.linearProgressLineBar": "線形進行線バー",
	"widgets.indeterminate": "不確定",
	"widgets.buffer": "バッファ",
	"widgets.query": "クエリ",
	"widgets.transitionControlDirection": "移行制御方向",
	"widgets.simpleSnackbar": "シンプルなスナックバー",
	"widgets.positionedSnackbar": "位置付けられたスナックバー",
	"widgets.contexualColoredSnackbars": "コンテクストカラーのスナックバー",
	"widgets.simpleCheckbox": "簡易チェックボックス",
	"widgets.interminateSelection": "間欠選択",
	"widgets.disabledCheckbox": "無効なチェックボックス",
	"widgets.customColorCheckbox": "カスタムカラーチェックボックス",
	"widgets.VerticalStyleCheckbox": "垂直スタイルのチェックボックス",
	"widgets.horizontalStyleCheckbox": "水平スタイルチェックボックス",
	"widgets.radioButtons": "ラジオボタン",
	"widgets.disabledRadio": "障害者用ラジオ",
	"widgets.withError": "エラーあり",
	"widgets.switches": "スウィッチ",
	"widgets.dateAndTimePicker": "日付と時刻のピッカー",
	"widgets.defaultPicker": "デフォルトのピッカー",
	"widgets.timePicker": "時間ピッカー",
	"widgets.weekPicker": "週ピッカー",
	"widgets.defaultDatePicker": "デフォルトの日付ピッカー",
	"widgets.customPicker": "カスタムピッカー",
	"widgets.tabs": "タブ",
	"widgets.fixedTabs": "固定タブ",
	"widgets.basicTab": "基本タブ",
	"widgets.wrappedLabels": "ラップラベル",
	"widgets.centeredLabels": "中央のラベル",
	"widgets.forcedScrolledButtons": "強制スクロールボタン",
	"widgets.iconsTabs": "アイコンタブ",
	"widgets.withDisableTabs": "タブを無効にする",
	"widgets.iconWithLabel": "ラベル付きアイコン",
	"widgets.stepper": "ステッパー",
	"widgets.horizontalLinear": "水平リニア",
	"widgets.horizontalNonLinear": "水平非線形",
	"widgets.horizontalLinerAlternativeLabel": "水平ライナー代替ラベル",
	"widgets.horizontalNonLinerAlternativeLabel": "水平ノンライナー代替ラベル",
	"widgets.verticalStepper": "垂直ステッパー",
	"widgets.descriptionAlert": "説明アラート",
	"widgets.customIconAlert": "カスタムアイコンアラート",
	"widgets.withHtmlAlert": "HTMLアラートで",
	"widgets.promptAlert": "迅速な警告",
	"widgets.passwordPromptAlert": "パスワードプロンプトアラート",
	"widgets.customStyleAlert": "カスタムスタイルアラート",
	"widgets.autoComplete": "オートコンプリート",
	"widgets.reactSelect": "反応選択",
	"widgets.downshiftAutoComplete": "ダウンシフトオートコンプリート",
	"widgets.reactAutoSuggests": "オート提案の提案",
	"widgets.aboutUs": "私たちに関しては",
	"widgets.ourVission": "私たちの使命",
	"widgets.ourMissions": "私たちの使命",
	"widgets.ourMotivation": "私たちのモチベーション",
	"widgets.defualtReactForm": "不確定な反応形式",
	"widgets.url": "Url",
	"widgets.textArea": "テキスト領域",
	"widgets.file": "ファイル",
	"widgets.formGrid": "フォームグリッド",
	"widgets.inlineForm": "インラインフォーム",
	"widgets.inputSizing": "入力サイジング",
	"widgets.inputGridSizing": "入力グリッドサイジング",
	"widgets.hiddenLabels": "隠しラベル",
	"widgets.formValidation": "フォーム検証",
	"widgets.number": "数",
	"widgets.date": "日付",
	"widgets.time": "時間",
	"widgets.color": "色",
	"widgets.search": "サーチ",
	"widgets.selectMultiple": "複数を選択",
	"widgets.inputWithSuccess": "成功の入力",
	"widgets.inputWithDanger": "危険を伴う入力",
	"widgets.simpleTextField": "シンプルテキストフィールド",
	"widgets.componet": "コンポーネント",
	"widgets.layouts": "レイアウト",
	"widgets.inputAdorements": "入力アドライト",
	"widgets.formattedInputs": "書式付き入力",
	"widgets.simpleSelect": "シンプルセレクト",
	"widgets.nativeSelect": "ネイティブセレクト",
	"widgets.MutltiSelectList": "複数選択 リスト",
	"widgets.lineChart": "折れ線グラフ",
	"widgets.barChart": "棒グラフ",
	"widgets.stackedBarChart": "積み上げ棒グラフ",
	"widgets.lineBarAreaChart": "ラインバーエリアチャート",
	"widgets.areaChart": "エリアチャート",
	"widgets.stackedAreaChart": "積み重ねられたエリアチャート",
	"widgets.verticalChart": "垂直チャート",
	"widgets.radarChart": "レーダーチャート",
	"widgets.doughnut": "ドーナツ",
	"widgets.polarChart": "極座標",
	"widgets.pieChart": "円グラフ",
	"widgets.bubbleChart": "バブルチャート",
	"widgets.horizontalBar": "ホライズンナショナル バー",
	"widgets.basicTable": "基本テーブル",
	"widgets.contexualColoredTable": "コンテクスチャカラーテーブル",
	"widgets.dataTable": "データ表",
	"widgets.employeeList": "従業員リスト",
	"widgets.responsiveTable": "レスポンシブテーブル",
	"widgets.responsiveFlipTable": "応答性フリップテーブル",
	"widgets.reactGridControlledStateMode": "グリッド制御状態モードに反応する",
	"widgets.googleMaps": "グーグルマップ",
	"widgets.productsReports": "製品レポート",
	"widgets.taskList": "タスクリスト",
	"widgets.basicCalender": "基本的なカレンダー",
	"widgets.culturesCalender": "カルチャーカレンダー",
	"widgets.dragAndDropCalender": "ドラッグアンドドロップカレンダー",
	"widgets.selectableCalender": "選択可能なカレンダー",
	"widgets.customRendering": "カスタムレンダリング",
	"widgets.customCalender": "カスタムカレンダー",
	"widgets.searchMailList": "メールリストを検索",
	"components.buyNow": "今買う",
	"compenets.choose": "選択する",
	"compenets.username": "ユーザー名",
	"compenets.passwords": "パスワード",
	"widgets.forgetPassword": "パスワードを忘れる",
	"compenets.signIn": "サインイン",
	"compenets.dontHaveAccountSignUp": "アカウントのサインアップはありません",
	"compenets.enterUserName": "ユーザーネームを入力してください",
	"compenets.enterEmailAddress": "メールアドレスを入力してください",
	"compenets.confirmPasswords": "パスワードの確認",
	"components.alreadyHavingAccountSignIn": "すでにアカウントにサインインしている",
	"components.enterYourPassword": "パスワードを入力してください",
	"components.unlock": "ロックを解除する",
	"components.enterPasswords": "パスワードを入力",
	"components.resetPassword": "パスワードの再入力",
	"components.pageNotfound": "ページが見つかりません",
	"components.goToHomePage": "ホームページへ",
	"components.sorryServerGoesWrong": "申し訳ありませんが、サーバーが間違っています",
	"components.persistentDrawer": "永続的引き出し",
	"components.back": "バック",
	"components.next": "次",
	"components.completeStep": "完全なステップ",
	"components.withHtml": "HTMLで",
	"components.prompt": "プロンプト",
	"components.withDescription": "説明付き",
	"components.success": "成功",
	"components.passwordPrompt": "パスワードプロンプト",
	"components.warning": "警告",
	"components.customIcon": "カスタムアイコン",
	"components.customStyle": "カスタムスタイル",
	"components.basic": "ベーシック",
	"components.submit": "提出する",
	"components.compose": "作成する",
	"components.sendMessage": "メッセージを送る",
	"components.addNewTasks": "新しいタスクを追加する",
	"components.addToCart": "カートに追加",
	"components.payNow": "今払う",
	"components.print": "印刷",
	"components.cart": "カート",
	"components.viewCart": "かごの中身を見る",
	"components.checkout": "チェックアウト",
	"widgets.QuickLinks": "クイックリンク",
	"widgets.upgrade": "アップグレード",
	"widgets.app": "アプリ",
	"widgets.addNew": "新しく追加する",
	"widgets.orderDate": "注文日",
	"widgets.status": "状態",
	"widgets.trackingNumber": "追跡番号",
	"widgets.action": "アクション",
	"widgets.designation": "指定",
	"widgets.subject": "主題",
	"widgets.send": "送信",
	"widgets.saveAsDrafts": "下書きとして保存",
	"widgets.onlineSources": "オンラインソース",
	"widgets.lastMonth": "先月",
	"widgets.widgets": "ウィジェット",
	"widgets.listing": "リスト",
	"widgets.paid": "有料",
	"widgets.refunded": "払い戻された",
	"widgets.done": "完了",
	"widgets.pending": "保留中",
	"widgets.cancelled": "キャンセル",
	"widgets.approve": "承認する",
	"widgets.following": "以下",
	"widgets.follow": "フォローする",
	"widgets.graphs&Charts": "グラフ＆グラフ",
	"widgets.open": "開いた",
	"widgets.bounced": "バウンス",
	"widgets.spam": "スパム",
	"widgets.unset": "設定を解除する",
	"widgets.bandwidthUse": "帯域幅の使用",
	"widgets.dataUse": "データ使用",
	"widgets.unsubscribe": "退会する",
	"widgets.profile": "プロフィール",
	"widgets.messages": "メッセージ",
	"widgets.support": "サポート",
	"widgets.faq(s)": "Faq（s）",
	"widgets.upgradePlains": "アップグレードプレーンズ",
	"widgets.logOut": "ログアウト",
	"widgets.mail": "郵便物",
	"widgets.adminTheme": "管理テーマ",
	"widgets.wordpressTheme": "Wordpressのテーマ",
	"widgets.addToCart": "カートに追加",
	"widgets.plan": "計画",
	"widgets.basic": "ベーシック",
	"widgets.pro": "プロ",
	"widgets.startToBasic": "ベーシックスタート",
	"widgets.upgradeToPro": "プロ仕様にアップグレードする",
	"widgets.upgradeToAdvance": "アドバンスにアップグレードする",
	"widgets.comparePlans": "計画比較",
	"widgets.free": "無料",
	"widgets.frequentlyAskedQuestions": "よくある質問",
	"widgets.searchIdeas": "検索のアイデア",
	"widgets.startDate": "開始日",
	"widgets.endDate": "終了日",
	"widgets.category": "カテゴリー",
	"widgets.apply": "適用",
	"widgets.downloadPdfReport": "Pdfレポートをダウンロード",
	"widgets.yesterday": "昨日",
	"widgets.totalOrders": "合計注文",
	"widgets.totalVisitors": "総訪問者",
	"widgets.typeYourQuestions": "あなたの質問を入力してください",
	"widgets.username": "ユーザー名",
	"widgets.password": "パスワード",
	"widgets.signIn": "サインイン",
	"widgets.enterYourPassword": "パスワードを入力してください",
	"widgets.alreadyHavingAccountLogin": "すでにアカウントにログインしている",
	"widgets.composeMail": "メールを作成する",
	"widgets.issue": "問題",
	"widgets.recentChat": "最近のチャット",
	"widgets.previousChat": "前のチャット",
	"widgets.all": "すべて",
	"widgets.filters": "フィルタ",
	"widgets.deleted": "削除済み",
	"widgets.starred": "スター付き",
	"widgets.frontend": "フロントエンド",
	"widgets.backend": "バックエンド",
	"widgets.api": "Api",
	"widgets.simpleAppBar": "シンプルなアプリケーションバー",
	"widgets.recents": "過去",
	"widgets.cardLink": "カードリンク",
	"widgets.anotherLink": "別のリンク",
	"widgets.cardSubtitle": "カード字幕",
	"widgets.confirmationDialogs": "確認ダイアログ",
	"widgets.deletableChip": "削除可能なチップ",
	"widgets.customDeleteIconChip": "カスタム削除アイコンチップ",
	"widgets.openAlertDialog": "警告ダイアログを開く",
	"widgets.openResponsiveDialog": "応答ダイアログを開く",
	"widgets.openSimpleDialog": "シンプルダイアログを開く",
	"widgets.openFormDialog": "フォームダイアログを開く",
	"widgets.follower": "フォロワー",
	"widgets.important": "重要",
	"widgets.private": "プライベート",
	"widgets.openLeft": "左開き",
	"widgets.openRight": "右に開く",
	"widgets.openTop": "オープントップ",
	"widgets.openBottom": "オープンボトム",
	"widgets.selectTripDestination": "旅行先を選択",
	"widgets.pinnedSubheaderList": "固定されたサブヘッダリスト",
	"widgets.singleLineItem": "単品商品",
	"widgets.acceptTerms": "受諾条件",
	"widgets.optionA": "オプションA",
	"widgets.optionB": "オプションB",
	"widgets.optionC": "オプションC",
	"widgets.optionM": "オプションM",
	"widgets.optionN": "オプションN",
	"widgets.optionO": "オプションO",
	"widgets.customColor": "カスタムカラー",
	"widgets.centeredTabs": "中央のタブ",
	"widgets.multipleTabs": "複数のタブ",
	"widgets.preventScrolledButtons": "スクロールボタンの防止",
	"widgets.browse": "ブラウズ",
	"widgets.formValidate": "フォームの検証",
	"widgets.code": "コード",
	"widgets.company": "会社",
	"widgets.price": "価格",
	"widgets.change": "変化する",
	"widgets.high": "高い",
	"widgets.low": "低い",
	"widgets.volume": "ボリューム",
	"widgets.personalDetails": "個人情報",
	"widgets.occupation": "職業",
	"widgets.companyName": "会社名",
	"widgets.phoneNo": "電話番号",
	"widgets.city": "シティ",
	"widgets.zipCode": "郵便番号",
	"widgets.updateProfile": "プロフィールを更新",
	"widgets.reject": "拒否",
	"widgets.exportToExcel": "優れたにエクスポート",
	"widgets.addNewUser": "新しいユーザーを追加",
	"widgets.workWeek": "ワークウィーク",
	"widgets.agenda": "議題",
	"widgets.conference": "会議",
	"widgets.multilevel": "マルチレベル",
	"widgets.dailySales": "毎日の販売",
	"widgets.today": "今日",
	"widgets.campaignPerformance": "キャンペーンの掲載結果",
	"widgets.supportRequest": "サポートリクエスト",
	"widgets.usersList": "ユーザーリスト",
	"widgets.lastWeek": "先週",
	"themeOptions.sidebarOverlay": "サイドバーオーバーレイ",
	"themeOptions.sidebarBackgroundImages": "サイドバーの背景イメージ",
	"themeOptions.appSettings": "アプリの設定",
	"themeOptions.sidebarImage": "サイドバー画像",
	"themeOptions.miniSidebar": "ミニサイドバー",
	"themeOptions.boxLayout": "ボックスレイアウト",
	"themeOptions.rtlLayout": "RTLレイアウト",
	"themeOptions.darkMode": "ダークモード",
	"themeOptions.sidebarLight": "光",
	"themeOptions.sidebarDark": "ダーク",
	"button.cancel": "キャンセル",
	"button.add": "追加",
	"button.update": "更新",
	"button.reply": "応答",
	"button.delete": "削除",
	"button.yes": "はい",
	"button.viewAll": "すべて見る",
	"button.like": "好き",
	"button.assignNow": "今すぐ割り当て",
	"button.seeInsights": "インサイトを見る",
	"sidebar.dateTimePicker": "日付と時刻のピッカー",
	"components.summary": "概要",
	"hint.whatAreYouLookingFor": "何を探していますか",
	"components.yesterday": "昨日",
	"components.last7Days": "過去7日間",
	"components.last1Month": "過去1ヶ月",
	"components.last6Month": "過去6ヶ月",
	"components.spaceUsed": "使用されたスペース",
	"components.followers": "フォロワー",
	"components.trending": "トレンド",
	"components.paid": "有料",
	"components.refunded": "払い戻された",
	"components.done": "完了",
	"components.pending": "保留中",
	"components.cancelled": "キャンセル",
	"components.approve": "承認する",
	"components.week": "週間",
	"components.month": "月",
	"components.year": "年",
	"components.today": "今日",
	"components.popularity": "人気",
	"components.email": "Eメール",
	"components.drafts": "下書き",
	"components.sent": "送信済み",
	"components.trash": "ごみ",
	"components.all": "すべて",
	"components.do": "行う",
	"components.title": "タイトル",
	"components.projectName": "プロジェクト名",
	"components.companyName": "会社名",
	"components.openAlert": "オープンアラート",
	"components.slideInAlertDialog": "警告ダイアログでスライド",
	"components.openFullScreenDialog": "フルスクリーンダイアログを開く",
	"components.basicChip": "基本チップ",
	"components.clickableChip": "クリック可能なチップ",
	"components.left": "左",
	"components.right": "右",
	"components.expansionPanel1": "拡張パネル1",
	"components.expansionPanel2": "拡張パネル2",
	"components.generalSetting": "一般的な設定",
	"components.advancedSettings": "高度な設定",
	"components.firstName": "ファーストネーム",
	"components.lastName": "苗字",
	"components.occupation": "職業",
	"components.phoneNo": "電話番号",
	"components.address": "住所",
	"components.city": "シティ",
	"components.state": "状態",
	"components.zipCode": "郵便番号",
	"components.social Connection": "ソーシャルコネクション",
	"widgets.buyMore": "もっと買う",
	"widgets.trafficChannel": "トラフィックチャネル",
	"widgets.stockExchange": "証券取引所",
	"widgets.tweets": "ツイート",
	"widgets.ourLocations": "私たちの場所",
	"widgets.sales": "販売",
	"widgets.to": "に",
	"widgets.shipTo": "送り先",
	"widgets.description": "説明",
	"widgets.unitPrice": "単価",
	"widgets.total": "合計",
	"widgets.note": "注意",
	"widgets.chipWithAvatar": "アバターのチップ",
	"widgets.chipWithTextAvatar": "テキストアバターチップ",
	"widgets.chipWithIconAvatar": "アイコンアバターのチップ",
	"widgets.customClickableChip": "カスタムクリック可能チップ",
	"widgets.outlineChip": "アウトラインチップ",
	"widgets.disableChip": "チップを無効にする",
	"widgets.alertDialog": "警告ダイアログ",
	"widgets.animatedSlideDialogs": "アニメーションスライドダイアログ",
	"widgets.fullScreenDialogs": "全画面ダイアログ",
	"widgets.formDialogs": "フォームダイアログ",
	"widgets.simpleDialogs": "シンプルなダイアログ",
	"widgets.responsiveFullScreen": "レスポンシブフルスクリーン",
	"widgets.primary": "一次",
	"widgets.social": "ソーシャル",
	"widgets.user": "ユーザー",
	"widgets.admin": "管理者",
	"widgets.permanentdrawer": "常設引き出し",
	"widgets.persistentdrawer": "永続的引き出し",
	"widgets.swiches": "スウィッチ",
	"widgets.horizontalLinearAlternativeLabel": "水平線形代替ラベル",
	"widgets.horizontalNonLinearAlternativeLabel": "水平非線形代替ラベル",
	"widgets.notifications": "通知",
	"widgets.basicAlert": "基本的な警告",
	"widgets.successAlert": "成功の警告",
	"widgets.warningAlert": "警告アラート",
	"widgets.reactAutoSuggest": "自動提案に反応する",
	"widgets.components": "コンポーネント",
	"widgets.inputAdornments": "入力アドライト",
	"widgets.multiSelectList": "マルチセレクトリスト",
	"widgets.contextualColoredTable": "コンテクスチャカラーテーブル",
	"widgets.updateYourEmailAddress": "あなたのメールアドレスを更新する",
	"widgets.selectADefaultAddress": "デフォルトアドレスを選択",
	"widgets.activity": "アクティビティ",
	"widgets.basicCalendar": "基本カレンダー",
	"widgets.culturesCalendar": "カルチャーカレンダー",
	"widgets.dragAndDropCalendar": "ドラッグアンドドロップカレンダー",
	"widgets.quillEditor": "クイルエディタ",
	"widgets.reactDND": "反応dnd",
	"widgets.dragula": "ドラッグラ",
	"button.acceptTerms": "受諾条件",
	"button.reject": "拒否",
	"button.addNew": "新しく追加する",
	"button.goToCampaign": "キャンペーンへ移動",
	"button.viewProfile": "プロフィールを見る",
	"button.sendMessage": "メッセージを送る",
	"button.saveNow": "今すぐ保存",
	"button.pen": "ペン",
	"button.search": "サーチ",
	"button.downloadPdfReport": "pdfレポートをダウンロード",
	"button.primary": "一次",
	"button.secondary": "二次",
	"button.danger": "危険",
	"button.info": "情報",
	"button.success": "成功",
	"button.warning": "警告",
	"button.link": "リンク",
	"button.smallButton": "小さなボタン",
	"button.largeButton": "大きなボタン",
	"button.blockLevelButton": "ブロックレベルボタン",
	"button.primaryButton": "プライマリボタン",
	"button.button": "ボタン",
	"button.save": "セーブ",
	"button.openMenu": "メニューを開く",
	"button.openWithFadeTransition": "フェードトランジションで開く",
	"button.openPopover": "オープン・ポップ・オーバー",
	"button.accept": "同意する",
	"button.click": "クリック",
	"button.complete": "コンプリート",
	"button.back": "バック",
	"button.next": "次",
	"button.completeStep": "完全なステップ",
	"button.error": "エラー",
	"button.writeNewMessage": "新しいメッセージを書く",
	"button.saveChanges": "変更内容を保存",
	"button.addNewUser": "新しいユーザーを追加",
	"button.more": "もっと",
	"hint.searchMailList": "メールリストを検索",
	"widgets.AcceptorrRejectWithin": "以内に同意または拒否する",
	"widgets.quoteOfTheDay": "今日の名言",
	"widgets.updated10Minago": "1時間前に更新されました",
	"widgets.personalSchedule": "個人のスケジュール",
	"widgets.activeUsers": "アクティブユーザー",
	"widgets.totalRequest": "総リクエスト",
	"widgets.new": "新しい",
	"widgets.ShareWithFriends": "友人たちと分ける！",
	"widgets.helpToShareText": "私たちのウェブサイトをあなたの友人やソーシャルメディアのフォロワーと共有することで、世界を広げるお手伝いをしてください！",
	"widgets.thisWeek": "今週",
	"widgets.howWouldYouRateUs": "どのように評価しますか？",
	"widgets.booking": "予約",
	"widgets.confirmed": "確認済み",
	"widgets.monthly": "毎月",
	"widgets.weekly": "ウィークリー",
	"widgets.target": "ターゲット",
	"widgets.totalActiveUsers": "総アクティブユーザー数",
	"sidebar.user": "ユーザー",
	"sidebar.miscellaneous": "雑",
	"sidebar.promo": "プロモーション",
	"themeOptions.themeColor": "テーマカラー",
	"module.inbox": "受信トレイ",
	"module.drafts": "下書き",
	"module.sent": "送信済み",
	"module.trash": "ごみ",
	"module.spam": "スパム",
	"module.frontend": "フロントエンド",
	"module.backend": "バックエンド",
	"module.api": "Api",
	"module.issue": "問題",
	"components.emailPrefrences": "メールの設定",
	"components.myProfile": "私のプロフィール",
	"sidebar.gettingStarted": "入門",
	"widgets.deadline": "締め切り",
	"widgets.team": "チーム",
	"widgets.projectManagement": "プロジェクト管理",
	"widgets.latestPost": "最新の投稿",
	"widgets.projectTaskManagement": "プロジェクトタスク管理",
	"widgets.selectProject": "プロジェクトを選択",
	"widgets.activityBoard": "アクティビティボード",
	"widgets.checklist": "チェックリスト",
	"sidebar.shop": "ショップ",
	"sidebar.cart": "カート",
	"sidebar.checkout": "チェックアウト",
	"components.companiesAndRoles": "会社と役割",
	"components.product": "製品",
	"components.quantity": "量",
	"components.totalPrice": "合計金額",
	"components.removeProduct": "製品を削除する",
	"components.mobileNumber": "携帯電話番号",
	"components.address2Optional": "住所2（オプション）",
	"components.country": "国",
	"components.zip": "ジップ",
	"components.saveContinue": "保存して続行",
	"components.placeOrder": "注文する",
	"components.payment": "支払い",
	"components.billingAddress": "請求先住所",
	"components.ShippingAddressText": "配送先住所は請求先住所と同じです。",
	"components.CartEmptyText": "ショッピングカートは空です！",
	"components.NoItemFound": "アイテムが見つかりません",
	"components.goToShop": "店へ出かける",
	"components.cardNumber": "カード番号",
	"components.expiryDate": "有効期限",
	"components.cvv": "CVV",
	"components.nameOnCard": "カード上の名前",
	"components.confirmPayment": "お支払いの確認",
	"sidebar.saas": "SAAS",
	"sidebar.multiLevel": "マルチレベル",
	"sidebar.level1": "レベル1",
	"sidebar.level2": "レベル2",
	"sidebar.boxed": "箱入り",
	"sidebar.news": "ニュース",
	"sidebar.extensions": "拡張機能",
	"sidebar.imageCropper": "Image Cropper",
	"sidebar.videoPlayer": "ビデオプレーヤー",
	"sidebar.dropzone": "Dropzone",
	"widgets.baseConfig": "基本構成",
	"widgets.customControlBar": "カスタムコントロールバー",
	"widgets.withDownloadButton": "ダウンロードボタン付き",
	"widgets.httpLiveStreaming": "HTTPライブストリーミング",
	"widgets.keyboardShortcuts": "キーボードショートカット",
	"button.useDefaultImage": "デフォルト画像を使用する",
	"button.cropImage": "クロップ画像",
	"widgets.preview": "プレビュー",
	"widgets.croppedImage": "クロップド画像"
}