import { Activable } from "./activable";
import Table from './table';
import { MARKET_SERVICE_IMG_PATH, Category } from "Constants";
import { storage } from '../firebase_config';
import { BaseRecord } from "./base";

export type ParameterType = "TEXT" | "AMOUNT" | "PHONE" | "NUMBER" | "BOOLEAN" | "COUNTRY"

export interface BaseParameter {
    name: string
    type: ParameterType
}

export interface ServiceParameter extends BaseParameter {
    choices?: any[]
    hint?: string
    optional?: boolean
}

export interface ParameterStatic extends BaseParameter {
    result: any
}

export class MarketService extends Activable {
    coll_ref: string = Table.MarketService

    name: string
    category: string
    description: string
    imgFile: string
    imgPath: string
    randomColor: boolean
    stringsId: string
    actionContractId?: string
    shippingContractId?: string
    parameters: ServiceParameter[]
    statics: ParameterStatic[]
    versionCode: number
    checkoutUrl?: string
    acl?: string[]

    constructor(parent?: BaseRecord) {
        super(parent)
        this.name = ""
        this.category = "UNDEF"
        this.description = ""
        this.randomColor = false
        this.stringsId = "strings_1"
        this.parameters = []
        this.statics = []
        this.versionCode = 0
    }

    protected beforeCreate(data: any): any {
        data.imgPath = MARKET_SERVICE_IMG_PATH
        return data
    }

    deleteImageFile() {
        const ref = this.getStorageReference()
        if (ref == null) return
        ref.delete()
            .catch(error => {
                console.error("delete error:", error)
            })
    }

    getStorageReference(): firebase.storage.Reference | null {
        if (!this.imgFile || !this.imgPath) return null
        const url = [this.imgPath, this.imgFile].join("/")
        return storage.refFromURL(url)
    }

    setFileReference(ref: firebase.storage.Reference) {
        try {
            this.deleteImageFile()
        } catch (error) {
            console.log("fime not deleted : ", error)
        }
        this.imgFile = ref.name
        const fullRef = `gs://${ref.bucket}/${ref.fullPath}`
        const rgx = new RegExp("/" + this.imgFile + "$")
        this.imgPath = fullRef.replace(rgx, "")
    }

}