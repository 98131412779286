import { Role } from "./role";
import { ActivationCondition } from "./activation_condition";
import { ActivationMap } from "./activation_map";
import { DocumentData } from "@google-cloud/firestore";

export const NO_LIMIT = 999999999999;

export class Signatory extends ActivationMap {
    coll_ref: string = "signatories"

    maxAmount: number = 0

    static dbFields = ["role", "qty"]

    documentData(): DocumentData {
        if (this.maxAmount < 0) this.maxAmount = NO_LIMIT
        return {
            maxAmount: this.maxAmount,
            conditions: this.toConditions()
        }
    }

    isValid(): boolean {
        return this.maxAmount !== 0 && this.toConditions().length > 0
    }

    sanitized(): Signatory {
        if (this.maxAmount < 0) this.maxAmount = NO_LIMIT
        return this
    }

    protected static setDefaults(): Signatory[] {
        const sig = new Signatory()
        sig.maxAmount = NO_LIMIT
        sig.default = true
        sig.setConditions([{
            qty: 1, role: Role.Boss.id
        }])
        return [sig]
    }

    static defaults = Signatory.setDefaults()

}

