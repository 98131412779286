import { CorporateAccount } from './corporate'
import { AccountType } from './base'
import { ExtraField } from 'Interfaces'
import { LOCAL_APP } from 'Constants'
import * as _ from 'lodash'
import { BaseRecord, ParentCreator } from 'Models/base'
import { DocumentData } from "@google-cloud/firestore";
import Table from 'Models/table'

export enum ContractType {
    ACTION, SHIPPING
}

const SHIPPING_DEFAULT_PARAMS = ["shippingCode", "country", "name", "amount"]

export class ActionContract extends CorporateAccount {
    accountType = AccountType.SHARING_ACTION
    accountId: string
    description?: string
    extraDefault: ExtraField = {}
    params: string[] = []
    url: string
    calculatorUrl?: string
    uid: string     //User ID to login in
    amount: number = 0
    marketServiceId: string
    contractType: ContractType = ContractType.ACTION
    isPublic: boolean = false // To be used with Shipping contract type

    static initial(): ActionContract {
        let ac = new ActionContract();
        ac.name = '';
        ac.description = '';
        ac.accountId = '';
        ac.url = '';
        return ac;
    }

    setType(type: ContractType) {
        this.contractType = type
        switch (type) {
            case ContractType.ACTION:
                delete this.calculatorUrl
                this.params = _.remove(this.params, x => { SHIPPING_DEFAULT_PARAMS.includes(x) })
                break;
            case ContractType.SHIPPING:
                this.params = _.uniq(this.params.concat(SHIPPING_DEFAULT_PARAMS))
                this.calculatorUrl = LOCAL_APP
                break;
        }
    }

    defaultParams(): string[] {
        if (this.getType() == ContractType.ACTION) return []
        return SHIPPING_DEFAULT_PARAMS
    }

    getType(): ContractType {
        return this.contractType
    }
}

//This class is used by List
export class ActionContractCreator extends ActionContract {

    constructor() {
        super()
    }

}


export class ActionContractPlus extends ActionContract {

    coll_ref: string = "accounts"
    actionContractId: string
    mcId: string

    constructor(parent?: BaseRecord) {
        super()
        this.parent = parent
    }
    static from(acc: ActionContract): ActionContractPlus {
        return acc as ActionContractPlus
    }

    assign(data: DocumentData) {
        super.assign(data)
        this.parent = new ParentCreator(data.mcId, Table.MobileCurrency)
    }

}

