import { BaseRecord } from "./base";
import Table from './table';

export enum ApprovalStatus {
    REJECTED = -1,
    CREATED,
    PROCESSING,
    ACCEPTED
}

export class Approval extends BaseRecord {
    coll_ref: string = Table.Approval
    parentRef: string
    cause?: string
    status: ApprovalStatus = ApprovalStatus.CREATED
}