import { KashRequest, ActivationRequest, Membership, Operation } from "Models";
import { KASH_REQUEST_PROCESSING, KASH_REQUEST_CREATED, KASH_REQUEST_COMPLETED, KASH_REQUEST_RESET } from "./types";
import Api, { saveKashRequest, checkKashRequestCompleted, deleteKashRequest } from "Api";

export const createRequest = (ar: ActivationRequest, member: Membership) => (dispatch) => {
    let kr = KashRequest.fromActivationRequest(ar, member.userId)
    dispatch({ type: KASH_REQUEST_PROCESSING, payload: kr.url() });
    saveKashRequest(kr)
        .then(res => {
            dispatch({type: KASH_REQUEST_CREATED, payload: res})
            return checkKashRequestCompleted(kr)
        })
        .then(res => {
            dispatch({type: KASH_REQUEST_COMPLETED, payload: res})
            return Api.signRequest(res)   
        })
        .then(res => {
            return deleteKashRequestAction(kr)(dispatch)
        })
        .catch(err =>{
            console.error(err)
            dispatch({type: KASH_REQUEST_RESET})
        })
};

export const createOperationRequest = (op: Operation, member: Membership) => (dispatch) => {
    let kr = KashRequest.fromOperationRequest(op, member.userId)
    dispatch({ type: KASH_REQUEST_PROCESSING, payload: kr.url() });
    saveKashRequest(kr)
        .then(res => {
            dispatch({type: KASH_REQUEST_CREATED, payload: res})
            return checkKashRequestCompleted(kr)
        })
        .then(res => {
            dispatch({type: KASH_REQUEST_COMPLETED, payload: res})
            return Api.signRequest(res)   
        })
        .then(res => {
            return deleteKashRequestAction(kr)(dispatch)
        })
        .catch(err =>{
            console.error(err)
            dispatch({type: KASH_REQUEST_RESET})
        })
};

export const deleteKashRequestAction = (kr: KashRequest) => (dispatch) => {
    dispatch({type: KASH_REQUEST_RESET})
    return deleteKashRequest(kr)
}