export default {
    Account: "accounts",
    Approval: "approvals",
    Backfire: "backfires",
    BankInfo: "banks",
    CsvFile: "csv",
    Entry: "entries",
    I18n: "market_i18n",
    Invitation: "licenses_links",
    KashRequest: "kash_requests",
    LicenseSpecs: "specs",
    LicenseType: "licenses_types",
    Link: "links",
    Member: "members",
    MobileCurrency: "currencies",
    MarketService: "market_services",
    Operation: "operations",
    Partner: "partners",
    Payee: "payees",
    Prospect: "prospects",
    Role: "roles",
    Server: "servers",
    Signatory: "signatories",
    Store: "stores",
    Verification: "verifications",
    User: "users"
}