import { NotificationManager } from 'react-notifications';
import api, {
    getGroupedAccounts,
    createApprovalRequests,
    setRecordListener,
    getPendingOrFailedOperations,
    getPendingCurrencyOperations,
    fscDeletePayee,
    fscCreatePayee,
    fscCreateExtPayee,
    fscUpdatePayee,
    addDocument,
    getRecordDocuments,
    delDocument,
    updateDocument
} from "Api"
import {
    ADD_NEW_COMPANY,
    COMPANY_ADD_COMPLETED,
    COMPANY_ADD_FAILED,
    FETCHING_MEMBERSHIP,
    MEMBERSHIP_LIST_RECEIVED,
    MEMBERSHIP_FETCHING_FAILED,
    ACCOUNT_GROUP_LIST,
    APPROVAL_REQUEST,
    PAYEE_LIST,
    BANK_INFO_MAP,
    DEL_BANK_INFO,
    ADD_BANK_INFO,
    WITH_TESTING,
    GET_OPERATIONS,
    GET_CURRENCY_OPERATIONS,
    REMOVE_PAYEE,
    LOADING_DATA,
    ADDED_PAYEE,
    UPDATE_PAYEE,
    GET_ROLES,
    ADD_ROLE,
    UPDATE_ROLE,
    DELETED_ROLE,
    ACTIVATION_MAPS,
    IB_LIST_LOADING,
    IB_LIST_MCS,
    IB_LIST_ACCOUNTS
} from "Actions/types";
import { Membership, NewMemberData } from 'src/models/membership';
import { Organization, Payee, NewPayee, Role, BankInfo } from 'Models';
import { Approval } from 'Models/approval';
import { CurrencyGroup } from 'src/groups/accounts1';
import { ActivationMap } from 'Models/activation_map';
import { BankInfoMap } from 'Interfaces';
import { NEW_ID } from 'Constants';

export const addNewRole = (token: string, newRole: Role) => (dispatch) => {
    return api.addNewRole(newRole, token)
        .then(res => {
            const role = new Role()
            role.assign(res)
            dispatch({ type: ADD_ROLE, payload: role });
            return Promise.resolve()
        })
}

export const updateRole = (token: string, newRole: Role) => (dispatch) => {
    return api.updateRole(newRole, token)
        .then(res => {
            const role = new Role()
            role.assign(res)
            dispatch({ type: UPDATE_ROLE, payload: role });
            return Promise.resolve()
        })
}
export const deleteRole = (token: string, role: Role) => (dispatch) => {
    return api.deleteRole(role.id, token)
        .then(_ => {
            dispatch({ type: DELETED_ROLE, payload: role });
            return Promise.resolve()
        })
        .catch(error => {
            console.log("error on action ", error)
            return Promise.reject(error)
        })

}

export const addNewCompanyAction = (token: string, newCompanyData: any) => (dispatch) => {
    dispatch({ type: ADD_NEW_COMPANY });
    return api.postPartner(token, newCompanyData)
        .then(() => {
            dispatch({ type: COMPANY_ADD_COMPLETED })
            dispatch(getMemberships(token))
            return Promise.resolve() // used to manage loading
        })
        .catch((e) => {
            NotificationManager.error(e.message, 'Could not add Company');
            dispatch({ type: COMPANY_ADD_FAILED, payload: e });
            return Promise.resolve()
        });
};

export const getMemberships = (token: string) => (dispatch) => {
    dispatch({ type: FETCHING_MEMBERSHIP });
    dispatch({ type: LOADING_DATA, payload: true })
    api.getMemberships(token)
        .then((response) => {
            dispatch({ type: LOADING_DATA, payload: false })
            dispatch({ type: MEMBERSHIP_LIST_RECEIVED, payload: response.data })
        })
        .catch(error => {
            dispatch({ type: LOADING_DATA, payload: false })
            // error hanlding
            NotificationManager.error(error.message);
            dispatch({ type: MEMBERSHIP_FETCHING_FAILED })
        })
}

export const addNewMember = (token: string, data: NewMemberData) => (dispatch) => {
    api.postMember(data, token)
        .then(response => {
            NotificationManager.success("New member added", "Success")
        })
        .catch(error => {
            NotificationManager.error(error.response.data, "Operation failed!");
        })
}

export const deleteMember = (token: string, id: string) => (dispatch) => {
    api.deleteMember(id, token)
        .then(response => {
            NotificationManager.success("Member marked for deletion", "Success")
        })
        .catch(error => {
            NotificationManager.error(error.response.data, "Operation failed!");
        })
}

export const deleteOperation = (token: string, id: string) => (dispatch) => {
    return api.deleteOperation(id, token)
        .then(response => {
            NotificationManager.success("Operaiton marked for deletion", "Success")
            return Promise.resolve()
        })
        .catch(error => {
            NotificationManager.error(error.response.data, "Operation failed!");
            return Promise.reject(error)
        })
}

export const deletePayee = (payee: Payee) => (dispatch) => {
    dispatch({ type: LOADING_DATA, payload: true })
    fscDeletePayee(payee)
        .then(response => {
            dispatch({ type: LOADING_DATA, payload: false })
            NotificationManager.success("Payee deleted", "Success")
            dispatch({ type: REMOVE_PAYEE, payload: payee })
        })
        .catch(error => {
            console.error(error)
            dispatch({ type: LOADING_DATA, payload: false })
            NotificationManager.error(error.message, "Operation failed!");
        })
}

export const setPayee = (payee: NewPayee) => (dispatch) => {
    if (payee.id === undefined || payee.id === "new") return createPayee(payee)(dispatch)
    updatePayee(payee)(dispatch)
}

export const createPayee = (payee: NewPayee) => (dispatch) => {
    dispatch({ type: LOADING_DATA, payload: true })
    let fn = payee.external ? fscCreateExtPayee : fscCreatePayee
    fn(payee)
        .then(response => {
            NotificationManager.success("Payee created", "Success")
            dispatch({ type: LOADING_DATA, payload: false })
            dispatch({ type: ADDED_PAYEE, payload: response })
        })
        .catch(error => {
            console.error(error)
            dispatch({ type: LOADING_DATA, payload: false })
            NotificationManager.error(error.message, "Operation failed!");
        })
}

export const updatePayee = (payee: NewPayee) => (dispatch) => {
    dispatch({ type: LOADING_DATA, payload: true })
    fscUpdatePayee(payee)
        .then(response => {
            NotificationManager.success("Payee updated", "Success")
            dispatch({ type: LOADING_DATA, payload: false })
            dispatch({ type: UPDATE_PAYEE, payload: response })
        })
        .catch(error => {
            console.error(error)
            dispatch({ type: LOADING_DATA, payload: false })
            NotificationManager.error(error.message, "Operation failed!");
        })
}

export const addRegularAccount = (member: Membership, withTests: boolean, firebaseToken: string) => (dispatch) => {
    api.setDefaultAccount(firebaseToken)
        .then(_ => {
            NotificationManager.success("Account created", "Success")
            return fetchGroupedAccounts(member, withTests)(dispatch)
        })
        .catch(error => {
            NotificationManager.error(error.response.data, "Operation failed!");
            return Promise.reject(error)
        })
}

export const fetchGroupedAccounts = (member: Membership, withTests: boolean) => (dispatch) => {
    return getGroupedAccounts(member, withTests)
        .then(group => {
            dispatch({ type: ACCOUNT_GROUP_LIST, payload: group })
            //fetchPendingOperations(group, withTests)(dispatch)
            //fetchPendingCurrencyOperations(group)(dispatch)
            fetchCurrencyAccounts(member)(dispatch)
            return Promise.resolve()
        })
}

export const getActivationMaps = (fbToken: string) => (dispatch) => {
    return api.getActivationMaps(fbToken)
        .then(res => Promise.resolve(res.map(data => {
            let sig = new ActivationMap()
            sig.assign(data)
            return sig
        })))
        .then(res => {
            dispatch({ type: ACTIVATION_MAPS, payload: ActivationMap.mergeDefaults(res) })
            return Promise.resolve()
        })
}


export const fetchPendingOperations = (group: CurrencyGroup[], query?: firebase.firestore.Query) => (dispatch) => {
    return getPendingOrFailedOperations(group, query)
        .then(res => {
            dispatch({ type: GET_OPERATIONS, payload: res })
            return Promise.resolve()
        })
}

export const fetchCurrencyAccounts = (member: Membership) => (dispatch) => {
    dispatch({ type: IB_LIST_LOADING, payload: true })
    api.getIBCurrencies(member)
        .then(result => {
            dispatch({ type: IB_LIST_MCS, payload: result })
            return api.getCurrencyAccounts(result)
        })
        .then(result => {
            dispatch({ type: IB_LIST_ACCOUNTS, payload: result })
            dispatch({ type: IB_LIST_LOADING, payload: false })
        })
        .catch(error => {
            dispatch({ type: IB_LIST_LOADING, payload: false })
            NotificationManager.error(error.message, "Error")
            console.error(error)
        })
}


export const requestApproval = (partner: Organization) => (dispatch) => {
    return createApprovalRequests(partner)
        .then(appr => {
            //dispatch({type:APPROVAL_REQUEST, payload: appr})
            setApprovalListener(appr)(dispatch)
            return Promise.resolve()
        })
}

export const setApprovalListener = (approval: Approval) => (dispatch) => {
    let cb = (approval: Approval) => {
        dispatch({ type: APPROVAL_REQUEST, payload: approval })
    }
    setRecordListener(approval, cb)
}

export const getPayees = (partner: Organization) => (dispatch) => {
    dispatch({ type: LOADING_DATA, payload: true })
    getRecordDocuments(Payee, partner)
        .then(res => {
            getPayeeAccounts(res)(dispatch)
            dispatch({ type: LOADING_DATA, payload: false })
            dispatch({ type: PAYEE_LIST, payload: res })
        })
        .catch(error => {
            dispatch({ type: LOADING_DATA, payload: false })
            const msg = !error.response ? error.message : error.response.data
            NotificationManager.error(msg, "Operation failed!");
        })
}

const getPayeeAccounts = (payees: Payee[]) => (dispatch) => {
    payees.map(payee => {
        return getRecordDocuments(BankInfo, payee)
            .then(banks => {
                let map: BankInfoMap = {}
                map[payee.id] = banks
                return Promise.resolve(map)
            })
    }).reduce((acc: Promise<BankInfoMap>, current: Promise<BankInfoMap>) => {
        return acc.then(imap => current.then(cmap => {
            Object.keys(cmap).forEach(key => imap[key] = cmap[key])
            return Promise.resolve(imap)
        }))
    }, Promise.resolve({}))
        .then(mapResult => {
            dispatch({ type: BANK_INFO_MAP, payload: mapResult })
        })
}

export const deletePayeeAccount = (acc: BankInfo) => (dispatch) => {
    return delDocument(acc)
        .then(_ => {
            dispatch({ type: DEL_BANK_INFO, payload: acc })
            return Promise.resolve()
        })
}


export const setPayeeAccount = (acc: BankInfo) => (dispatch) => {
    if (!acc.parent) return Promise.reject(new Error("Parent is null"))
    let fn = acc.id === NEW_ID ? addPayeeAccount : uodatePayeeAccount
    return fn(acc)(dispatch)
}

const addPayeeAccount = (acc: BankInfo) => (dispatch) => {
    if (!acc.parent) return Promise.reject(new Error("Parent is null"))
    return addDocument(acc, BankInfo)
        .then(acc => {
            dispatch({ type: ADD_BANK_INFO, payload: acc })
            return Promise.resolve()
        })
}

const uodatePayeeAccount = (acc: BankInfo) => (dispatch) => {
    if (!acc.parent) return Promise.reject(new Error("Parent is null"))
    dispatch({ type: DEL_BANK_INFO, payload: acc })
    return updateDocument(acc)
        .then(_ => {
            dispatch({ type: ADD_BANK_INFO, payload: acc })
            return Promise.resolve()
        })
}

export const getRoles = (partner: Organization) => (dispatch) => {
    return getRecordDocuments(Role, partner)
        .then(roles => {
            dispatch({ type: GET_ROLES, payload: roles });
            return Promise.resolve()
        })
        .catch(error => {
            console.error(error)
            return Promise.reject(error)
        })
}

export const onTestingFeatures = (isOn) => ({
    type: WITH_TESTING,
    payload: isOn
});

