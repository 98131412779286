/**
 * Users Routes
 */
/* eslint-disable */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// async components
import {
    AsyncLicenseSpecComponent,
    AsyncLicenseTypeComponent,
    AsyncLicenseContractListComponent,
    AsyncLicenseContractEditComponent
} from 'Components/AsyncComponent/AsyncComponent';

const Forms = ({ match }) => (
    <div className="content-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/specs`} />
            <Route path={`${match.url}/specs`} component={AsyncLicenseSpecComponent} />
            <Route path={`${match.url}/types`} component={AsyncLicenseTypeComponent} />
            <Route path={`${match.url}/contracts`} component={AsyncLicenseContractListComponent} />
            <Route path={`${match.url}/contract/:id`} component={AsyncLicenseContractEditComponent} />
        </Switch>
    </div>
);

export default Forms;