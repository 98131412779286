import { BaseRecord } from "./base";
import Table from './table';
import { ActivationRequest } from "./activation_request";
import uuidv4 from 'uuid/v4';
import { isNullOrUndefined } from "util";
import { Operation } from "./operation";
import { KASH_SIGN_VALUE, SING_OP_CONTROLLER, PUBLIC_USER_ID } from "Constants";
const KASH_REQUEST_TYPE = "kr"
const SIGNATURE_FIELD = "signature"
const ACTION_KEY = "action"
const NEW_PARAMS_KEY = "newParams"
const DOCUMENT_PATH_KEY = "documentPath"
const CONTROLLER_KEY = "controller"
const SUBJECT_KEY = "sub"
const KASH_REQUEST_SCHEME = "kr"
const API_VERSION = "v1.0"


function strMapToObj(strMap: Map<string, any>) {
    let obj = Object.create(null);
    strMap.forEach((v, k) => {
        // We don’t escape the key '__proto__'
        // which can cause problems on older engines
        obj[k] = v;
    })
    return obj;
}

function objToStrMap(obj) {
    let strMap: Map<string, any> = new Map();
    Object.keys(obj).forEach(k => {
        strMap.set(k, obj[k]);
    })
    return strMap;
}


export class KashRequest extends BaseRecord {

    coll_ref: string = Table.KashRequest
    readonly type: string = KASH_REQUEST_TYPE
    fields: string[] = []
    secured: boolean = true
    emitId: string
    userId: string = PUBLIC_USER_ID
    completed: boolean = false
    data: Map<string, any> = new Map()


    static fromActivationRequest(ar: ActivationRequest, id: string): KashRequest {
        let kr = new KashRequest()
        kr.emitId = id
        kr.id = uuidv4()
        kr.data.set(DOCUMENT_PATH_KEY, ar._subjectPath)
        kr.data.set(ACTION_KEY, ar.action)
        kr.data.set(SUBJECT_KEY, ar.id)
        kr.data.set(CONTROLLER_KEY, ar.modelType.value)
        if (!isNullOrUndefined(ar.newParams)) kr.data.set(NEW_PARAMS_KEY, ar.newParams)
        kr.fields = [SIGNATURE_FIELD]
        return kr
    }

    static fromOperationRequest(op: Operation, userId: string): KashRequest {
        let kr = new KashRequest()
        kr.emitId = userId
        kr.id = uuidv4()
        kr.data.set(DOCUMENT_PATH_KEY, op.coll_ref)
        kr.data.set(ACTION_KEY, KASH_SIGN_VALUE)
        kr.data.set(SUBJECT_KEY, op.id)
        kr.data.set(CONTROLLER_KEY, SING_OP_CONTROLLER)
        kr.fields = [SIGNATURE_FIELD]
        return kr
    }

    url(): string {
        return `${KASH_REQUEST_SCHEME}://${API_VERSION}/${this.relativePath()}`
    }

    controller(): string {
        return [this.data[CONTROLLER_KEY], this.data[SUBJECT_KEY]].join("/")
    }

    signature(): string {
        return this.data[SIGNATURE_FIELD]
    }

    protected preventedField(): string[] {
        return super.preventedField().concat(["data"])
    }

    protected beforeCreate(_data: any): any {
        let obj = strMapToObj(this.data)
        _data.data = obj
        return _data
    }

}