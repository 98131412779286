import { BaseRecord } from "./base";
import Table from './table'
import { Timestamp } from "@google-cloud/firestore";

export enum ContactType {
    PHONE = "PHONE", EMAIL = "EMAIL"
}

export enum CreditType {
    KASH = "KASH", IBAN = "IBAN"
}

export class PayLink extends BaseRecord {
    coll_ref: string = Table.Link

    contactType: ContactType
    contactId: string
    amount: number
    mcId: string
    debitId: string
    debitName: string
    creditType: CreditType
    creditId: string
    effectDate: Timestamp
    opId: string
    note: String

    fmtAmount(): string {
        return Intl.NumberFormat('fr-FR',{ style: 'currency', currency: 'XAF'}).format(this.amount)
    }

}