/**
 * FCM Reducers
 */
import update from 'immutability-helper';
import {
    NO_FCM_STATUS,
    NO_TOKEN_STATUS,
    REQUEST_FCM_PERMISSION,
    FCM_PERMISSION_GRANTED,
    FCM_PERMISSION_REFUSED,
    TOKEN_REQUEST,
    TOKEN_SUCCESS,
    TOKEN_FAILURE
} from 'Actions/types';
import { FCM_TOKEN, FCM_PERMISSION } from '../constants/storagePrefs';

/**
 * initial auth user
 */
const INIT_STATE = {
    status: NO_FCM_STATUS,
    loading: false,
    tokenStatus: NO_TOKEN_STATUS,
    token: localStorage.getItem(FCM_TOKEN)
};

function initialState(){
    var state = INIT_STATE;
    if(localStorage.getItem(FCM_PERMISSION)){
        state.status = FCM_PERMISSION_GRANTED;
    }
    return state;
}

export default (state = initialState(), action) => {
    switch (action.type) {

        case REQUEST_FCM_PERMISSION:
            return { ...state, loading: true };

        case FCM_PERMISSION_GRANTED:
            localStorage.setItem(FCM_PERMISSION, true);
            return update(state, {
                status: {$set : FCM_PERMISSION_GRANTED},
                loading: {$set :  false}
            });

        case FCM_PERMISSION_REFUSED:
            localStorage.setItem(FCM_PERMISSION, false);
            return update(state, {
                status: {$set : FCM_PERMISSION_REFUSED},
                loading: {$set :  false}
            });

        case TOKEN_REQUEST:
            return update(state, {
                tokenStatus : {$set: TOKEN_REQUEST},
                loading: {$set: true}
            });

        case TOKEN_SUCCESS:
            localStorage.setItem(FCM_TOKEN, action.payload);
            return update(state, {
                tokenStatus: {$set: action.type},
                loading: {$set: false},
                token: {$set: action.payload}
            })

        case TOKEN_FAILURE:
            return update(state, {
                tokenStatus: {$set : TOKEN_FAILURE},
                loading: {$set: false},
            });

        default: return { ...state };
    }
}
