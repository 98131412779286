export interface ActivationCondition {
    role: string
    qty: number
}

export type ConditionMap = { [field: string]: number };


export function conditionString(cond: ActivationCondition): string {
    return `${cond.qty} * ${cond.role}`
}