import api from "Api";
import { fetchingData } from "./OperationActions";
import { PayLink, Prospect } from "Models";
import { NotificationManager } from 'react-notifications';
import { Invitation } from "Models/invitation";

export const getPaymentLink = (id: string) => (dispatch) => {
    dispatch(fetchingData(true))
    return api.getLink(id)
        .then(res => {
            dispatch(fetchingData(false))
            return Promise.resolve(res.data)
        })
        .catch(e => {
            dispatch(fetchingData(false))
            return Promise.reject(e)
        })
}

export const updatePaylink = (id: string, link: PayLink) => (dispatch) => {
    dispatch(fetchingData(true))
    return api.updateLink(id, link)
        .then(res => {
            dispatch(fetchingData(false))
            return Promise.resolve()
        })
        .catch(e => {
            dispatch(fetchingData(false))
            NotificationManager.error(e.message, 'Could update link');
            return Promise.reject(e)
        })
}

export const getInvitation = (id: string) => (dispatch) => {
    dispatch(fetchingData(true))
    return api.getInvitation(id)
        .then(res => {
            dispatch(fetchingData(false))
            return Promise.resolve(res.data)
        })
        .catch(e => {
            dispatch(fetchingData(false))
            return Promise.reject(e)
        })
}

export const updateProspect = (prospect: Prospect) => (dispatch) => {
    dispatch(fetchingData(true))
    return api.updateProspect(prospect)
        .then(res => {
            dispatch(fetchingData(false))
            return Promise.resolve()
        })
        .catch(e => {
            dispatch(fetchingData(false))
            NotificationManager.error(e.message, 'Could update link');
            return Promise.reject(e)
        })
}
