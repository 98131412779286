/**
 * Sidebar Content
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import SidebarItem from './Sidebaritem';
import { ApplicationState } from 'src/reducers';
import { ISidebarMenu, NavCategory } from './NavLinks';
import { sidebarItem} from './nav_filter'


class SidebarContent extends Component<SidebarContentProps> {

	render() {
		const { sidebarMenus} = this.props
		return (
			<div className="rct-sidebar-nav">
					<nav className="navigation">
						{Object.keys(sidebarMenus).map(key => {
							let item = sidebarItem(key as NavCategory)
							return(<SidebarItem
								key={key}
								header={item.header}
								category={item.category}/>)
						})}
				</nav>
			</div>
		);
	}
}

// Props passed from mapStateToProps
interface PropsFromState {
	sidebarMenus: ISidebarMenu
}

// Component-specific props.
interface OtherProps {
	children: (props: PropsFromState) => React.ReactNode
}

type SidebarContentProps = PropsFromState & OtherProps;

// map state to props
const mapStateToProps = ({ sidebar }: ApplicationState) => {
	const { sidebarMenus} = sidebar

	return { sidebarMenus };
}

export default connect(mapStateToProps)(SidebarContent);
