import Table from './table';
import { BaseRecord } from "./base";

export class ServerStore extends BaseRecord {
    coll_ref: string = Table.Store

    name: string
    mcId: string
    latitude: number
    longitude: number
    phone: number
    logo: number

}