import { Membership, CorporateAccount, KashRequest, MemberBase, UserMembership, Role, Organization, OperationStatus, Payee, MobileCurrency, NewPayee, DefaultRole, ExtPayee, BankInfo, CurrencyAccount, CurrencyAccountDirect } from "Models";
import firebase from 'firebase';
import * as _ from 'lodash'
import Table from 'Models/table';
import { BaseRecord, ModelCreator } from "Models/base";
import { CurrencyGroup, generateAccountsGroup } from "../groups/accounts1";
import { BaseAccount, RawAccount, AccountType } from "Models/account/base";
import { Activable, ActivationStatus } from "Models/activable";
import { Verification } from "Models/verification";
import { User, Entry } from "Models";
import { notEmpty, groupRawAccounts } from "../lib/tools";
import { Approval } from "Models/approval";
import { storage } from '../firebase_config';
import { DOCS_REF, DEMO_ID, DEMO_CURRENCY, NEW_ID } from "Constants";
import { PartnerOperation, AmountType } from "Models/partner_operation";
import { QueryVar, OrderVar } from "Lib/query";

let db = firebase.firestore()

export function getDb(): firebase.firestore.Firestore {
    return db
}

type DbSubscriber = () => void

let unsubscribeKashRequest: DbSubscriber
export var unsubscribeMembers: DbSubscriber
//export var operationsSubscribers : DbSubscriber[] = []

function getEntries(member: Membership, field: string): Promise<Entry[]> {
    return db.collection(Table.Entry)
        .where(field, "==", member.partnerId)
        .where("effectDate", ">=", new Date(2018, 1))
        .orderBy("effectDate", "desc")
        .get()
        .then(BaseRecord.fromSnapshot(Entry))
        .then(entries => {
            const filtered = entries.filter(x => x.status >= 0)
            return Promise.resolve(filtered)
        })
}

function getMobileCurrencies(partnerId: string): Promise<MobileCurrency[]> {
    const queries: QueryVar[] = [["partnerId", "==", partnerId]]
    return getRecordDocuments(MobileCurrency, undefined, queries)
}

function getAccountPendingOps(acc: CurrencyAccount, order?: OrderVar, limit?: number, after?: any): Promise<PartnerOperation[]> {
    const queries: QueryVar[] = [
        ["mcId", "==", acc.mcId], ["status", "<=", OperationStatus.INIT], ["debitId", "==", acc.id]
    ]
    return getRecordDocuments(PartnerOperation, undefined, queries, order, limit, after)
        .then(ops => Promise.resolve(ops.map(op => {
            op.amountType = AmountType.DEBIT
            return op
        })))
}

export function addDocument<Q extends BaseRecord>(elm: Q, creator?: ModelCreator<Q>): Promise<Q> {
    const data = elm.documentData()
    const collRef = elm.collectionReference(db)
    if (elm.id === NEW_ID || !elm.setRecordId) {
        return collRef.add(data)
            .then(docRef => {
                elm.id = docRef.id
                return Promise.resolve(elm)
            })
            .catch(error => {
                console.log("failed on collRef", collRef)
                return Promise.reject(error)
            })
    } else {
        const id = elm.id || elm.setRecordId()
        return collRef.doc(id).set(data)
            .then(_ => {
                return Promise.resolve(elm)
            })
            .catch(error => {
                console.log("failed on collRef", collRef)
                return Promise.reject(error)
            })
    }
}

export function updateDocument<Q extends BaseRecord>(elm: Q): Promise<void> {
    let data = elm.documentData()
    return elm.documentReference(db).update(data)
}


export function delDocument<Q extends BaseRecord>(elm: Q): Promise<void> {
    return elm.documentReference(db).delete()
}


export function getDocument<Q extends BaseRecord>(creator: ModelCreator<Q>, id: string, parent?: BaseRecord): Promise<Q | null> {
    let elm = new creator(parent)
    return elm.collectionReference(db).doc(id).get()
        .then(docRef => Promise.resolve(BaseRecord.fromDocRef(creator)(docRef)))
}

export function getRecordDocuments<Q extends BaseRecord>(creator: ModelCreator<Q>, parent?: BaseRecord, queries?: QueryVar[], order?: OrderVar, limit?: number, after?: any): Promise<Q[]> {
    let elm = new creator(parent)
    let query = elm.collectionReference(db) as any as firebase.firestore.Query
    if (!!queries) {
        queries.forEach(qr => {
            query = query.where(qr[0], qr[1], qr[2])
        })
    }
    if (!!order) {
        query = query.orderBy(order[0], order[1])
    }
    if (!!limit) {
        query = query.limit(limit)
    }
    if (!!after) {
        query = query.startAfter(after)
    }
    return query.get()
        .then(BaseRecord.fromSnapshot(creator, parent))
        .catch(error => {
            console.error(error)
            console.log("error on query", query)
            return Promise.reject(error)
        })
}

export function getMultiParentRecordDocuments<Q extends BaseRecord>(creator: ModelCreator<Q>, parents: (BaseRecord | undefined)[], queries?: QueryVar[], order?: OrderVar, limit?: number, after?: any): Promise<Q[]> {
    const init: Q[] = []
    return parents.reduce((prev: Promise<Q[]>, parent: BaseRecord | undefined) => {
        let currentList: Q[]
        return prev
            .then(list => {
                currentList = list
                return getRecordDocuments(creator, parent, queries, order, limit, after)
            })
            .then(res => Promise.resolve(currentList.concat(res)))
    }, Promise.resolve(init))
}

export function getPartner(member: Membership): Promise<Organization> {
    let base = new Organization()
    return base.collectionReference(db).doc(member.partnerId).get()
        .then(BaseRecord.resolveSnapshotDocument(Organization))
        .then(getPartnerVerification)
        .then(getApprovalRequest)
}

function getPartnerVerification(partner: Organization): Promise<Organization> {
    let base = new Verification()
    return base.collectionReference(db).doc(partner.id).get()
        .then(BaseRecord.resolveSnapshotDocument(Verification))
        .catch(_ => Promise.resolve(undefined))
        .then(vrf => {
            partner.verification = vrf
            return Promise.resolve(partner)
        })
}

function getApprovalRequest(partner: Organization): Promise<Organization> {
    let base = new Approval()
    return base.collectionReference(db).doc(partner.id)
        .get()
        .then(BaseRecord.fromDocRef(Approval))
        .then(appr => {
            if (!!appr) partner.approval = appr
            return Promise.resolve(partner)
        })
        .catch(err => {
            console.error(err)
            return Promise.resolve(partner)
        })
}

export function setRecordListener(record: BaseRecord, callback: (rec: BaseRecord | null) => void): () => void {
    return record.documentReference(db)
        .onSnapshot(docSnap => {
            if (docSnap.exists) {
                let data = docSnap.data()
                if (data != undefined) record.assign(data)
                callback(record)
            } else callback(null)
        })
}

export function createApprovalRequests(partner: Organization): Promise<Approval> {
    let base = new Approval()
    base.parentRef = partner.coll_ref
    base.id = partner.id
    return base.create(Approval, db)
}
function getAllEntries(member: Membership): Promise<Entry[]> {
    let credits: Entry[]
    return getEntries(member, "creditUid")
        .then(list => {
            credits = list
            return getEntries(member, "debitUid")
        })
        .then(list => Promise.resolve(credits.concat(list)))
}

function deleteQueryBatch(query: firebase.firestore.Query,
    batchSize: number, resolve: (any?) => void, reject: (any?) => void) {
    query.get()
        .then(snapshot => {
            if (snapshot.empty) return Promise.resolve(0)
            // Delete documents in a batch
            let batch = db.batch();
            snapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });
            return batch.commit()
                .then(() => {
                    return snapshot.size;
                });
        })
        .then(numDeleted => {
            if (numDeleted == 0) {
                resolve()
                return
            }

            // Recurse on the next process tick, to avoid
            // exploding the stack.
            process.nextTick(() => {
                deleteQueryBatch(query, batchSize, resolve, reject);
            });
        })
        .catch(reject)
}


function deleteCollection(collectionRef: firebase.firestore.CollectionReference,
    batchSize: number): Promise<void> {
    let query = collectionRef.orderBy('__name__').limit(batchSize);
    return new Promise((resolve, reject) => {
        deleteQueryBatch(query, batchSize, resolve, reject);
    });
}

const partnerFilter = (member: Membership, withTests: boolean) => (account: CorporateAccount) => {
    return withTests ? true : member.partnerId == DEMO_ID ? account.mcId == DEMO_CURRENCY : account.mcId != DEMO_CURRENCY
}

export function getGroupedAccounts(member: Membership, withTests: boolean): Promise<CurrencyGroup[]> {
    let accounts: BaseAccount[]
    return db.collection(Table.Account)
        .where("partnerId", "==", member.partnerId)
        .get()
        .then(qSnap => {
            let accounts: CorporateAccount[] = []
            qSnap.forEach(doc => {
                accounts.push(new CorporateAccount(doc))
            })
            return Promise.resolve(accounts)
        })
        .then(list => {
            accounts = list.filter(partnerFilter(member, withTests))
            return getAllEntries(member)
        })
        .then(list => Promise.resolve(generateAccountsGroup(accounts, list)))
}

function _operationAccount(account: RawAccount, type: AmountType, _query?: firebase.firestore.Query): Promise<PartnerOperation[]> {
    let field = type == AmountType.CREDIT ? "creditId" : "debitId"
    return new Promise((resolve, reject) => {
        let query = _query != undefined ? _query : new PartnerOperation().collectionReference(db)
            .where("mcId", "==", account.mcId)
            .where("status", "<", OperationStatus.OK);
        query = query.where(field, "==", account.code)

        query.onSnapshot(snap => {
            let list = PartnerOperation.fromSnapshotDb(type)(snap)
            return resolve(list)
        }, error => {
            return reject(error)
        })
    })
}

function getOperationsOnAccount(account: RawAccount, query?: firebase.firestore.Query): Promise<PartnerOperation[]> {
    let reducer = (previous: Promise<PartnerOperation[]>, currentType: AmountType) => {
        return previous.then(res => {
            return _operationAccount(account, currentType, query)
                .then(ops => res.concat(ops))
        })
    }
    return [AmountType.DEBIT, AmountType.CREDIT].reduce(reducer, Promise.resolve([]))
}

export function getPendingOrFailedOperations(group: CurrencyGroup[], query?: firebase.firestore.Query): Promise<PartnerOperation[]> {
    let accounts: RawAccount[] = []
    accounts = accounts.concat(...Object.values(groupRawAccounts(group)))
    let reducer = (previous: Promise<PartnerOperation[]>, account: RawAccount) => {
        return previous.then(res => {
            return getOperationsOnAccount(account, query)
                .then(ops => Promise.resolve(res.concat(ops)))
        })
    }
    return accounts.reduce(reducer, Promise.resolve([]))
        .then(ops => Promise.resolve(ops.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())))
}

/*
function _getPendingCurrencyOperations(group: CurrencyGroup[], query?: firebase.firestore.Query): Promise<PartnerOperation[]> {
    let reducer = (prevPromise: Promise<PartnerOperation[]>, account: RawAccount) => {
        return prevPromise.then(ops => {
            return getOperationsOnAccount(account, query)
                .then(result => Promise.resolve(ops.concat(result)))
        })
    }

    return group.map(x => x.header).reduce((acc: Promise<RawAccount[]>, cur: string) => {
        let listAcc: RawAccount[]
        return acc.then(list => {
            listAcc = list
            return MobileCurrency.accounts(cur, db)
        })
            .then(result => Promise.resolve(listAcc.concat(result)))
    }, Promise.resolve([]))
        .then(accounts => {
            return accounts.reduce(reducer, Promise.resolve([]))
        })
}
*/
export function getPendingCurrencyOperations(partnerId: string): Promise<PartnerOperation[]> {
    let accReducer = (prevPromise: Promise<CurrencyAccount[]>, mc: MobileCurrency) => {
        return prevPromise.then(accounts => {
            return getRecordDocuments(CurrencyAccountDirect, mc)
                .then(result => Promise.resolve(accounts.concat(result)))
        })
    }
    let opsReducer = (prevPromise: Promise<PartnerOperation[]>, acc: CurrencyAccount) => {
        return prevPromise.then(ops => {
            return getAccountPendingOps(acc)
                .then(result => Promise.resolve(ops.concat(result)))
        })
    }
    return getMobileCurrencies(partnerId)
        .then(mcs => mcs.reduce(accReducer, Promise.resolve([])))
        .then(accounts => accounts.reduce(opsReducer, Promise.resolve([])))
        .then(ops => Promise.resolve(ops.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())))
}




export function saveKashRequest(kr: KashRequest): Promise<KashRequest> {
    return kr.create(KashRequest, db)
}

export function deleteKashRequest(kr: KashRequest): Promise<void> {
    if (unsubscribeKashRequest != undefined) unsubscribeKashRequest()
    return kr.delete(db)
}

export function fscDeletePayee(payee: Payee): Promise<void> {
    deleteCollection(payee.documentReference(db).collection(Table.BankInfo), 100)
        .catch(err => console.error(err))
    return payee.delete(db)
}

export function fscCreatePayee(rec: NewPayee): Promise<Payee> {
    let payee: Payee
    return db.collection(new RawAccount().coll_ref).doc(rec.account).get()
        .then(snap => {
            if (!snap.exists) return Promise.reject(new Error("Account does not exists"))
            const accountData = snap.data()!!
            payee = Payee.fromAccountData(accountData)
            payee.assign(_.pick(rec, "email", "contact", "parent"))
            //console.log("payee collection reference", payee.collectionReference(db).path)
            return payee.collectionReference(db).add(payee.data())
        })
        .then(res => {
            payee.id = res.id
            return Promise.resolve(payee)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

export function fscCreateExtPayee(rec: NewPayee): Promise<Payee> {
    let payee = new ExtPayee()
    payee.assign(_.pick(rec, "email", "contact", "name", "parent", "external"))
    payee.phone = rec.account
    return payee.collectionReference(db).add(payee.data())
        .then(res => {
            payee.id = res.id
            return Promise.resolve(payee)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

export function fscUpdatePayee(rec: NewPayee): Promise<Payee> {
    let payee = new Payee()
    payee.assign(_.pick(rec, "email", "contact", "name", "parent", "external"))
    payee.phone = rec.account
    return payee.collectionReference(db).doc(rec.id).update(payee.data())
        .then(res => {
            return Promise.resolve(payee)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}

export function checkKashRequestCompleted(kr: KashRequest): Promise<KashRequest> {
    return new Promise((resolve, reject) => {
        unsubscribeKashRequest = kr.documentReference(db)
            .onSnapshot(snap => {
                let doc = BaseRecord.fromDocRef(KashRequest)(snap)
                if (doc != null) {
                    if (doc.completed) resolve(doc)
                }
            })
    })
}

export function listPartnerMembers(member: Membership, initialList: UserMembership[], callback: (list: UserMembership[]) => void) {
    let elements: UserMembership[]
    unsubscribeMembers = db.collection(MemberBase.FIRESTORE_COLL)
        .where("partnerId", "==", member.partnerId)
        .where("status", ">=", ActivationStatus.created)
        .onSnapshot(qSnap => {
            let list = Activable.fromSnapshot(UserMembership)(qSnap)
            elements = []
            list.forEach(elm => {
                let ref = initialList.find(x => x.id == elm.id)
                if (ref != null) {
                    elm.userName = ref.userName
                    elm.userPhoneNumber = ref.userPhoneNumber
                } else {
                    elements.push(elm)
                }
            })
            if (elements.length == 0) callback(list)
            else {
                let promises = elements.map(getUserInfo)
                Promise.all(promises)
                    .then(res => {
                        let userElements = res.filter(notEmpty)
                        list.forEach(elm => {
                            let ref = userElements.find(x => x.id == elm.userId)
                            if (ref != null) {
                                elm.userName = ref.fullName()
                                elm.userPhoneNumber = ref.phoneNumber
                            }
                        })
                        callback(list)
                    })
            }
        })
}

function getUserInfo(member: UserMembership): Promise<User | null> {
    let base = new User()
    base.id = member.userId
    return base.documentReference(db).get()
        .then(BaseRecord.fromDocRef(User))

}

function getBosses(pid: string): Promise<User[]> {
    let mBase = new Membership()
    return mBase.collectionReference(db)
        .where("partnerId", "==", pid)
        .where("role", "==", DefaultRole.Boss)
        .get()
        .then(qSnap => {
            if (qSnap.empty) return Promise.resolve([])
            let promises = qSnap.docs.map(doc => {
                let uBase = new User()
                uBase.id = doc.data().userId
                return uBase.documentReference(db).get()
                    .then(BaseRecord.fromDocRef(User))
            })
            return Promise.all(promises)
        })
        .then(res => {
            return Promise.resolve(res.filter(notEmpty))
        })
}

function getDocuments(pid: string): Promise<firebase.storage.Reference[]> {
    let listRef = storage.ref().child(DOCS_REF).child(pid) as any
    return listRef.listAll()
        .then(res => Promise.resolve(res.items))
}

export function getVerification(partner: Organization): Promise<Verification | undefined> {
    let base = new Verification()
    return base.collectionReference(db).doc(partner.id)
        .get()
        .then(BaseRecord.resolveSnapshotDocument(Verification))
        .catch(() => Promise.resolve(undefined))
}

export function setBosses(orgs: Organization[]): Promise<Organization[]> {
    let promises = orgs.map(partner => {
        return getBosses(partner.id)
            .then(bosses => {
                partner.bosses = bosses
                return Promise.resolve(partner)
            })
    })
    return Promise.all(promises)
}

export function setDocuments(orgs: Organization[]): Promise<Organization[]> {
    let promises = orgs.map(partner => {
        return getDocuments(partner.id)
            .then(docs => {
                partner.documents = docs
                return Promise.resolve(partner)
            })
    })
    return Promise.all(promises)
}

export function setVerifications(orgs: Organization[]): Promise<Organization[]> {
    let promises = orgs.map(partner => {
        return getVerification(partner)
            .then(vrf => {
                partner.verification = vrf
                return Promise.resolve(partner)
            })
    })
    return Promise.all(promises)
}

