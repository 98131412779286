import firebase from 'firebase';
import { Activable } from '../activable';
import { Balance } from 'Interfaces';
import { BaseRecord } from '../base'
import { DPO_ACCOUNT_CODE } from 'Constants';

export enum AccountType {
    USER,
    CORPORATE,
    SHARING_CONTRACT,
    SHARING_ACTION,
    DPO,
    COMP,
    TEMP,
    POS,
    DCE,
    CIRC
}


export function accountTypeString(at: AccountType): string {
    let array = ["User accounts", "Corporate accounts", "Sharing contracts",
        "Action contracts", "DPO Accounts", "COMP accounts", "TEMP accounts",
        "PayIn accounts", "Astropy accounts"]
    return array[at]
}

export class RawAccount extends Activable {
    coll_ref: string = "accounts"
    accountType: AccountType
    code: string
    createdAt: firebase.firestore.Timestamp
    mcId: string
    name: string
    status: number
    balance: Balance

    static DPO(mcId: string): RawAccount {
        let acc = new RawAccount()
        acc.mcId = mcId
        acc.code = DPO_ACCOUNT_CODE
        acc.id = DPO_ACCOUNT_CODE
        return acc
    }
}

export class BaseAccount extends RawAccount {
    static NEW_ID = "new";

    constructor(doc?: firebase.firestore.QueryDocumentSnapshot) {
        super()
        if (doc) {
            this.id = doc.id
            this.assign(doc.data())
        }
    }

    static fromSnapshot2<Q extends BaseAccount>(k: new (doc?: firebase.firestore.QueryDocumentSnapshot) => Q, parent?: BaseRecord): (QuerySnapshot, BaseRecord?) => Q[] {
        return snap => {
            let array: Q[] = []
            snap.docs.map(doc => {
                let e = new k()
                if (parent) e.parent = parent
                e.assign(doc.data())
                e.id = doc.id
                array.push(e)
            })
            return array
        }
    }
}