/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import { NotificationManager } from 'react-notifications';
import firebase from 'firebase';
// api
import api, {getPartner} from 'Api';
import {USER_PREF, TOKEN_PREF } from '../constants/storagePrefs';
import {
    UUID_REGISTRATION,
    UUID_REGISTRATION_SUCCESS,
    UUID_REGISTRATION_FAILED,
    USER_SET_TOKEN,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_MEMBER,
    RESET_LOGIN_MSG,
    SET_USER_INFO,
    MEMBERSHIP_SET,
    SET_SELECTED_PARTNER,
    FIREBASE_CUSTOM_TOKEN,
    APPROVAL_REQUEST,
    WITH_TESTING
} from 'Actions/types';
import { getPayees, getRoles } from './CompanyActions';
import { DEMO_ID } from 'Constants';

/**
 * Redux action to generate a login token
 */
export const generateLoginToken = (uuid, regid) => (dispatch) => {
    dispatch({type:  UUID_REGISTRATION});
    api.postToken(uuid, regid)
        .then(()=> {
            dispatch({type: UUID_REGISTRATION_SUCCESS})
        })
        .catch((e) => {
            NotificationManager.error(e.message, 'Token registration failure');
            dispatch({type: UUID_REGISTRATION_FAILED, payload: e});
        });
};

/**
 * Redux Action To Sigin User With Firebase
 */
export const signinUserInSystemId = (token, history) => (dispatch) => {
    dispatch({type: RESET_LOGIN_MSG});
    localStorage.setItem(TOKEN_PREF, token);
    dispatch({ type: USER_SET_TOKEN,  payload: token});
    api.getUserInfo(token)
        .then((resp) =>{
            var user = resp.data
            localStorage.setItem(USER_PREF, JSON.stringify(user));
            dispatch({type : SET_USER_INFO, payload: user});
            NotificationManager.success(`Welcome, ${user.givenNames}!`);
        })
        .catch((error) => {
            dispatch({ type: LOGIN_USER_FAILURE });
            NotificationManager.error(error.message);
        });
};

/**
 * Redux Action To Signout User From  Firebase
 */

export const logoutUserFromSystemID = () => (dispatch) => {
    localStorage.removeItem(USER_PREF);
    localStorage.removeItem(TOKEN_PREF);
    dispatch({ type: LOGOUT_USER });
    NotificationManager.success('User Logout Successfully');
};

export const logMemberOut = () => (dispatch) => {
    dispatch({ type: LOGOUT_MEMBER });
};

export const setMembership = (member, token) => (dispatch) =>{
    var firebaseToken;
    dispatch({type: MEMBERSHIP_SET, payload: member});
    api.getFirebaseCustomToken(member, token)
    .then(response => {
        firebaseToken = response.data
        return firebase.auth()
            .signInWithCustomToken(firebaseToken)
    })
    .then(() => {
        return firebase.auth().currentUser.getIdToken()
    })
    .then(idToken => {
        dispatch({type: FIREBASE_CUSTOM_TOKEN, payload: idToken})
        return getPartner(member)
    })
    .then(partner => {
        if(partner.id === DEMO_ID) dispatch({type: WITH_TESTING, payload: true})
        dispatch({type: SET_SELECTED_PARTNER, payload: partner})
        getRoles(partner)(dispatch)
        getPayees(partner)(dispatch)
    })
    .then(aReq => {
        dispatch({type: APPROVAL_REQUEST, payload: aReq})
    })
    .catch((error) => {
        NotificationManager.error(error.message);
    });
};

