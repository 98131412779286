import { Organization, Operation } from "Models";
import api from "Api";
import { SEARCH_OPERATION, UPDATE_SEARCH_OP, LOADING_DATA } from "./types";
import { NotificationManager } from 'react-notifications';

/**
 * Search Form Hanlder
 */
export const updateSearchOp = (searchText) => ({
    type: UPDATE_SEARCH_OP,
    payload: searchText
});
/**
 * Redux Action On Search Todo
 */
export const onSearchOperation = (searchText) => ({
    type: SEARCH_OPERATION,
    payload: searchText
});

/**
 * Redux Action On Search Todo
 */
export const fetchingData = (val: boolean) => ({
    type: LOADING_DATA,
    payload: val
});

export const addNewOperation = (op: Operation, fbToken: string) => (dispatch) => {
    dispatch(fetchingData(true))
    return api.createOperation(op, fbToken)
        .then(_ => {
            dispatch(fetchingData(false))
            NotificationManager.success('Operation created !');
            return Promise.resolve()
        })
        .catch(e => {
            dispatch(fetchingData(false))
            console.error("===>", e)
            NotificationManager.error(e.message, 'Could not process Operation');
            return Promise.reject(e)
        })
}

