import { FullMembership, UserMembership } from "src/models/membership";
import * as _ from 'lodash'

export interface MyOrganizationsState {
    memberships: FullMembership[] | null
    tester: boolean
    members: UserMembership[] | null
    fetching: boolean
    partner: Organization | null
    approval: Approval | null
    accountsGroup: CurrencyGroup[] | null
    currencyAccounts: RawAccount[]
    payees: Payee[]
    roles: Role[]
    withTests: boolean
    activationMaps: ActivationMap[]
    bankInfos: BankInfoMap
}

import {
    FETCHING_MEMBERSHIP,
    MEMBERSHIP_FETCHING_FAILED,
    MEMBERSHIP_LIST_RECEIVED,
    ADD_NEW_COMPANY,
    COMPANY_ADD_COMPLETED,
    COMPANY_ADD_FAILED,
    PARTNER_MEMBERS_LIST,
    ACCOUNT_GROUP_LIST,
    SET_SELECTED_PARTNER,
    LOGOUT_MEMBER,
    APPROVAL_REQUEST,
    PAYEE_LIST,
    WITH_TESTING,
    CURRENCY_ACCOUNTS_LIST,
    ADDED_PAYEE,
    REMOVE_PAYEE,
    UPDATE_PAYEE,
    ADD_ROLE,
    GET_ROLES,
    UPDATE_ROLE,
    ACTIVATION_MAPS,
    BANK_INFO_MAP,
    DEL_BANK_INFO,
    ADD_BANK_INFO
} from "Actions/types"
import { CurrencyGroup } from "src/groups/accounts1";
import { Organization, Payee, Approval, Role, BankInfo } from "Models";
import { isTester } from "Lib/tools";
import { RawAccount } from "Models/account/base";
import { ActivationMap } from "Models/activation_map";
import { BankInfoMap } from "Interfaces";

// initial state
const INIT_STATE: MyOrganizationsState = {
    memberships: null,
    tester: false,
    withTests: false,
    members: null,
    fetching: false,
    partner: null,
    approval: null,
    accountsGroup: null,
    currencyAccounts: [],
    payees: [],
    roles: [],
    activationMaps: [],
    bankInfos: {}
};

export default (state: MyOrganizationsState = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_NEW_COMPANY:
            return { ...state, fetching: true };
        case APPROVAL_REQUEST:
            return { ...state, approval: action.payload }
        case COMPANY_ADD_COMPLETED:
            return { ...state, fetching: false };
        case COMPANY_ADD_FAILED:
            return { ...state, fetching: false };
        case FETCHING_MEMBERSHIP:
            return { ...state, fetching: true };
        case MEMBERSHIP_FETCHING_FAILED:
            return { ...state, fetching: false };
        case MEMBERSHIP_LIST_RECEIVED:
            return {
                ...state, fetching: false,
                memberships: action.payload,
                tester: isTester(action.payload)
            };
        case PARTNER_MEMBERS_LIST:
            return { ...state, members: action.payload }
        case ACCOUNT_GROUP_LIST:
            return { ...state, accountsGroup: action.payload }
        case CURRENCY_ACCOUNTS_LIST:
            return { ...state, currencyAccounts: action.payload }
        case SET_SELECTED_PARTNER:
            return { ...state, partner: action.payload }
        case LOGOUT_MEMBER:
            return {
                ...state, partner: null,
                members: null,
                approval: null,
                accountsGroup: null,
                currencyAccounts: [],
                payees: [],
                roles: [],
                activationMaps: [],
                bankInfos: {}
            }
        case PAYEE_LIST:
            return { ...state, payees: action.payload }
        case WITH_TESTING:
            return { ...state, withTests: action.payload }
        case ADDED_PAYEE:
            var { payees } = state
            payees.push(action.payload)
            return { ...state, payees: payees }
        case REMOVE_PAYEE:
            var { payees } = state
            _.remove(payees, (e) => e.id === action.payload.id)
            return { ...state, payees: payees }
        case ADD_ROLE:
            var { roles } = state
            roles.push(action.payload)
            return { ...state, roles: roles }
        case GET_ROLES:
            return { ...state, roles: action.payload }
        case UPDATE_ROLE:
            var { roles } = state
            _.remove(roles, (e) => e.id === action.payload.id)
            roles.push(action.payload)
            return { ...state, roles: roles }
        case UPDATE_PAYEE:
            var { payees } = state
            _.remove(payees, (e) => e.id === action.payload.id)
            payees.push(action.payload)
            return { ...state, payees: payees }
        case ACTIVATION_MAPS:
            return { ...state, activationMaps: action.payload }
        case BANK_INFO_MAP:
            return { ...state, bankInfos: action.payload }
        case ADD_BANK_INFO:
            var { bankInfos } = state
            var acc = action.payload
            if (!!acc.parent && !!acc.parent.id) {
                let pid = acc.parent.id
                if (!bankInfos[pid]) bankInfos[pid] = []
                bankInfos[pid].push(acc)
                return { ...state, bankInfos: bankInfos }
            } else return state
        case DEL_BANK_INFO:
            var { bankInfos } = state
            var acc = action.payload
            if (!!acc.parent && !!acc.parent.id) {
                let pid = acc.parent.id
                if (!bankInfos[pid]) bankInfos[pid] = []
                _.remove(bankInfos[pid], (e: BankInfo) => e.id === acc.id)
                return { ...state, bankInfos: bankInfos }
            } else return state

        default: return { ...state };
    }
}