import { Timestamp } from '@google-cloud/firestore';
import { BaseRecord } from './base';
import Table from './table';
import { Category } from 'Constants';
export class Entry extends BaseRecord {
    readonly opId: string
    readonly mcId: string
    readonly info: string
    readonly debitId: string
    readonly debitUid: string
    readonly debitName: string
    readonly creditId: string
    readonly creditUid: string
    readonly creditName: string
    readonly effectDate: Timestamp
    readonly amount: number
    readonly category: Category
    readonly status: number
    readonly reference?: string
    isProcessed: boolean
    tag: string

    coll_ref = Table.Entry

    static getSum(entries: Entry[]): number {
        return entries.map(x => x.amount).reduce((prev, curr) => prev + curr, 0)
    }
};