import { Activable } from "./activable"
import { Role } from "./role";
import { ActivationCondition, ConditionMap } from "./activation_condition";
import { DocumentData } from "@google-cloud/firestore";
import * as _ from 'lodash'
import { NEW_ID } from "Constants";

export const CURRENCY_SPECS = "specs"

const activables: string[] = [
    "accounts", "addresses", CURRENCY_SPECS, "roles", "members",
    "servers", "signatories", "stores", "activation_maps"
]

export class ActivationMap extends Activable {
    coll_ref: string = "activation_maps"

    name: string
    conditions: ConditionMap = {}
    default: boolean = false


    static dbFields = ["role", "qty"]

    assign(data: DocumentData) {
        for (var key of Object.keys(data)) {
            let value = data[key]
            if (key === "conditions" && value instanceof Array) {
                this.setConditions(value as ActivationCondition[])
            } else this[key] = value
        }
        return
    }

    setConditions(conditions: ActivationCondition[]) {
        conditions.forEach(cond => {
            this.setCondition(cond)
        })
    }

    documentData(): DocumentData {
        return {
            name: this.name,
            conditions: this.toConditions()
        }
    }


    setCondition(condition: ActivationCondition) {
        let qty = condition.qty
        this.conditions[condition.role] = typeof qty === "number" ? qty : parseInt(qty)
    }

    toConditions(): ActivationCondition[] {
        return Object.keys(this.conditions).map(key => {
            return {
                role: key,
                qty: this.conditions[key]
            }
        })
    }

    isValid(): boolean {
        return this.toConditions().length > 0
    }

    hasCustomConditions(): boolean {
        return this.toConditions().length > 0
    }

    protected static setDefaults(): ActivationMap[] {
        return activables.map(id => {
            const rec = new ActivationMap()
            rec.id = id
            rec.default = true
            rec.setConditions([{
                qty: 1, role: Role.Boss.id
            }])
            return rec
        })

    }

    static defaults = ActivationMap.setDefaults()

    static mergeDefaults(maps: ActivationMap[]): ActivationMap[] {
        const list: ActivationMap[] = []
        activables.forEach(key => {
            let base = new ActivationMap()
            base.id = NEW_ID
            base.name = key
            base.status = 0
            let map = maps.find(x => x.id == key)
            if (map) {
                base.id = key
                base.setConditions(map.toConditions())
                base.status = map.status
            }
            list.push(base)
        })
        return list
    }


}

