import { Activable, ActivationStatus } from "./activable";
import { Organization } from "./organization";
import { Role } from "./role";
import { BaseRecord } from "./base";
import Table from './table';

export class MemberBase extends BaseRecord {
    coll_ref: string = Table.Member
    constructor(ms: Membership) {
        super()
        this.id = ms.id
    }
    static FIRESTORE_COLL = "members"
}

export class Membership extends Activable {
    coll_ref: string = "members"
    userId: string
    partnerId: string
    role: string
    status: ActivationStatus
    partner?: Organization

    base(): MemberBase {
        return new MemberBase(this)
    }

    getRoles(custom: Role[]): Role {
        return Role.get(this.role, custom)
    }
}

export class FullMembership extends Membership {
    partner: Organization
}

export class UserMembership extends Membership {
    userName: string
    userPhoneNumber: string
}

export class NewMemberData {

    phoneNumber: string
    role: string

    isValid(): boolean {
        return this.phoneNumber.length > 4 && this.role.length > 0
    }

    static init(): NewMemberData {
        const data = new NewMemberData()
        data.phoneNumber = ""
        data.role = ""
        return data
    }

}