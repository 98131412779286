export enum RequestType {
    token = "TokenRequest",
    signature = "SignatureRequest",
    userInfo = "UserInfoRequest"
}

/*
* data can be:
*   null  => token request
*   string(userrId) => userInfo request
*   object => signature request
*/
export class WebRequest{
    uuid?: string;  //it can be set to null after been processed by mobile application
    accountId: string;
    userId?: string;
    terminalId?: string;
    type: RequestType;
    regid: string;
};

