import { BaseRecord } from "./base";
import Table from './table'
import { Timestamp } from "@google-cloud/firestore";

export class Invitation extends BaseRecord {
    coll_ref: string = Table.Invitation

    code: string
    debitId: string
    debitName: string
    mcId: string
    price: number
    userId: string
    validUntil: Timestamp
}