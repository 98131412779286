import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
// rct collapsible card
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';
// intl messages
import IntlMessages from 'Util/IntlMessages';
import {
	MyOrgsWidget,
} from "Components/Widgets";

class CompanyRole extends Component {
    render() {
		const { match } = this.props;
        return (
            <div className="ecom-dashboard-wrapper">
				<Helmet>
					<title>Company and Roles</title>
					<meta name="description" content="Reactify Ecommerce Dashboard" />
				</Helmet>
				<PageTitleBar title={<IntlMessages id="components.companiesAndRoles" />} match={match} />
                <div className="row  justify-content-center">
                    <RctCollapsibleCard
	        			colClasses="col-sm-12 col-md-8 col-lg-8 w-xs-full"
			        	heading={<IntlMessages id="widgets.myOrganisations" />}
        				fullBlock
		        	>
			            <MyOrgsWidget />
			        </RctCollapsibleCard>                
                </div>
            </div>
        )
    }
}


const mapStateToProps = ({ authUser }) => {
    const { user, member } = authUser;
    return { user, member };
 }
 
 export default connect(mapStateToProps, {
})(CompanyRole);